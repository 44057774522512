import { ReactNode } from 'react';
import cl from 'classnames';
import styles from '../../index.module.scss';
import { Link } from 'react-router-dom';

export type TDotsProps = {
  current: number;
  increment: number;
  key: string;
  setSearch: (arg: number) => string;
};

const renderDotsPagination: (args: TDotsProps) => ReactNode = props => {
  const { current, increment, key, setSearch } = props;

  return (
    <li className={cl(styles.item, styles.more)} key={key}>
      <Link
        className={styles.link}
        to={{
          search: setSearch(current + increment),
        }}
      >
        ...
      </Link>
      {/* <span className="pagination__link">...</span> */}
    </li>
  );
};

export default renderDotsPagination;
