import { AuthBlockSchema } from '../types/authBlockSchema';
import { createSelector } from '@reduxjs/toolkit';
import { TState } from 'store/types';

export const getAuthBlock = (state: TState): AuthBlockSchema => state.authBlock;

export const getCurrentBtnId = createSelector(
  getAuthBlock,
  ({ currentBtnId }) => {
    return currentBtnId;
  },
);

export const getPopupType = createSelector(
  getAuthBlock,
  ({ currentPopupType }) => {
    return currentPopupType;
  },
);

export const getPopupIsOpen = createSelector(
  getAuthBlock,
  ({ popupIsOpen }) => {
    return popupIsOpen;
  },
);

export const getFormType = createSelector(getAuthBlock, ({ formType }) => {
  return formType;
});

export const getSmsIsloading = createSelector(
  getAuthBlock,
  ({ sms: { loading } }) => {
    return loading;
  },
);

export const getSmsError = createSelector(
  getAuthBlock,
  ({ sms: { error } }) => {
    return error;
  },
);

export const getSmsMessage = createSelector(
  getAuthBlock,
  ({
    sms: {
      response: { message },
    },
  }) => {
    return message;
  },
);

export const getSmsTime = createSelector(getAuthBlock, ({ sms: { time } }) => {
  return time;
});

export const getSmsOtp = createSelector(getAuthBlock, ({ sms: { otp } }) => {
  return otp;
});

export const getPromocodeCheck = createSelector(
  getAuthBlock,
  ({
    promocode: {
      response: { check },
    },
  }) => {
    return check;
  },
);

export const getPromocodeError = createSelector(
  getAuthBlock,
  ({ promocode: { error } }) => {
    return error;
  },
);

export const getPromocodeLoading = createSelector(
  getAuthBlock,
  ({ promocode: { loading } }) => {
    return loading;
  },
);

export const getPromocodeIsSend = createSelector(
  getAuthBlock,
  ({ promocode: { send } }) => {
    return send;
  },
);
