import { TReferralSchema } from './schema';

export const initialState: TReferralSchema = {
  transactions: {
    loading: false,
  },
  chart: {
    chartLoading: false,
    period: '30',
  },
  withdraw: {},
  init: false,
};
