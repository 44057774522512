import { Modal } from 'shared/components/modal/modal';
import { Suspense, memo, useCallback } from 'react';
import { ContactSupportForm } from '../ContactSupportForm/ContactSupportForm.async';
import { getUserSupportIsOpen } from 'features/ContactSupport/model/selectors/getUserSupportData';
import { userSupportActions } from 'features/ContactSupport/model/slice/userSupportSlice';
import { useDispatch, useSelector } from 'react-redux';

export const ContactSupportModal = memo(() => {
  const dispatch = useDispatch();
  const isUserSupportOpen = useSelector(getUserSupportIsOpen);
  const onClose = useCallback(() => {
    dispatch(userSupportActions.setUserSupportClose());
  }, [dispatch]);

  return (
    <Modal isOpen={isUserSupportOpen} onClose={onClose}>
      <Suspense fallback={''}>
        <ContactSupportForm onClose={onClose} />
      </Suspense>
    </Modal>
  );
});
