import { createSlice } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';
import { clientsList } from '../services/clientsList/clientsList';
import { getAccess } from '../services/getAccess/getAccess';
import { AdminSupportSchema } from '../types/adminSupportSchema';

const initialState: AdminSupportSchema = {
  clients: [],
  isLoading: false,
  error: null,
  access: {
    user: undefined,
    isLoading: false,
    error: null,
  },
};

export const AdminSupportSlice = createSlice({
  name: 'adminSupport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //ClientList
    builder
      .addCase(clientsList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(clientsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.clients = action.payload;
      })
      .addCase(clientsList.rejected, (state, action) => {
        state.isLoading = false;
        const message = (action.payload as NetworkError).message;
        state.error = { ...(action.payload as NetworkError), message };
      });

    //getAccess
    builder
      .addCase(getAccess.pending, state => {
        state.access.isLoading = true;
        state.access.error = null;
      })
      .addCase(getAccess.fulfilled, (state, action) => {
        state.access.isLoading = false;
        state.access.error = null;
        state.access.user = action.payload;
      })
      .addCase(getAccess.rejected, (state, action) => {
        state.access.isLoading = false;
        const message = (action.payload as NetworkError).message;
        state.access.error = { ...(action.payload as NetworkError), message };
      });
  },
});

export const { actions: AdminSupportActions } = AdminSupportSlice;
export const { reducer: AdminSupportReducer } = AdminSupportSlice;
