import { createAsyncThunk } from '@reduxjs/toolkit';
import tariffsModel from 'store/models/tariffs';

export const fetchMainTariffs = createAsyncThunk(
  'tariffs/fetchMainTariffs',
  async (_, thunkApi) => {
    try {
      const response = await tariffsModel.getMainTariffs();
      if (!response.data) {
        throw new Error();
      }
      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
