import {
  Modal,
  ModalDescription,
  ModalFooter,
  ModalTitle,
} from 'shared/components/modal/modal';
import { Suspense, memo, useCallback, useEffect, useMemo } from 'react';
import {
  getPromocodesList,
  getSendMessageToRespondentsError,
  getSendMessageToRespondentsIsLoading,
  getSendMessageToRespondentsIsOpen,
  getSendMessageToRespondentsIsSent,
  getSendMessageToRespondentsMessage,
  getSendMessageToRespondentsPromocode,
  getSendMessageToRespondentsTester,
} from '../../model/selectors/getMessageToRespondents';
import { useSelector } from 'react-redux';
import { sendMessageToRespondentsActions } from '../../model/slices/sendMessageToRespondents';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { fetchPromocodes } from '../../model/services/fetchPromocodes';
import { FieldSet } from 'shared/components/grid/field';
import Select from 'react-select';
import { singleSelectStyles } from 'app/styles/select';
import Button from 'shared/components/button';
import { TextArea } from 'shared/components/textarea';
import { sendMessage } from '../../model/services/sendMessageToRespondents';

const options = [
  { value: 'all', label: 'Отправить всем' },
  { value: 'promocode', label: 'Отправить только с указанным промокодом' },
  { value: 'tester', label: 'Отправить только тестовым респондентам' },
];

export const SendMessageToRespondentsModal = memo(() => {
  const dispatch = useAppDispatch();
  const isOpen = useSelector(getSendMessageToRespondentsIsOpen);
  const onClose = useCallback(() => {
    dispatch(sendMessageToRespondentsActions.sendIsopenModal(false));
  }, [dispatch]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchPromocodes({}));
    }
  }, [dispatch, isOpen]);

  const promocodesList = useSelector(getPromocodesList);
  const promocode = useSelector(getSendMessageToRespondentsPromocode);
  const tester = useSelector(getSendMessageToRespondentsTester);
  const message = useSelector(getSendMessageToRespondentsMessage);
  const isLoading = useSelector(getSendMessageToRespondentsIsLoading);
  const error = useSelector(getSendMessageToRespondentsError);
  const isSent = useSelector(getSendMessageToRespondentsIsSent);

  const promocodeValue = useMemo(() => {
    return (
      promocodesList.find(
        promo => promo.value.toString() === promocode?.toString(),
      ) || null
    );
  }, [promocode, promocodesList]);

  const respondents = useMemo(() => {
    if (tester) {
      return options[2];
    }
    if (promocode || promocode === null) {
      return options[1];
    }
    return options[0];
  }, [promocode, tester]);

  const onChangeRespondents = useCallback(
    (evt: { value: string; label: string }) => {
      if (evt.value === 'all') {
        dispatch(sendMessageToRespondentsActions.sendIsTester(false));
        dispatch(sendMessageToRespondentsActions.sendPromocode(undefined));
      }
      if (evt.value === 'promocode') {
        dispatch(sendMessageToRespondentsActions.sendPromocode(null));
        dispatch(sendMessageToRespondentsActions.sendIsTester(false));
      }
      if (evt.value === 'tester') {
        dispatch(sendMessageToRespondentsActions.sendIsTester(true));
        dispatch(sendMessageToRespondentsActions.sendPromocode(undefined));
      }
    },
    [dispatch],
  );

  const onChangePromocode = useCallback(
    (evt: { label: string; value: string }) => {
      dispatch(sendMessageToRespondentsActions.sendPromocode(evt.value));
    },
    [dispatch],
  );

  const onChangeMessage = useCallback(
    (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(sendMessageToRespondentsActions.sendMessage(evt.target.value));
    },
    [dispatch],
  );

  const onErrorBack = useCallback(() => {
    dispatch(sendMessageToRespondentsActions.sendIsSent(false));
  }, [dispatch]);

  const onSendMessage = useCallback(() => {
    dispatch(
      sendMessage({
        tester,
        promocode: promocode === null ? undefined : promocode,
        message,
      }),
    );
  }, [dispatch, message, promocode, tester]);

  const onCloseModal = useCallback(() => {
    dispatch(sendMessageToRespondentsActions.sendIsopenModal(false));
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {!isSent && !error && (
        <Suspense fallback={''}>
          <form
            onSubmit={evt => {
              evt.preventDefault();
              onSendMessage();
            }}
          >
            <ModalTitle>Отправка сообщения пользователям</ModalTitle>
            <FieldSet labelName="Выберите группу респондентов">
              <Select
                styles={singleSelectStyles()}
                options={options}
                placeholder="Выбрать"
                inputId="respondents"
                value={respondents}
                onChange={onChangeRespondents}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue ? 'Загрузка...' : 'Значение не найдено'
                }
              />
            </FieldSet>
            {(promocode || promocode === null) && (
              <FieldSet labelName="Промокод">
                <Select
                  styles={singleSelectStyles()}
                  options={promocodesList}
                  placeholder="Выберете из списка"
                  inputId="promocode"
                  value={promocodeValue}
                  onChange={onChangePromocode}
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue ? 'Загрузка...' : 'Значение не найдено'
                  }
                  required
                />
              </FieldSet>
            )}
            <FieldSet labelName="Сообщение">
              <TextArea
                value={message}
                onChange={onChangeMessage}
                error={false}
              />
            </FieldSet>
            <ModalFooter>
              <Button
                type="submit"
                as="button"
                withIcon={false}
                disabled={!message || promocode === null || isLoading}
              >
                Отправить сообщение
              </Button>
            </ModalFooter>
          </form>
        </Suspense>
      )}
      {isSent && !error && (
        <form
          onSubmit={evt => {
            evt.preventDefault();
            onCloseModal();
          }}
        >
          <ModalTitle>Отправка сообщения пользователям</ModalTitle>
          <ModalDescription>Сообщение успешно отправлено</ModalDescription>
          <ModalFooter>
            <Button type="submit" as="button" withIcon={false}>
              Закрыть
            </Button>
          </ModalFooter>
        </form>
      )}
      {error && (
        <form
          onSubmit={evt => {
            evt.preventDefault();
            onErrorBack();
          }}
        >
          <ModalTitle>Отправка сообщения пользователям</ModalTitle>
          <ModalDescription>
            Произошла ошибка при отправке сообщения
          </ModalDescription>
          <ModalFooter>
            <Button type="submit" as="button" withIcon={false}>
              Назад
            </Button>
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
});
