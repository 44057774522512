import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BurgerBlockSchema } from '../types/burgerBlockSchema';

const initialState: BurgerBlockSchema = {
  popupIsOpen: false,
};

export const burgerBlockSlice = createSlice({
  name: 'burgerBlock',
  initialState,
  reducers: {
    setOpenPopup(state, action: PayloadAction<boolean>) {
      state.popupIsOpen = action.payload;
    },
  },
});

export const { actions: burgerBlockActions } = burgerBlockSlice;
export const { reducer: burgerBlockReducer } = burgerBlockSlice;
