export const sendMetrik = (type, identificator) => {
  const IS_DEV = process.env.REACT_APP_NODE_ENV === 'development';
  if (!IS_DEV) {
    window.ym(92016649, type, identificator);
  }
};

export const sendPixel = (type, identificator) => {
  const IS_DEV = process.env.REACT_APP_NODE_ENV === 'development';
  if (!IS_DEV) {
    _tmr.push({ type, id: 3405062, goal: identificator});
  }
};
