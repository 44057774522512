import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProfileFormActions } from 'features/Profile';
import authModel from 'store/models/auth';

export const fetchSmsRequest = createAsyncThunk(
  'authBlock/fetchSmsRequest',
  async (data: { endpoint: string; phone: string }, thunkApi) => {
    thunkApi.dispatch(ProfileFormActions.setIsOpenSms(true));
    try {
      const response = (await authModel.postAuth(data)) as unknown as {
        data: { message: string; registered: boolean };
      };
      if (!response.data) {
        throw new Error();
      }
      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
