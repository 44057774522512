import Network, { TAxios } from '../../../shared/libraries/network';
import CONFIG from '../../../shared/constants/config';

class TestModel extends Network {
  constructor(baseURL: string, timeout = 30000) {
    super(baseURL, timeout);
  }

  async getTestData(): Promise<TAxios> {
    return this.send('get', `/typicode/demo/posts`, {});
  }
}

const baseUrl = CONFIG.REACT_APP_API_BASE_URL as string;
const testModel = new TestModel(baseUrl);

export default testModel;
