import { TState } from 'store/types';

export const getProfileFormInitialData = (state: TState) =>
  state.profileForm.initialData;

export const getProfileFormChangedData = (state: TState) =>
  state.profileForm.changedData;

export const getProfileFormIsOpen = (state: TState) => {
  return state.profileForm.isOpenSms;
};

export const getIsRegistrationDataEdit = (state: TState) => {
  return (
    state.profileForm.initialData.email !==
      state.profileForm.changedData.email ||
    state.profileForm.initialData.phone !== state.profileForm.changedData.phone
  );
};
