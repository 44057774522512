import { PropsWithChildren } from 'react';
import style from './index.module.scss';
import cl from 'classnames';
import { Layouts, LayoutValues } from 'shared/layouts/types';

type THeader = PropsWithChildren<{
  layout?: LayoutValues;
}>;
export const HeaderId = 'header';

const Header = ({ children, layout }: THeader) => {
  const isIndex = layout === Layouts.Index;
  const isCabinet = layout === Layouts.Cabinet;

  return (
    <header
      id={HeaderId}
      className={cl(style.header, {
        [style.index]: isIndex,
        [style.cabinet]: isCabinet,
      })}
    >
      <div className={`wrapper ${style.wrapper}`}>{children}</div>
    </header>
  );
};

export default Header;
