import CONFIG from 'shared/constants/config';
import ROUTES from 'shared/constants/routes';

const useHeadTitle = () => {
  // const { pathname } = useLocation();
  const pathname = window.location.pathname;
  const title =
    Object.values(ROUTES).find(({ ROUTE }) => {
      return ROUTE === pathname;
    })?.NAME || CONFIG.PROJECT_NAME;

  return title;
};

export default useHeadTitle;
