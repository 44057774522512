import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  TCreateInvoiceParams,
  TCreateInvoiceResponse,
} from '../types/balanceSchema';
import balanceModel from 'store/models/balance';
import {
  getPayerCompanyAddress,
  getPayerCompanyname,
  getPayerEmail,
  getPayerInn,
  getPayerPayerType,
  getPayerSumme,
  getPayerUsername,
  getTransactionType,
} from '../selectors/getBalance';
import { TState } from 'store/types';
import { balanceActions } from '../slices/balanceSlice';
import { fetchClientTransactions } from './fetchClientTransactions';

export const fetchCreatePaymentInvoice = createAsyncThunk(
  'balance/fetchCreatePaymentInvoice',
  async (testEdit: boolean, thunkApi) => {
    const payer = getPayerPayerType(thunkApi.getState() as TState);
    const transactionType = getTransactionType(thunkApi.getState() as TState);
    const inn = getPayerInn(thunkApi.getState() as TState);
    const username = getPayerUsername(thunkApi.getState() as TState);
    const companyname = getPayerCompanyname(thunkApi.getState() as TState);
    const address = getPayerCompanyAddress(thunkApi.getState() as TState);
    const summ = getPayerSumme(thunkApi.getState() as TState);
    const email = getPayerEmail(thunkApi.getState() as TState);

    const data: TCreateInvoiceParams = {
      name: payer === 'user' ? username || '' : companyname || '',
      sum: summ || '0',
      inn: inn || undefined,
      address: address || undefined,
      card: transactionType === 'card',
      email: transactionType === 'card' ? email : undefined,
    };

    try {
      const response = (await balanceModel.postCreateInvoice(data)) as {
        data: TCreateInvoiceResponse;
      };
      if (!response.data) {
        throw new Error();
      }
      if (!response.data.link && response.data.id) {
        if (!testEdit) {
          thunkApi.dispatch(fetchClientTransactions({ draft: true, limit: 3 }));
          thunkApi.dispatch(balanceActions.setCloseBalanceUpPopup());
        }
      }
      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
