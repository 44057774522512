import { ReactNode } from 'react';
import style from './QuoteItem.module.scss';

export type TQuoteItem = {
  text: ReactNode;
  author: string;
};

export const QuoteItem = ({ author, text }: TQuoteItem) => {
  return (
    <div className={style.item}>
      <blockquote className={style.quote}>
        <p>{text}</p>
      </blockquote>
      <p className={style.author}>— {author}</p>
    </div>
  );
};
