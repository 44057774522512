const CONFIG = {
  REACT_APP_NODE_ENV: process.env.REACT_APP_NODE_ENV,
  REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  PUBLIC_URL: process.env.PUBLIC_URL,
  PROJECT_NAME: 'Split Test',
  PAGINATION_LIMIT: 10,
  IS_DEV: process.env.NODE_ENV === 'development',
  PROMO_COOKIE_NAME: 'promocode_cookie',
};

export default CONFIG;
