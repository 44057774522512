import { FormHTMLAttributes, HTMLAttributes, createElement } from 'react';
import cl from 'classnames';
import style from './index.module.scss';

const ContentType = {
  Div: 'Div',
  Form: 'Form',
} as const;

export type TContentTypeValues = typeof ContentType[keyof typeof ContentType];

type TContent = FormHTMLAttributes<HTMLFormElement> &
  HTMLAttributes<HTMLDivElement> & {
    type?: TContentTypeValues;
  };

const Content = ({
  children,
  type = ContentType.Div,
  className = '',
  ...props
}: TContent) => {
  return createElement(ContentType[type].toLocaleLowerCase(), {
    children,
    className: cl(style.content, className),
    ...props,
  });
};

export default Content;
