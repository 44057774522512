import { ButtonHTMLAttributes, ReactNode } from 'react';
import styles from './index.module.scss';
import cl from 'classnames';
import { Link } from 'react-router-dom';

const enum ButtonType {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset',
}

interface ButtonBaseProps {
  icon?: React.ReactNode;
  transparent?: boolean;
  outline?: boolean;
  empty?: boolean;
  withIcon?: boolean;
  as?: string;
  children?: ReactNode;
  fullWidth?: boolean;
  nopadding?: boolean;
}

interface ButtonOnlyProps
  extends ButtonBaseProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  type?: `${ButtonType}`;
  as: 'button';
}

interface LinkOnlyProps extends ButtonBaseProps {
  className?: string;
  href: string;
  target?: '_blank';
  as: 'link';
}

type BtnProps = ButtonOnlyProps | LinkOnlyProps;

const Button = (props: BtnProps) => {
  const defaultClassName = (({
    withIcon = true,
    transparent,
    outline,
    empty,
    className,
    fullWidth = false,
    nopadding = false,
  }: BtnProps) =>
    cl(styles.button, className, {
      [styles.transparent]: transparent,
      [styles.outline]: outline,
      [styles.empty]: empty,
      [styles.withIcon]: withIcon,
      [styles.fullWidth]: fullWidth,
      [styles.nopadding]: nopadding,
    }))(props);

  const { children } = props;

  if (props.as === 'button') {
    const {
      type = ButtonType.Button,
      className,
      transparent,
      outline,
      empty,
      withIcon,
      nopadding,
      ...rest
    } = props;

    return (
      <button className={defaultClassName} type={type} {...rest}>
        {children}
      </button>
    );
  }

  return (
    <Link
      to={props.href}
      target={props.target}
      className={defaultClassName}
      {...props}
    >
      {children}
    </Link>
  );
};

export default Button;
