import {
  FC,
  Fragment,
  PropsWithChildren,
  TableHTMLAttributes,
  useCallback,
} from 'react';
import btnStyles from 'shared/components/button/index.module.scss';
import styles from './index.module.scss';
import cl from 'classnames';
import utils from 'shared/helpers/utils';
import { Icon } from 'shared/components/icon';
import 'react-tooltip/dist/react-tooltip.css';
import Button from 'shared/components/button';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { fetchLoadDocument } from '../../../model/services/fetchLoadDocument';
import SpinerMin from 'shared/components/loader/spinner-min';
import { useSelector } from 'react-redux';
import { getDownloadIsLoading } from '../../../model/selectors/getBalance';
import { InfoPopover } from 'shared/components/infoPopover';

export type Sum = {
  value: number;
  status?: 'success' | 'error' | undefined;
};

export type Document = 'download' | 'act' | 'wait' | null;

export type TBalanceData = {
  date: string;
  descr: string;
  sum: Sum;
  document: Document;
  balance?: number;
  id: number;
};

type TPaymentTable = TableHTMLAttributes<HTMLTableElement> & {
  thCells: { id: string; name: string }[];
  data: TBalanceData[];
  loading?: boolean;
};

const SumComponent = ({ sum }: { sum: Sum }) => {
  return (
    <span
      className={cl(styles.sum, {
        [styles.success]: sum.status === 'success',
        [styles.error]: sum.status === 'error',
      })}
    >
      {utils.addSpaces(sum.value)}
    </span>
  );
};
const DocumentComponent = ({
  document,
  id,
}: {
  document: Document;
  id: number;
}) => {
  const isDownloading = useSelector(state => getDownloadIsLoading(state, id));
  const dispatch = useAppDispatch();
  const onDownloadDocument = useCallback(
    (idDoc: number) => {
      dispatch(fetchLoadDocument({ id: idDoc }));
    },
    [dispatch],
  );
  const DownloadButton: FC<PropsWithChildren> = ({ children }) => {
    return (
      <Button
        className={cl(btnStyles.withIcon)}
        as="button"
        empty
        onClick={() => onDownloadDocument(id)}
        disabled={isDownloading}
        nopadding
      >
        {isDownloading ? <SpinerMin /> : <Icon name="icon-download" />}
        {children}
      </Button>
    );
  };

  return (
    <>
      {document === 'download' ? (
        <DownloadButton>Скачать счет</DownloadButton>
      ) : null}
      {document === 'act' ? <DownloadButton>Акт</DownloadButton> : null}
      {document === 'wait' ? (
        <InfoPopover
          id={id}
          iconName="icon-clock"
          title="Тест проводится"
          text="Акт выполненных работ автоматически сформируется по завершении теста."
          placement="bottom"
          offsetValue={{
            mobile: 3,
            desktop: 3,
          }}
        />
      ) : null}
    </>
  );
};
const BalanceComponent = ({ balance }: { balance?: number }) => {
  const { td } = styles;
  return (
    <>
      {typeof balance === 'number' ? (
        <td className={cl(td, styles.balance)}>{utils.addSpaces(balance)}</td>
      ) : null}
    </>
  );
};

export const PaymentTable: FC<TPaymentTable> = props => {
  const { thCells, className, data, loading = false } = props;
  const { table, desktop, mobile, td, th, mobileTable } = styles;
  const hasBalance = data.some(el => el.balance);

  return (
    <>
      <table
        className={cl(table, desktop, className, { [styles.loading]: loading })}
      >
        <thead>
          <tr>
            {thCells.map(({ id, name }) => (
              <th key={id} className={cl(th, [styles[id]])}>
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(({ date, descr, sum, document, balance, id }) => {
            return (
              <tr key={id}>
                <td className={cl(td, styles.date)}>{date}</td>
                <td className={cl(td, styles.descr)}>{descr}</td>
                <td className={cl(td)}>
                  <SumComponent sum={sum} />
                </td>
                <td className={cl(td)}>
                  <DocumentComponent document={document} id={id} />
                </td>
                <BalanceComponent balance={balance} />
              </tr>
            );
          })}
        </tbody>
      </table>

      <table className={cl(table, mobileTable, mobile, className)}>
        <thead>
          <tr>
            <th className={cl(th, styles.mobileTh)}>
              {thCells.map(({ id, name }, idx) => (
                <Fragment key={id}>
                  {idx === thCells.length - 1 && hasBalance ? (
                    <></>
                  ) : (
                    <span className={cl(styles.item, styles[id])}>{name}</span>
                  )}
                </Fragment>
              ))}
            </th>
            {hasBalance ? (
              <th className={th}>{thCells[thCells.length - 1].name}</th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {data.map(({ date, descr, sum, document, balance, id }) => {
            return (
              <tr key={id}>
                <td className={cl(td, styles.mobileTd)}>
                  <span className={styles.date}>{date}</span>
                  <span className={styles.descr}>{descr}</span>
                  <div className={styles.footer}>
                    <SumComponent sum={sum} />
                    <DocumentComponent document={document} id={id} />
                  </div>
                </td>
                <BalanceComponent balance={balance} />
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
