import { createSlice } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';

import { TariffsSchema } from '../types/triffsSchema';
import { fetchTariffs } from '../services/fetchTariffs';
import { fetchMainTariffs } from '../services/fetchMainTariffs';

const initialState: TariffsSchema = {
  response: {
    data: [],
  },
  loading: false,
  error: null,
  mainTariffs: undefined,
};

export const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    //ClientList
    builder
      .addCase(fetchTariffs.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTariffs.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.response = action.payload;
      })
      .addCase(fetchTariffs.rejected, (state, action) => {
        state.loading = false;
        const message = (action.payload as NetworkError).message;
        state.error = { ...(action.payload as NetworkError), message };
      });

    //tariifs for landing

    builder.addCase(fetchMainTariffs.fulfilled, (state, action) => {
      state.mainTariffs = action.payload.data;
    });
  },
});

export const { actions: tariffsActions } = tariffsSlice;
export const { reducer: tariffsReducer } = tariffsSlice;
