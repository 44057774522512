import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { PageTitle } from 'shared/components/pageTitle/index';
import style from '../index.module.scss';
import { PageDescription } from 'shared/components/pageDescription';
import {
  getInitBalancePage,
  getIsEmptyBalancsPage,
} from '../../../model/selectors/getBalance';
import { BalanceForm } from '../balanceForm';

export const EmptyBalanceBlock: FC = memo(() => {
  const isEmptyPage = useSelector(getIsEmptyBalancsPage);
  const isInitPage = useSelector(getInitBalancePage);

  if (isEmptyPage && isInitPage) {
    return (
      <>
        <PageTitle className={style.title}>Баланс</PageTitle>
        <PageDescription className={style.descr}>
          <p>
            В этом разделе будет храниться история операций (пополнения счетов и
            списания за проведенные тесты), а также выставленные счета и акты
            выполненных работ. Чтобы запустить тест, необходимо достаточное
            количество средств на балансе.
          </p>
        </PageDescription>
        <BalanceForm withIndent title="Пополнить баланс" />
      </>
    );
  }

  return null;
});
