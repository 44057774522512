import React, { FC, TextareaHTMLAttributes } from 'react';
import styles from './index.module.scss';
import cl from 'classnames';

type InputProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  recommended?: boolean;
  error?: boolean;
};

const TextArea: FC<InputProps> = React.memo(
  ({
    maxLength,
    value = '',
    children,
    recommended,
    error,
    className = '',
    ...props
  }) => {
    return (
      <div className={cl(styles.wrap)}>
        <textarea
          className={cl(
            styles.textarea,
            className,
            {
              [styles['textarea--recommended']]: recommended,
            },
            {
              [styles['textarea--error']]: error,
            },
          )}
          value={value}
          maxLength={maxLength}
          {...props}
        ></textarea>
        {maxLength && (
          <span className={styles.counter}>Максимум {maxLength} символов</span>
        )}
      </div>
    );
  },
);

export { TextArea };
