import { FC, PropsWithChildren } from 'react';
import style from './index.module.scss';
import cl from 'classnames';

type TPageTitle = PropsWithChildren<{
  className?: string;
  middle?: boolean;
}>;

const PageTitle: FC<TPageTitle> = ({ children, className, middle }) => {
  return (
    <h1
      className={cl(style.title, className, {
        [style.middle]: middle,
      })}
    >
      {children}
    </h1>
  );
};

export { PageTitle };
