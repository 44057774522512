import { FC, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { TRoute } from 'shared/constants/routes';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { userSupportActions } from 'features/ContactSupport/model/slice/userSupportSlice';
import style from './index.module.scss';
import Button from 'shared/components/button';
import { getIsAuth } from 'store/selectors/user';
import { useSelector } from 'react-redux';

type TNavbarFooter = {
  links: TRoute[];
};

const NavbarFooter: FC<TNavbarFooter> = ({ links }) => {
  const dispatch = useAppDispatch();
  const isAuth = useSelector(getIsAuth);

  const onOpen = useCallback(() => {
    dispatch(userSupportActions.setUserSupportOpen());
  }, [dispatch]);

  return (
    <nav className={style.navbarFooter}>
      {links.map(({ ROUTE, NAME, TBLANK }) => {
        if (NAME === 'О сервисе' && isAuth) {
          return null;
        }
        if (NAME === 'Поддержка') {
          return (
            <Button
              as="button"
              className={style.link}
              onClick={onOpen}
              transparent
              nopadding
              key={ROUTE}
            >
              <span>{NAME}</span>
            </Button>
          );
        }
        return (
          <NavLink
            className={style.link}
            to={ROUTE}
            key={ROUTE}
            target={TBLANK ? '_blank' : '_self'}
          >
            <span>{NAME}</span>
          </NavLink>
        );
      })}
    </nav>
  );
};

export default NavbarFooter;
