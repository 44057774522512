import { createAsyncThunk } from '@reduxjs/toolkit';
import { TUser } from 'pages/user/store/types';
import supportModel from 'store/models/support';

export const getAccess = createAsyncThunk(
  'support/getAccess',
  async (id: number, thunkApi) => {
    try {
      const response = (await supportModel.getAccess(id)) as unknown as {
        data: TUser & { token: string };
      };
      if (!response.data) {
        throw new Error();
      }
      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
