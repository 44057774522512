import { fetchUser } from 'pages/user/store/store';
import { memo, ReactNode, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router';
import BurgerBlock from 'shared/components/burgerBlock';
import Footer from 'shared/components/footer';
import Header from 'shared/components/header';
import Logo from 'shared/components/logo';
import MainWrapper from 'shared/components/mainWrapper';
import Navbar from 'shared/components/navbar';
import ROUTES from 'shared/constants/routes';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';

type TCabinetLayoutRoute = {
  isAuth: boolean;
  children?: ReactNode;
};

const CabinetLayoutRoute = ({ isAuth, children }: TCabinetLayoutRoute) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);
  if (!isAuth) {
    return <Navigate to={ROUTES.MAIN.ROUTE} replace />;
  }
  const routesValues = Object.values(ROUTES);
  const headerLinks = routesValues.filter(
    ({ POSITION }) => POSITION === 'cabinetHeader',
  );

  return (
    <>
      <Header layout="Cabinet">
        <Logo to={ROUTES.CABINET_TESTS.ROUTE} layout="Cabinet" />
        <BurgerBlock layout="Cabinet">
          <Navbar links={headerLinks} layout="Cabinet" />
        </BurgerBlock>
        <Navbar links={headerLinks} layout="Cabinet" position="Desktop" />
      </Header>
      <MainWrapper>{children ?? <Outlet />}</MainWrapper>
      <Footer layout="Cabinet" />
    </>
  );
};

export default memo(CabinetLayoutRoute);
