import { Title } from '../Title/Title';
import { Text } from '../Text/Text';
import style from './CompareItem.module.scss';
import { ImageGrid, Image } from 'pages/cabinet/allTest/components/image';
import { Random } from 'shared/helpers/utils/random';

export type TCompareItem = {
  title: string;
  descr: string;
  data: Array<{ link: string; votes: number }[]>;
};

export const CompareItem = ({ title, descr, data }: TCompareItem) => {
  return (
    <div className={style.item}>
      <Title type="h3" className={style.title}>
        {title}
      </Title>
      <Text min className={style.descr}>
        {descr}
      </Text>
      <ImageGrid className={style.images}>
        {Random.itemFromArray(data).map(item => {
          const { link, votes } = item;

          return <Image noMinImg src={link} text={`${votes}%`} key={link} />;
        })}
      </ImageGrid>
    </div>
  );
};
