import React, {
  FC,
  FormEvent,
  PropsWithChildren,
  ReactNode,
  createElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import './index.scss';
import { CSSTransition } from 'react-transition-group';
import mainUtils from 'shared/helpers/utils';
import keys from 'shared/helpers/utils/keys';
import { Icon } from '../icon';
import cl from 'classnames';
import { Portal } from '../portal/Portal';

enum ElementClassName {
  Header = '.modal__fixed-header',
  Footer = '.modal__fixed-footer',
}
enum CssVariables {
  Window = '--window-max-height',
  Header = '--modalFixedHeaderHeight',
  Footer = '--modalFixedFooterHeight',
  ModalPadding = '--modalPadding',
  ModalPaddingTop = '--modalBodyPaddingTop',
}

export enum BodyClassNames {
  Modal = 'body--modal',
  Close = 'body--close',
  CloseWindows = 'body--close-win',
}

export type TModal = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  bodyIsForm?: boolean;
  bodyClassNames?: string;
  bodySubmitHandler?: (evt: FormEvent) => void;
};

export const Modal: FC<TModal> = React.memo(
  ({
    isOpen: isShow,
    children,
    className = '',
    onClose: closeHandler,
    bodyIsForm = false,
    bodyClassNames = '',
    bodySubmitHandler = () => {},
  }) => {
    const nodeRef = useRef(null);
    const handleEscClose = React.useCallback(
      evt => {
        if (keys.isEsc(evt)) {
          evt.preventDefault();
          closeHandler();
        }
      },
      [closeHandler],
    );

    useEffect(() => {
      const isWindows = mainUtils.isWindows();

      if (isShow) {
        document.addEventListener('keydown', handleEscClose);
        document.body.classList.add(BodyClassNames.Modal);

        if (!isWindows) {
          document.body.classList.add(BodyClassNames.Close);
          // mainUtils.bodyFixPosition();
        } else {
          document.body.classList.add(
            BodyClassNames.Close,
            BodyClassNames.CloseWindows,
          );
        }
      }
      return () => {
        document.removeEventListener('keydown', handleEscClose);
        document.body.classList.remove(BodyClassNames.Modal);

        if (!isWindows) {
          document.body.classList.remove(BodyClassNames.Close);
          // mainUtils.bodyUnfixPosition();
        } else {
          document.body.classList.remove(
            BodyClassNames.Close,
            BodyClassNames.CloseWindows,
          );
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow]);

    const elementsData = [
      {
        classNameString: ElementClassName.Header,
        cssVariable: CssVariables.Header,
      },
      {
        classNameString: ElementClassName.Footer,
        cssVariable: CssVariables.Footer,
      },
    ];

    // useEffect(() => {
    //   const setWindowHeight = () => {
    //     const windowHeight = window.innerHeight;
    //     document.documentElement.style.setProperty(
    //       CssVariables.Window,
    //       `${windowHeight}px`,
    //     );
    //   };
    //   const setElementsHeight = () => {
    //     setTimeout(() => {
    //       elementsData.forEach(({ classNameString, cssVariable }) => {
    //         const elementHeight = document
    //           .querySelector(classNameString)
    //           ?.getBoundingClientRect().height;

    //         if (elementHeight) {
    //           document.documentElement.style.setProperty(
    //             cssVariable,
    //             `${elementHeight}px`,
    //           );
    //         }
    //       });
    //     }, 100);
    //   };
    //   setWindowHeight();
    //   setElementsHeight();
    //   window.addEventListener('resize', setWindowHeight);
    //   window.addEventListener('resize', setElementsHeight);

    //   return () => {
    //     window.removeEventListener('resize', setWindowHeight);
    //     window.removeEventListener('resize', setElementsHeight);
    //   };
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isShow]);

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
      const MIN_INNER_CONTENT_VISIBLE_HEIGHT = 50;
      const documentStyle = getComputedStyle(document.documentElement);
      const checkedMinHeight = elementsData
        .map(el => el.cssVariable)
        .concat([CssVariables.ModalPaddingTop, CssVariables.ModalPadding])
        .reduce((acc, cssVariable) => {
          const currentHeight = parseInt(
            documentStyle.getPropertyValue(cssVariable),
            10,
          );

          if (
            cssVariable === CssVariables.ModalPadding ||
            cssVariable === CssVariables.ModalPaddingTop
          ) {
            return acc + currentHeight * 2;
          }

          return acc + currentHeight;
        }, 0);

      const checkSmallScreen = () => {
        if (
          window.innerHeight <=
          checkedMinHeight + MIN_INNER_CONTENT_VISIBLE_HEIGHT
        ) {
          setIsSmallScreen(true);
        } else {
          setIsSmallScreen(false);
        }
      };
      checkSmallScreen();

      window.addEventListener('resize', checkSmallScreen);

      return () => {
        window.removeEventListener('resize', checkSmallScreen);
      };
    });

    return (
      <Portal>
        <CSSTransition
          in={isShow}
          timeout={300}
          classNames={{
            enter: 'modal--enter',
            enterActive: 'modal--enter-active',
            exit: 'modal--exit',
            exitActive: 'modal--exit-active',
          }}
          unmountOnExit
          nodeRef={nodeRef}
        >
          <div
            className={cl('modal', className, {
              ['modal--fixed']: !isSmallScreen,
            })}
          >
            <button
              className="modal__overlay"
              onClick={closeHandler}
              onKeyDown={closeHandler}
              type="button"
            >
              Overlay
            </button>
            {React.createElement(
              bodyIsForm ? 'form' : 'div',
              {
                className: `modal__body ${bodyClassNames}`,
                ref: nodeRef,
                onSubmit: bodySubmitHandler,
              },

              <>
                {children}
                <button
                  onClick={closeHandler}
                  className="modal__close"
                  type="button"
                  title="Закрыть"
                >
                  <Icon className="modal__close-icon" name="icon-close" />
                </button>
              </>,
            )}
          </div>
        </CSSTransition>
      </Portal>
    );
  },
);

const [ModalHeader, ModalInner, ModalFooter] = [
  'modal__fixed-header',
  'modal__inner scroll-block',
  'modal__fixed-footer',
].map(item => {
  return ({
    className = '',
    children,
    withWrap = true,
    type = 'div',
  }: {
    // eslint-disable-next-line react/require-default-props
    withWrap?: boolean;
    // eslint-disable-next-line react/require-default-props
    className?: string;
    children: ReactNode;
    // eslint-disable-next-line react/require-default-props
    type?: string;
  }) =>
    withWrap ? (
      <>
        {createElement(type, {
          className: `${item} ${className}`,
          children,
        })}
      </>
    ) : (
      <>{children}</>
    );
});

export { ModalHeader, ModalInner, ModalFooter };

export const ModalTitle: FC<PropsWithChildren<{ nopadding?: boolean }>> = ({
  children,
  nopadding,
}) => {
  return (
    <ModalHeader
      type="h3"
      className={cl('modal__title', { ['nopadding']: nopadding })}
    >
      {children}
    </ModalHeader>
  );
};

export const ModalDescription: FC<PropsWithChildren> = ({ children }) => {
  return <div className="modal__description">{children}</div>;
};
