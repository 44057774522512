import cl from 'classnames';
import styles from './index.module.scss';
import { HTMLAttributes } from 'react';

const Direction = {
  Rtl: 'rtl',
  Ltr: 'ltr',
} as const;

const Gender = {
  Male: 'male',
  Female: 'female',
} as const;

type TProgress = HTMLAttributes<HTMLElement> & {
  direction: typeof Direction[keyof typeof Direction];
  type?: 'default' | typeof Gender[keyof typeof Gender];
  width?: number;
  subWidth?: number;
};

export const ProgressLine = ({
  direction,
  width,
  subWidth,
  type = 'default',
  className,
}: TProgress) => {
  const LINE_DEFAULT_STYLE = cl(styles[type], {
    [styles.left]: direction === Direction.Ltr,
    [styles.right]: direction === Direction.Rtl,
  });

  return (
    <div className={cl(styles.progress, className)}>
      {width ? (
        <div
          className={cl(LINE_DEFAULT_STYLE, styles.mainLine, {
            [styles.noRadius]: subWidth,
          })}
          style={{ width: `${width}%` }}
        />
      ) : null}
      {subWidth ? (
        <div
          className={cl(LINE_DEFAULT_STYLE, styles.subLine)}
          style={{ width: `${subWidth}%` }}
        />
      ) : null}
    </div>
  );
};
