import {
  // combineReducers,
  configureStore,
  ReducersMapObject,
} from '@reduxjs/toolkit';
import testReducer from 'pages/test/store';
import authReducer from 'pages/user/store/store';
import pollsReducer from 'pages/cabinet/store/store';
import { AdminSupportReducer } from 'features/AdminSupport';
import { CompliantReducer } from 'features/ComplaintToComment';
import { TState } from './types';
import { userSupportReducer } from 'features/ContactSupport/model/slice/userSupportSlice';
import { ProfileFormReducer } from 'features/Profile/model/slice/ProfileFormSlice';
import { balanceReducer } from 'entities/Balance';
import { authBlockReducer } from 'entities/AuthBlock';
import { burgerBlockReducer } from 'shared/components/burgerBlock/model/slices/burgerBlockSlice';
import { tariffsReducer } from 'entities/tariffs';
import { sendMessageToRespondentsReducer } from 'features/SendMessageToRespondents';
import { refferalReducer } from 'pages/cabinet/profile/profileReferral/model/store';

// export const rootReducer = combineReducers({
//   test: testReducer,
//   auth: authReducer,
//   polls: pollsReducer,
//   adminSupport: AdminSupportReducer,
//   compliant: CompliantReducer,
//   profileForm: ProfileFormReducer,
// });

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });

export function createReduxStore() {
  const rootReducers: ReducersMapObject<TState> = {
    test: testReducer,
    auth: authReducer,
    polls: pollsReducer,
    adminSupport: AdminSupportReducer,
    userSupport: userSupportReducer,
    compliant: CompliantReducer,
    profileForm: ProfileFormReducer,
    balance: balanceReducer,
    authBlock: authBlockReducer,
    burgerBlock: burgerBlockReducer,
    tariffs: tariffsReducer,
    sendMessageToRespondents: sendMessageToRespondentsReducer,
    refferal: refferalReducer,
  };

  const store = configureStore({
    reducer: rootReducers,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

  return store;
}

// export type ТRootState = ReturnType<typeof rootReducer>;

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
