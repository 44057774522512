import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cl from 'classnames';
import styles from '../../index.module.scss';

export type TBlockProps = {
  current: number;
  start: number;
  count: number;
  setSearch: (arg: number) => string;
};

const renderBlockPagination: (arg: TBlockProps) => ReactNode = props => {
  const { current, start, count, setSearch } = props;
  const result: ReactNode[] = [];

  for (let i = start; i <= count; i += 1) {
    const isCurrent = current.toString() === i.toString();

    const item = isCurrent ? (
      <span className={styles.link}>{i}</span>
    ) : (
      <Link
        className={styles.link}
        to={{
          search: setSearch(i),
        }}
      >
        {i}
      </Link>
    );
    result.push(
      <li
        className={cl(styles.item, {
          [styles.current]: isCurrent,
        })}
        key={i}
      >
        {item}
      </li>,
    );
  }
  return result;
};

export default renderBlockPagination;
