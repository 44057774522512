import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { createReduxStore } from 'store';

// @todo текст Loading на компоеннет spinner
const store = createReduxStore();
const withStore =
  (component: () => React.ReactNode): FC =>
  () => {
    return <Provider store={store}>{component()}</Provider>;
  };

export default withStore;
