import { NavLink } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import style from '../navbarFooter/index.module.scss';
import cl from 'classnames';
import { FC, useCallback, useState } from 'react';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { AdminSupportModal } from 'features/AdminSupport';
import { sendMessageToRespondentsActions } from 'features/SendMessageToRespondents/model/slices/sendMessageToRespondents';

export const NavbarFooterSupport: FC = () => {
  const dispatch = useAppDispatch();
  const [isAdminSupportOpen, setIsAdminSuportOpen] = useState(false);

  const onOpenAdminSupport = useCallback(() => {
    setIsAdminSuportOpen(true);
  }, []);
  const onAdminSupportClose = useCallback(() => {
    setIsAdminSuportOpen(false);
  }, []);
  const onOpenSendMessageToRespondents = useCallback(() => {
    dispatch(sendMessageToRespondentsActions.sendIsopenModal(true));
  }, [dispatch]);

  return (
    <>
      <nav className={cl(style.navbarFooter)}>
        <NavLink
          to={'#'}
          className={cl(style.link)}
          onClick={onOpenAdminSupport}
        >
          {ROUTES.CONNECT.NAME}
        </NavLink>
        <NavLink
          to={'#'}
          className={cl(style.link)}
          onClick={onOpenSendMessageToRespondents}
        >
          {ROUTES.SPAM.NAME}
        </NavLink>
        <NavLink to={'#'} className={cl(style.link)} onClick={() => {}}>
          {ROUTES.NOTIFICATION.NAME}
        </NavLink>
      </nav>
      <AdminSupportModal
        isOpen={isAdminSupportOpen}
        onClose={onAdminSupportClose}
      />
    </>
  );
};
