import { Link } from 'react-router-dom';
import { Icon } from 'shared/components/icon';
import btnStyle from 'shared/components/button/index.module.scss';
import cl from 'classnames';
import styles from './index.module.scss';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

type ItemList = {
  withStep?: boolean;
  withButtons?: boolean;
  className?: string;
  classNameItem?: string;
  classNameTitle?: string;
  data: {
    id: string;
    title: ReactNode;
    text: ReactNode;
    link?: string;
    img?: ReactNode;
  }[];
};

const ItemsList: FC<PropsWithChildren<ItemList>> = ({
  withButtons = true,
  withStep = false,
  className = '',
  classNameItem = '',
  classNameTitle = '',
  data,
}) => {
  return (
    <ul className={cl(styles.list, className)}>
      {data.map(({ title, text, link, img, id }, idx) => {
        return (
          <li className={cl(styles.item, classNameItem)} key={uuidv4()}>
            <h2 className={cl(styles.title, classNameTitle)}>{title}</h2>
            {img && <div className={styles.img}>{img}</div>}
            <p className={cl(styles.text)}>{text}</p>
            {withStep && <div className={styles.step}>{idx + 1}</div>}
            {withButtons && (
              <div className={styles.buttons}>
                <Link
                  className={cl(btnStyle.button, btnStyle.withIcon, {
                    [btnStyle.disabled]: !link,
                  })}
                  to={link || ''}
                  state={id || null}
                >
                  <Icon name="icon-plus" />
                  Создать
                </Link>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export { ItemsList };
