import { memo, FC, PropsWithChildren, HTMLAttributes } from 'react';
import styles from './Section.module.scss';
import cl from 'classnames';

type TSection = HTMLAttributes<HTMLElement> & {
  bordered?: boolean;
  first?: boolean;
  last?: boolean;
};

const Section: FC<PropsWithChildren<TSection>> = memo(
  ({ children, bordered = true, first, last, id, className }) => {
    return (
      <section
        id={id}
        className={cl(className, styles.section, {
          [styles.bordered]: bordered,
          [styles.first]: first,
          [styles.last]: last,
        })}
      >
        {children}
      </section>
    );
  },
);

export { Section };
