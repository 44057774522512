import { memo, FC, HTMLAttributes } from 'react';
import styles from './Text.module.scss';
import cl from 'classnames';

const Text: FC<
  HTMLAttributes<HTMLElement> & {
    min?: boolean;
  }
> = memo(({ children, className, min }) => {
  return (
    <p
      className={cl(className, styles.text, {
        [styles.min]: min,
      })}
    >
      {children}
    </p>
  );
});

export { Text };
