import { ReactNode, SVGProps } from 'react';
import cl from 'classnames';
import styles from './index.module.scss';

const files = {
  byId: {
    'control-mark-checkbox': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M15.71,6.29c-0.39-0.39-1.02-0.39-1.41,0c-0.31,0.31-3.76,3.76-5.29,5.29c-0.81-0.81-2.01-2.01-2.29-2.29c-0.39-0.39-1.02-0.39-1.41,0c-0.39,0.39-0.39,1.02,0,1.41c0.39,0.39,2.61,2.61,3,3c0.39,0.39,1.02,0.39,1.41,0c0.39-0.39,5.61-5.61,6-6C16.1,7.32,16.1,6.68,15.71,6.29z"/>',
    },
    'control-mark-radiobutton': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<circle xmlns="http://www.w3.org/2000/svg" cx="10" cy="10" r="3"/>',
    },
    'icon-arrow-down': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M9,4v9.586c-0.578-0.578-1.118-1.117-1.292-1.293c-0.391-0.391-1.024-0.391-1.415,0s-0.391,1.023,0,1.414l3,3c0.39,0.391,1.023,0.391,1.414,0l3-3c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0c-0.175,0.176-0.715,0.715-1.293,1.293V4c0-0.553-0.448-1-1-1S9,3.448,9,4z"/>',
    },
    'icon-arrow-left': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16,9H6.414c0.578-0.578,1.118-1.118,1.293-1.292c0.391-0.391,0.391-1.024,0-1.415s-1.023-0.391-1.414,0l-3,3c-0.391,0.39-0.391,1.023,0,1.414l3,3c0.391,0.391,1.023,0.391,1.414,0s0.391-1.023,0-1.414C7.532,12.119,6.992,11.579,6.414,11H16c0.553,0,1-0.448,1-1S16.553,9,16,9z"/>',
    },
    'icon-arrow-right': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M4,9h9.586c-0.578-0.578-1.117-1.118-1.293-1.292c-0.391-0.391-0.391-1.024,0-1.415s1.023-0.391,1.414,0l3,3c0.391,0.39,0.391,1.023,0,1.414l-3,3c-0.391,0.391-1.023,0.391-1.414,0s-0.391-1.023,0-1.414c0.176-0.175,0.715-0.715,1.293-1.293H4c-0.553,0-1-0.448-1-1S3.447,9,4,9z"/>',
    },
    'icon-arrow-up': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M9,16V6.415C8.422,6.993,7.882,7.532,7.708,7.708c-0.391,0.391-1.024,0.391-1.415,0s-0.391-1.023,0-1.414l3-3c0.39-0.391,1.023-0.391,1.414,0l3,3c0.391,0.391,0.391,1.023,0,1.414s-1.023,0.391-1.414,0C12.118,7.532,11.578,6.993,11,6.415V16c0,0.553-0.448,1-1,1S9,16.553,9,16z"/>',
    },
    'icon-barchart': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16,6h-3V3c0-0.55-0.45-1-1-1H8C7.45,2,7,2.45,7,3v7H4c-0.55,0-1,0.45-1,1v6c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1V7C17,6.45,16.55,6,16,6z M7,16H5v-4h2V16z M11,7v9H9V4h2V7z M15,16h-2V8h2V16z"/>',
    },
    'icon-bell': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16.89,14.55L15,10.77V7c0-2.76-2.24-5-5-5S5,4.24,5,7l0,3.76l-1.9,3.79c-0.16,0.31-0.14,0.67,0.04,0.97S3.65,16,4,16h4c0,0,0,0,0,0c0,1.1,0.9,2,2,2s2-0.9,2-2c0,0,0,0,0,0h4c0.35,0,0.67-0.18,0.85-0.47S17.05,14.86,16.89,14.55z M5.62,14l1.28-2.55C6.96,11.3,7,11.16,7,11l0-4c0-1.66,1.34-3,3-3s3,1.34,3,3l0,4c0,0.15,0.04,0.31,0.1,0.45L14.38,14H5.62z"/>',
    },
    'icon-burger': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16,9H4c-0.552,0-1,0.448-1,1c0,0.553,0.448,1,1,1h12c0.553,0,1-0.447,1-1C17,9.448,16.553,9,16,9z M4,6h12c0.553,0,1-0.448,1-1s-0.447-1-1-1H4C3.448,4,3,4.448,3,5S3.448,6,4,6z M16,14H4c-0.552,0-1,0.447-1,1s0.448,1,1,1h12c0.553,0,1-0.447,1-1S16.553,14,16,14z"/>',
    },
    'icon-calendar': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M17,3h-3c0-0.55-0.45-1-1-1c-0.55,0-1,0.45-1,1H8c0-0.55-0.45-1-1-1C6.45,2,6,2.45,6,3H3C2.45,3,2,3.45,2,4v12c0,0.55,0.45,1,1,1h14c0.55,0,1-0.45,1-1V4C18,3.45,17.55,3,17,3z M16,15H4V9h12V15z M16,7H4V5h2c0,0.55,0.45,1,1,1c0.55,0,1-0.45,1-1h4c0,0.55,0.45,1,1,1c0.55,0,1-0.45,1-1h2V7z"/>',
    },
    'icon-clock': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M10,2c-4.42,0-8,3.58-8,8s3.58,8,8,8c4.42,0,8-3.58,8-8S14.42,2,10,2z M10,16c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S13.31,16,10,16z M11,9.59V6c0-0.55-0.45-1-1-1C9.45,5,9,5.45,9,6v4c0,0.28,0.11,0.53,0.29,0.71c0.39,0.39,1.61,1.61,2,2c0.39,0.39,1.02,0.39,1.41,0c0.39-0.39,0.39-1.02,0-1.41C12.39,10.97,11.52,10.11,11,9.59z"/>',
    },
    'icon-close': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M11.414,10l5.293-5.293c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L10,8.586L4.707,3.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L8.586,10l-5.293,5.293c-0.391,0.391-0.391,1.023,0,1.414s1.023,0.391,1.414,0L10,11.415l5.293,5.293c0.391,0.391,1.023,0.391,1.414,0s0.391-1.023,0-1.414L11.414,10z"/>',
    },
    'icon-comment': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M14,4H6C4.343,4,3,5.343,3,7v4c0,1.657,1.343,3,3,3h3.586l3.706,3.706C13.473,17.888,13.723,18,14,18c0.553,0,1-0.447,1-1v-3.184c1.163-0.413,2-1.512,2-2.816V7C17,5.343,15.657,4,14,4zM15,11c0,0.553-0.447,1-1,1s-1,0.447-1,1v1.586l-2.293-2.293C10.512,12.098,10.256,12,10,12H6c-0.552,0-1-0.447-1-1V7c0-0.553,0.448-1,1-1h8c0.553,0,1,0.447,1,1V11z"/>',
    },
    'icon-correct': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M10,2c-4.418,0-8,3.582-8,8c0,4.418,3.582,8,8,8s8-3.582,8-8C18,5.582,14.418,2,10,2z M10,16c-3.313,0-6-2.687-6-6s2.687-6,6-6s6,2.687,6,6S13.313,16,10,16z M12.293,7.293L9,10.586L7.707,9.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414s2,2,2,2c0.391,0.391,1.023,0.391,1.414,0l4-4c0.391-0.391,0.391-1.023,0-1.414S12.684,6.902,12.293,7.293z"/>',
    },
    'icon-cross': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M11.414,10l3.293-3.293c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L10,8.586L6.707,5.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L8.586,10l-3.293,3.293c-0.391,0.391-0.391,1.023,0,1.414s1.023,0.391,1.414,0L10,11.415l3.293,3.293c0.391,0.391,1.023,0.391,1.414,0s0.391-1.023,0-1.414L11.414,10z"/>',
    },
    'icon-cycle': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M17.71,9.29c-0.39-0.39-1.02-0.39-1.41,0C16.22,9.36,16.12,9.47,16,9.59V8c0-2.76-2.24-5-5-5H9C8.45,3,8,3.45,8,4s0.45,1,1,1h2c1.66,0,3,1.34,3,3v1.59c-0.12-0.12-0.22-0.22-0.29-0.29c-0.39-0.39-1.02-0.39-1.41,0c-0.39,0.39-0.39,1.02,0,1.41c0.39,0.39,1.61,1.61,2,2c0.39,0.39,1.02,0.39,1.41,0c0,0,1.61-1.61,2-2C18.1,10.32,18.1,9.68,17.71,9.29z M11,15H9c-1.66,0-3-1.34-3-3v-1.59c0.12,0.12,0.22,0.22,0.29,0.29c0.39,0.39,1.02,0.39,1.41,0c0.39-0.39,0.39-1.02,0-1.41c-0.39-0.39-1.61-1.61-2-2c-0.39-0.39-1.02-0.39-1.41,0c0,0,0,0,0,0c-0.39,0.39-1.61,1.61-2,2c-0.39,0.39-0.39,1.02,0,1.41c0.39,0.39,1.02,0.39,1.41,0c0.07-0.07,0.18-0.18,0.29-0.29V12c0,2.76,2.24,5,5,5h2c0.55,0,1-0.45,1-1S11.55,15,11,15z"/>',
    },
    'icon-drop': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M15.707,6.293c-0.391-0.391-1.023-0.391-1.414,0L10,10.586L5.707,6.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l5,5c0.391,0.391,1.023,0.391,1.414,0l5-5C16.098,7.317,16.098,6.684,15.707,6.293z"/>',
    },
    'icon-edit': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16.707,6.293l-3-3c-0.391-0.391-1.023-0.391-1.414,0l-7.999,7.999C4.112,11.473,4,11.724,4,12v3c0,0.553,0.448,1,1,1h3c0.276,0,0.527-0.112,0.708-0.294l8-7.999C17.098,7.316,17.098,6.684,16.707,6.293z M7.586,14H6v-1.586l4.5-4.5L12.086,9.5L7.586,14z M13.5,8.086L11.914,6.5L13,5.414L14.586,7L13.5,8.086z"/>',
    },
    'icon-folder': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M17,5h-6.59C9.89,4.48,9.03,3.61,8.71,3.29C8.51,3.1,8.26,3,8,3H3C2.45,3,2,3.45,2,4v12c0,0.55,0.45,1,1,1h14c0.55,0,1-0.45,1-1V6C18,5.45,17.55,5,17,5z M4,10h12v5H4V10z M9.29,6.71C9.49,6.9,9.74,7,10,7c0,0,6,0,6,0v1H4V5h3.59C8.11,5.52,8.97,6.39,9.29,6.71z"/>',
    },
    'icon-funnel': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M17.53,2.153C17.364,2.049,17.182,2,17,2H3C2.818,2,2.636,2.049,2.47,2.153C2.002,2.445,1.859,3.062,2.152,3.531L7,11.288V17c0,0.553,0.448,1,1,1h4c0.553,0,1-0.447,1-1v-5.714l4.848-7.756C18.141,3.062,17.998,2.445,17.53,2.153z M9,16v-5c0-0.182-0.048-0.365-0.152-0.53L4.805,4h10.392l-4.044,6.47C11.049,10.636,11,10.819,11,11v5H9z"/>',
    },
    'icon-heart-full': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16.61,4.39C14.79,2.57,11.86,2.55,10,4.3C8.14,2.55,5.21,2.57,3.39,4.39c-1.85,1.85-1.86,4.86,0,6.72l5.9,5.9c0.39,0.39,1.02,0.39,1.41,0l5.9-5.9C18.46,9.25,18.46,6.25,16.61,4.39z"/>',
    },
    'icon-heart': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16.61,4.39C14.79,2.57,11.86,2.55,10,4.3C8.14,2.55,5.21,2.57,3.39,4.39c-1.85,1.85-1.86,4.86,0,6.72l5.9,5.9c0.39,0.39,1.02,0.39,1.41,0l5.9-5.9C18.46,9.25,18.46,6.25,16.61,4.39z M15.19,9.69L10,14.89L4.81,9.69c-1.07-1.07-1.07-2.81,0-3.89c1.07-1.07,2.82-1.07,3.89,0L10,7.11l1.31-1.31c1.07-1.07,2.82-1.07,3.89,0C16.27,6.88,16.27,8.62,15.19,9.69z"/>',
    },
    'icon-image': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16,4H4C3.448,4,3,4.448,3,5v10c0,0.553,0.448,1,1,1h12c0.553,0,1-0.447,1-1V5C17,4.448,16.553,4,16,4z M15,14H5V6h10V14z M12.5,10c0.828,0,1.5-0.671,1.5-1.5S13.328,7,12.5,7S11,7.672,11,8.5S11.672,10,12.5,10z M9.5,9.5L6,13h7L9.5,9.5z"/>',
    },
    'icon-indicator-star': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M14.951,8.691C14.82,8.29,14.445,8,14,8h-2.33l-0.719-2.215C10.813,5.362,10.421,5.093,10,5.093c-0.421,0-0.813,0.269-0.951,0.691L8.329,8H6C5.555,8,5.179,8.29,5.048,8.691c-0.13,0.401,0.004,0.856,0.364,1.118l1.884,1.369l-0.72,2.215c-0.137,0.424,0.021,0.871,0.363,1.119s0.816,0.261,1.176-0.001L10,13.143l1.885,1.368c0.359,0.262,0.834,0.249,1.176,0.001c0.34-0.248,0.5-0.695,0.363-1.119l-0.72-2.215l1.884-1.369C14.947,9.547,15.082,9.092,14.951,8.691z"/>',
    },
    'icon-indicator-tick': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M15.707,6.293c-0.391-0.391-1.023-0.391-1.414,0L9,11.586L6.707,9.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l3,3c0.391,0.391,1.023,0.391,1.414,0l6-6C16.098,7.317,16.098,6.684,15.707,6.293z"/>',
    },
    'icon-info': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M10,2c-4.418,0-8,3.582-8,8c0,4.418,3.582,8,8,8c4.418,0,8-3.582,8-8C18,5.582,14.418,2,10,2z M10,16c-3.313,0-6-2.687-6-6s2.687-6,6-6s6,2.687,6,6S13.313,16,10,16z M10,9c-0.552,0-1,0.448-1,1v3c0,0.553,0.448,1,1,1s1-0.447,1-1v-3C11,9.448,10.552,9,10,9z M10,6C9.448,6,9,6.448,9,7s0.448,1,1,1s1-0.448,1-1S10.552,6,10,6z"/>',
    },
    'icon-key': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M17.707,7.293L15.414,5l1.293-1.293c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L8.02,9.566C7.425,9.215,6.74,9,6,9c-2.209,0-4,1.791-4,4s1.791,4,4,4s4-1.791,4-4c0-0.74-0.215-1.426-0.566-2.02L12,8.414l1.293,1.293c0.391,0.391,1.023,0.391,1.414,0s0.391-1.023,0-1.414L13.414,7L14,6.414l2.293,2.293c0.391,0.391,1.023,0.391,1.414,0S18.098,7.684,17.707,7.293z M6,15c-1.104,0-2-0.896-2-2s0.896-2,2-2s2,0.896,2,2S7.104,15,6,15z"/>',
    },
    'icon-list-unordered': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M9,6h7c0.552,0,1-0.447,1-1s-0.448-1-1-1H9C8.448,4,8,4.447,8,5S8.448,6,9,6z M16,9H9c-0.552,0-1,0.447-1,1s0.448,1,1,1h7c0.552,0,1-0.447,1-1S16.552,9,16,9z M16,14H9c-0.552,0-1,0.447-1,1s0.448,1,1,1h7c0.552,0,1-0.447,1-1S16.552,14,16,14z M5,4H4C3.448,4,3,4.447,3,5s0.448,1,1,1h1c0.552,0,1-0.447,1-1S5.552,4,5,4z M5,9H4c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1S5.552,9,5,9z M5,14H4c-0.552,0-1,0.447-1,1s0.448,1,1,1h1c0.552,0,1-0.447,1-1S5.552,14,5,14z"/>',
    },
    'icon-options': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M10,6c1.104,0,2-0.896,2-2s-0.896-2-2-2S8,2.896,8,4S8.896,6,10,6zM10,8c-1.104,0-2,0.896-2,2s0.896,2,2,2s2-0.896,2-2S11.104,8,10,8z M10,14c-1.104,0-2,0.896-2,2s0.896,2,2,2s2-0.896,2-2S11.104,14,10,14z"/>',
    },
    'icon-plus': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M15,9h-4V5c0-0.553-0.448-1-1-1S9,4.447,9,5v4H5c-0.552,0-1,0.447-1,1s0.448,1,1,1h4v4c0,0.553,0.448,1,1,1s1-0.447,1-1v-4h4c0.553,0,1-0.447,1-1S15.553,9,15,9z"/>',
    },
    'icon-piechart': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M12,6V3c0-0.55-0.45-1-1-1c-4.97,0-9,4.03-9,9c0,0.55,0.45,1,1,1h3c0,3.31,2.69,6,6,6c3.31,0,6-2.69,6-6C18,8.69,15.31,6,12,6z M4.08,10C4.52,6.94,6.94,4.52,10,4.08V10H4.08z M12,16c-2.21,0-4-1.79-4-4h3c0.55,0,1-0.45,1-1V8c2.21,0,4,1.79,4,4C16,14.21,14.21,16,12,16z"/>',
    },
    'icon-sms-code': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M15,4H5C3.34,4,2,5.34,2,7v4c0,1.3,0.84,2.4,2,2.82V17c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29c0.34-0.34,2.8-2.8,3.71-3.71H15c1.66,0,3-1.34,3-3V7C18,5.34,16.66,4,15,4z M16,11c0,0.55-0.45,1-1,1H9c-0.26,0-0.51,0.1-0.71,0.29c-0.22,0.22-1.3,1.3-2.29,2.29V13c0-0.55-0.45-1-1-1s-1-0.45-1-1V7c0-0.55,0.45-1,1-1h10c0.55,0,1,0.45,1,1V11z M6,10h8V8H6V10z"/>',
    },
    'icon-sort': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M18.707,12.293c-0.391-0.391-1.023-0.391-1.414,0L16,13.586V4c0-0.552-0.447-1-1-1s-1,0.448-1,1v9.586l-1.293-1.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l3,3c0.391,0.391,1.023,0.391,1.414,0l3-3C19.098,13.317,19.098,12.684,18.707,12.293z M5.707,3.293c-0.391-0.391-1.023-0.391-1.414,0l-3,3c-0.391,0.391-0.391,1.023,0,1.414s1.023,0.391,1.414,0L4,6.415V16c0,0.553,0.448,1,1,1s1-0.447,1-1V6.415l1.293,1.293c0.391,0.391,1.023,0.391,1.414,0s0.391-1.023,0-1.414L5.707,3.293z"/>',
    },
    'icon-spinner': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M17,9c0.55,0,1,0.45,1,1c0,0.55-0.45,1-1,1h-2c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1H17z" opacity=".125"/><path xmlns="http://www.w3.org/2000/svg" d="M15.657,14.242c0.389,0.39,0.389,1.025,0,1.414c-0.389,0.39-1.025,0.389-1.414,0l-1.414-1.414c-0.389-0.389-0.39-1.025,0-1.414c0.389-0.389,1.024-0.389,1.413,0L15.657,14.242z" opacity=".25"/><path xmlns="http://www.w3.org/2000/svg" d="M11,17c0,0.55-0.45,1-1,1c-0.55,0-1-0.45-1-1v-2c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1V17z" opacity=".375"/><path xmlns="http://www.w3.org/2000/svg" d="M5.757,15.656c-0.389,0.389-1.025,0.39-1.414,0.001c-0.389-0.39-0.389-1.025,0-1.415l1.414-1.414c0.389-0.389,1.025-0.389,1.414,0s0.389,1.025,0,1.414L5.757,15.656z" opacity=".5"/><path xmlns="http://www.w3.org/2000/svg" d="M5,9c0.55,0,1,0.45,1,1s-0.45,1-1,1H3c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1H5z" opacity=".625"/><path xmlns="http://www.w3.org/2000/svg" d="M7.171,5.758c0.389,0.389,0.389,1.025,0,1.414c-0.389,0.389-1.025,0.389-1.414,0L4.343,5.757c-0.389-0.389-0.389-1.025,0-1.414c0.389-0.389,1.025-0.389,1.414,0L7.171,5.758z" opacity=".75"/><path xmlns="http://www.w3.org/2000/svg" d="M11,5c0,0.55-0.45,1-1,1C9.45,6,9,5.55,9,5V3c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1V5z" opacity=".875"/><path xmlns="http://www.w3.org/2000/svg" d="M14.242,7.171c-0.389,0.389-1.025,0.389-1.414,0s-0.389-1.025,0-1.414l1.414-1.414c0.39-0.389,1.025-0.389,1.415,0c0.389,0.389,0.388,1.025-0.001,1.414L14.242,7.171z"/>',
    },
    'icon-trash': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16,6h-3V4c0-0.552-0.447-1-1-1H8C7.448,3,7,3.448,7,4v2H4C3.448,6,3,6.448,3,7s0.448,1,1,1h0.604l0.901,8.11C5.562,16.618,5.99,17,6.5,17h7c0.508,0,0.936-0.386,0.993-0.89L15.395,8H16c0.553,0,1-0.448,1-1S16.553,6,16,6z M9,5h2v1H9V5z M12.604,14.999H7.394L6.616,8h6.767L12.604,14.999z"/>',
    },
    'icon-upload': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M7.707,7.707L9,6.414V12c0,0.553,0.448,1,1,1c0.553,0,1-0.447,1-1V6.414l1.293,1.293c0.391,0.391,1.023,0.391,1.414,0s0.391-1.023,0-1.414l-3-3c-0.391-0.391-1.023-0.391-1.414,0l-3,3c-0.391,0.391-0.391,1.023,0,1.414S7.316,8.098,7.707,7.707z M16,15H4c-0.552,0-1,0.447-1,1s0.448,1,1,1h12c0.553,0,1-0.447,1-1S16.553,15,16,15z"/>',
    },
    'icon-user': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M13.172,12.412C14.288,11.128,15,9.181,15,7c0-2.761-2.238-5-5-5C7.239,2,5,4.239,5,7c0,2.181,0.712,4.128,1.828,5.412C3.988,13.18,1.999,14.944,1.999,17H2c0,0.553,0.448,1,1,1h14c0.553,0,1-0.447,1-1h0.001C18.001,14.944,16.012,13.18,13.172,12.412z M10,4c1.656,0,3,1.343,3,3c0,2.761-1.344,4.999-3,4.999c-1.657,0-3-2.238-3-4.999C7,5.343,8.343,4,10,4z M4.351,16C5.175,14.836,7.39,14,10,14s4.825,0.836,5.649,2H4.351z"/>',
    },
    'icon-download': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16,15H4c-0.552,0-1,0.447-1,1s0.448,1,1,1h12c0.553,0,1-0.447,1-1S16.553,15,16,15z M9.293,12.707c0.391,0.391,1.023,0.391,1.414,0l3-3c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L11,9.586V4c0-0.553-0.448-1-1-1S9,3.447,9,4v5.586L7.707,8.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414S9.293,12.707,9.293,12.707z"/>',
    },
    'icon-card': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M15,16H5c-1.66,0-3-1.34-3-3V7c0-1.66,1.34-3,3-3h10c1.66,0,3,1.34,3,3v6C18,14.66,16.66,16,15,16z M5,14h10c0.55,0,1-0.45,1-1v-3H4v3C4,13.55,4.45,14,5,14z M15,6H5C4.45,6,4,6.45,4,7v1h12V7C16,6.45,15.55,6,15,6z"/>',
    },
    'icon-link': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16.707,3.293c-0.391-0.391-1.023-0.391-1.414,0L13.89,4.697c-1.738-1.094-4.058-0.892-5.571,0.622c-1.758,1.757-1.243,1.242-3,3c-1.514,1.514-1.716,3.833-0.622,5.571l-1.403,1.403c-0.391,0.391-0.391,1.023,0,1.414s1.023,0.391,1.414,0l1.403-1.403c1.738,1.094,4.058,0.892,5.571-0.622c1.758-1.757,1.243-1.242,3-3c1.514-1.513,1.716-3.833,0.622-5.571l1.403-1.403C17.098,4.317,17.098,3.684,16.707,3.293zM13.268,10.269c-0.977,0.976-2.023,2.023-3,3c-0.725,0.725-1.782,0.909-2.679,0.558l1.119-1.118c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-1.119,1.118c-0.351-0.896-0.167-1.954,0.558-2.679c0.977-0.977,2.023-2.024,3-3c0.725-0.725,1.782-0.909,2.679-0.558l-1.118,1.119c-0.391,0.391-0.391,1.023,0,1.414s1.023,0.391,1.414,0l1.118-1.119C14.177,8.486,13.992,9.544,13.268,10.269z"/>',
    },
    'icon-copy': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M16,3H8C7.45,3,7,3.45,7,4v3H4C3.45,7,3,7.45,3,8v8c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v-3h3c0.55,0,1-0.45,1-1V4C17,3.45,16.55,3,16,3z M11,15H5V9h2v3c0,0.55,0.45,1,1,1h3V15z M15,11H9V5h6V11z"/>',
    },
    'icon-youtube': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M17.67,5.87c-0.18-0.74-0.73-1.32-1.41-1.52C15,4,10,4,10,4S5,4,3.75,4.36c-0.69,0.2-1.23,0.78-1.41,1.52C2,7.21,2,10,2,10s0,2.79,0.33,4.13c0.18,0.74,0.73,1.32,1.41,1.52C5,16,10,16,10,16s5,0,6.25-0.36c0.69-0.2,1.23-0.78,1.41-1.52C18,12.79,18,10,18,10S18,7.21,17.67,5.87z M8,13V7l5.29,3L8,13z"/>',
    },
    'icon-instagram': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M10,6c-2.21,0-4,1.79-4,4s1.79,4,4,4s4-1.79,4-4S12.21,6,10,6z M10,12c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S11.1,12,10,12zM14,5c-0.55,0-1,0.45-1,1s0.45,1,1,1s1-0.45,1-1S14.55,5,14,5z M14,2H6C3.79,2,2,3.79,2,6v8c0,2.21,1.79,4,4,4h8c2.21,0,4-1.79,4-4V6C18,3.79,16.21,2,14,2z M16,14c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h8c1.1,0,2,0.9,2,2V14z"/>',
    },
    'icon-telegram': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M17.97,3.6c-0.02-0.12-0.06-0.29-0.21-0.42C17.58,3.03,17.31,3,17.19,3c-0.55,0.01-1.4,0.32-5.5,2.12c-1.43,0.63-4.3,1.93-8.59,3.91C2.4,9.32,2.04,9.61,2,9.89c-0.06,0.54,0.67,0.71,1.59,1.02c0.75,0.26,1.76,0.56,2.29,0.57c0.48,0.01,1.01-0.2,1.6-0.62C11.5,8,13.57,6.55,13.7,6.52C13.79,6.5,13.92,6.47,14,6.55c0.09,0.08,0.08,0.23,0.07,0.28c-0.07,0.33-3.84,3.95-4.06,4.19c-0.83,0.91-1.77,1.47-0.32,2.48c1.26,0.88,1.99,1.43,3.29,2.33c0.83,0.57,1.48,1.25,2.33,1.17c0.39-0.04,0.8-0.43,1.01-1.59c0.49-2.75,1.45-8.72,1.67-11.18C18.01,4,17.99,3.72,17.97,3.6z"/>',
    },
    'icon-vk': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M10,2c-7,0-8,1-8,8s1,8,8,8s8-1,8-8S17,2,10,2z M13.1,13c-0.37-1.14-1.28-2.02-2.49-2.14V13h-0.19c-3.29,0-5.16-2.25-5.24-6h1.65c0.05,2.75,1.27,3.92,2.23,4.16V7h1.55v2.37c0.95-0.1,1.95-1.18,2.28-2.37h1.55c-0.26,1.47-1.34,2.55-2.11,2.99c0.77,0.36,2,1.3,2.47,3.01H13.1z"/>',
    },
    'icon-switch-off': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M13,4H7c-3.314,0-6,2.687-6,6s2.686,6,6,6h6c3.313,0,6-2.687,6-6S16.313,4,13,4z M7,14c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S9.209,14,7,14z"/>',
    },
    'icon-switch-on': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M13,4H7c-3.313,0-6,2.687-6,6s2.687,6,6,6h6c3.313,0,6-2.687,6-6S16.313,4,13,4z M13,14c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S15.209,14,13,14z"/>',
    },
    'icon-eraser': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<path xmlns="http://www.w3.org/2000/svg" d="M17.268,6.732l-3-3c-0.977-0.977-2.559-0.977-3.535,0s-6.024,6.023-7,7c-0.976,0.977-0.976,2.559,0,3.535C3.97,14.506,4.22,14.756,4.464,15H3c-0.552,0-1,0.447-1,1s0.448,1,1,1h13c0.552,0,1-0.447,1-1s-0.448-1-1-1h-3.465l4.732-4.732C18.244,9.291,18.244,7.709,17.268,6.732z M9.707,15H7.293l-2.146-2.146c-0.195-0.195-0.195-0.512,0-0.707l3.793-3.793l3.707,3.707L9.707,15z M15.854,8.854l-1.793,1.793l-3.707-3.707l1.793-1.793c0.195-0.195,0.512-0.195,0.707,0l3,3C16.049,8.342,16.049,8.658,15.854,8.854z"/>',
    },
    'icon-lock': {
      width: 20,
      height: 20,
      viewBox: [0, 0, 20, 20],
      data: '<circle cx="10" cy="14" r="1"/><path d="M15,10h-1V6c0-2.21-1.79-4-4-4C7.79,2,6,3.79,6,6v4H5c-0.55,0-1,0.45-1,1v6c0,0.55,0.45,1,1,1h10c0.55,0,1-0.45,1-1v-6C16,10.45,15.55,10,15,10z M8,6c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2v4H8V6z M14,16H6v-4h8V16z"/>',
    },
  },
  allIds: [
    'control-mark-checkbox',
    'control-mark-radiobutton',
    'icon-arrow-down',
    'icon-arrow-left',
    'icon-arrow-right',
    'icon-arrow-up',
    'icon-barchart',
    'icon-bell',
    'icon-burger',
    'icon-calendar',
    'icon-clock',
    'icon-close',
    'icon-comment',
    'icon-correct',
    'icon-cross',
    'icon-cycle',
    'icon-drop',
    'icon-edit',
    'icon-folder',
    'icon-funnel',
    'icon-heart-full',
    'icon-heart',
    'icon-image',
    'icon-indicator-star',
    'icon-indicator-tick',
    'icon-info',
    'icon-key',
    'icon-list-unordered',
    'icon-options',
    'icon-plus',
    'icon-piechart',
    'icon-sms-code',
    'icon-sort',
    'icon-spinner',
    'icon-trash',
    'icon-upload',
    'icon-user',
    'icon-download',
    'icon-card',
    'icon-link',
    'icon-copy',
    'icon-youtube',
    'icon-instagram',
    'icon-telegram',
    'icon-vk',
    'icon-switch-off',
    'icon-switch-on',
    'icon-eraser',
    'icon-lock',
  ] as const,
};
export type TIcon = typeof files.allIds[number] | '';

const PREFIX = 'img';

type TSvgProps = SVGProps<SVGSVGElement> & {
  name: TIcon;
  size?: number;
};

function Icon({
  size,
  width = size,
  height = size,
  name,
  className = '',
}: TSvgProps) {
  const file = files.byId[name];

  if (!file) return null;
  const DEFAULT_SIZE = size || 20;
  const currentWidth = width || file.width || DEFAULT_SIZE;
  const currentHeight = height || file.height || DEFAULT_SIZE;
  const viewBox = file.viewBox
    ? file.viewBox.join(' ')
    : `0 0 ${width} ${height}`;

  return (
    <svg
      className={cl('icon', styles.icon, className)}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="xMinYMin"
      width={currentWidth}
      height={currentHeight}
      viewBox={viewBox}
    >
      <use xlinkHref={`#${PREFIX}-${name}`} />
    </svg>
  );
}

function SVGSource() {
  return (
    <svg display="none">
      <defs>
        {files.allIds.reduce((defs, fileId, fileIndex) => {
          const file = files.byId[fileId];

          return defs.concat(
            <g
              key={fileIndex}
              id={`${PREFIX}-${fileId}`}
              dangerouslySetInnerHTML={{ __html: file.data }}
            />,
          );
        }, [] as ReactNode[])}
      </defs>
    </svg>
  );
}

type TGetIconStr = SVGProps<SVGSVGElement> & {
  name: TIcon;
  size?: number;
};

function getIconStr({
  size,
  width = size,
  height = size,
  name,
  className,
}: TGetIconStr) {
  const file = files.byId[name];

  if (!file) return null;
  const DEFAULT_SIZE = size || 20;
  const currentWidth = width || file.width || DEFAULT_SIZE;
  const currentHeight = height || file.height || DEFAULT_SIZE;
  const viewBox = file.viewBox
    ? file.viewBox.join(' ')
    : `0 0 ${width} ${height}`;

  return `
    <svg
      className="${cl('icon', styles.icon, className)}"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      preserveAspectRatio="xMinYMin"
      width="${currentWidth}"
      height="${currentHeight}"
      viewBox="${viewBox}"
    >
      <use xlink:href="${`#${PREFIX}-${name}`}" />
    </svg>`;
}

export { Icon, SVGSource, getIconStr };
