import Network from 'shared/libraries/network';
import CONFIG from 'shared/constants/config';
import {
  TReferralChartRequest,
  TReferralTransactionsRequest,
  TReferralWithdrawRequest,
} from 'pages/cabinet/profile/profileReferral/model/schema';

class RefrralModel extends Network {
  constructor(baseURL: string, timeout = 120000) {
    super(baseURL, timeout);
  }

  //GET
  //Получение списка транзакций начислений по реферальной программе
  async getReferralTransactions<T>(
    data: TReferralTransactionsRequest,
  ): Promise<T> {
    return this.send('get', `/transactions/referral`, { params: { ...data } });
  }

  async getReferralCharts<T>(data: TReferralChartRequest): Promise<T> {
    return this.send('get', `/transactions/referral/chart`, {
      params: { ...data },
    });
  }

  //POST
  async postWithdrawReferral<T>(data: TReferralWithdrawRequest): Promise<T> {
    return this.send('post', `/transactions/referral/withdraw`, {
      ...data,
    });
  }
}

const baseUrl = CONFIG.REACT_APP_API_BASE_URL as string;
const referralModel = new RefrralModel(baseUrl);

export default referralModel;
