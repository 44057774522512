/* eslint-disable jsx-a11y/media-has-caption */
import { memo, FC, useEffect, useCallback, useMemo } from 'react';
import { PageTitle } from 'shared/components/pageTitle';
import { Section } from './components/Section/Section';
import { Title } from './components/Title/Title';
import { Text } from './components/Text/Text';
import styles from './index.module.scss';
import { ItemsList } from 'pages/cabinet/allTest/components/list';
import { StartScreenList } from 'pages/cabinet/allTest/components/startScreen/data';
import {
  CompareItems,
  CreateListSteps,
  QuoteItems,
  Tarifs,
  getDefaultPath,
} from './data';
import { CompareItem } from './components/CompareItem/CompareItem';
import { QuoteItem } from './components/QuoteItem/QuoteItem';
import { TarifItem } from './components/TarifItem/TarifItem';
import utils from 'shared/helpers/utils';
import { useLocation } from 'react-router';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { AuthBlock, authBlockActions } from 'entities/AuthBlock';
import cl from 'classnames';
import { burgerBlockActions } from 'shared/components/burgerBlock/model/slices/burgerBlockSlice';
import { VideoBlock } from './components/VideoBlock/VideoBlock';
import { fetchMainTariffs } from 'entities/tariffs/model/services/fetchMainTariffs';
import { getMainPrice } from 'entities/tariffs/model/selectors/getCost';
import { useSelector } from 'react-redux';

export const SectionsId = {
  How: 'how',
  Reviews: 'reviews',
  Tariffs: 'tariffs',
};

const IndexPage: FC = memo(() => {
  const dispatch = useAppDispatch();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      utils.scroolTo(hash);
    }
  }, [hash]);

  useEffect(() => {
    dispatch(fetchMainTariffs());
  }, [dispatch]);

  const mainPrice = useSelector(getMainPrice);

  const onCreate = useCallback(
    (btnId: string) => {
      dispatch(authBlockActions.setOpenAuthPopup());
      dispatch(authBlockActions.setFormType('Reg'));
      dispatch(burgerBlockActions.setOpenPopup(false));
      dispatch(authBlockActions.setPopupType('landing'));
      dispatch(authBlockActions.setCurrentBtnId(btnId));
    },
    [dispatch],
  );

  const onHowButton = useCallback(() => {
    utils.scroolTo(`#${SectionsId.How}`);
  }, []);

  type TBtns = { howBtn?: boolean; btnId: string };

  const Btns = ({ howBtn = false, btnId }: TBtns) => (
    <AuthBlock
      btnId={btnId}
      howBtn={howBtn}
      onCreate={onCreate}
      onHowButton={onHowButton}
      type="landing"
    />
  );

  return (
    <div className={styles.index}>
      <Section bordered={false} first>
        <PageTitle className={styles.pageTitle}>
          Как выяснить, что&nbsp;на&nbsp;самом деле думают люди?
        </PageTitle>
        <VideoBlock />
        <Text className={cl(styles.text, styles.index)}>
          Больше не нужно искать сторонних специалистов и тратить время на
          раскопки в сомнительных результатах. Запустите тест и система опросит
          реальных людей, а затем сформирует детальный отчет — вам останется
          только принять решение.
        </Text>
        <Btns howBtn btnId="index" />
      </Section>
      <Section>
        <Title className={styles.wrap} type="h2">
          Тесты&nbsp;для осознанного принятия решений
        </Title>
        <Text className={cl(styles.text, styles.testFirst)}>
          SplitTest — это система алгоритмов для проведения исследований
          (социологических тестов), цель которых беспристрастное выявление
          варианта с лучшим целевым показателем. Исходный набор вариантов может
          содержать до 8 изображений, различие которых от незначительного до
          радикального.
        </Text>
        <ItemsList
          data={StartScreenList}
          className={styles.testsList}
          classNameItem={styles.testItem}
          withButtons={false}
        />
        <Text className={cl(styles.text, styles.testSecond)}>
          Респондентами тестов являются реальные люди, прошедшие модерацию,
          анкетирование и регулярные проверки качества ответов. Работа
          респондентов оплачивается в зависимости от их грейда, поэтому качество
          тестов не вызывает сомнений.
        </Text>
        <Btns howBtn btnId="tests" />
      </Section>
      <Section>
        <Title className={styles.wrap} type="h2">
          Профессиональный инструмент&nbsp;оценки решений
        </Title>
        <Text className={cl(styles.text, styles.compare)}>
          SplitTest оценивает эффективность гипотез (конверсия, восприятиее,
          реакция и поведение пользователей) и выявляет наиболее перспективные
          варианты перед самым дорогостоящим этапом — внедрением решения в
          производство.
        </Text>
        <div className={styles.compareList}>
          {CompareItems.map(el => (
            <CompareItem {...el} key={el.title} />
          ))}
        </div>

        <Btns btnId="compare" />
      </Section>
      <Section id={SectionsId.How}>
        <Title type="h2" className={cl(styles.wrap, styles.how)}>
          Как это работает: исследование за&nbsp;6&nbsp;шагов
        </Title>
        <img
          className={styles.howImg}
          src={getDefaultPath('video-steps.jpg')}
          alt="Как это работает"
        />
        <ItemsList
          data={CreateListSteps}
          className={styles.stepsList}
          classNameTitle={styles.stepsTitle}
          withButtons={false}
          withStep
        />
        <Btns btnId={SectionsId.How} />
      </Section>
      <Section id={SectionsId.Reviews} className={styles.reviewsSection}>
        <Title className={styles.wrap} type="h2">
          Отзывы — о&nbsp;сервисе&nbsp;говорят реальные клиенты
        </Title>
        <div className={styles.quoteList}>
          {QuoteItems.map(el => (
            <QuoteItem {...el} key={el.author} />
          ))}
        </div>
      </Section>
      <Section id={SectionsId.Tariffs} className={styles.tariffSection} last>
        <Title
          type="h2"
          className={cl(styles.title, styles.tariffs, styles.wrap)}
        >
          Тарифы: оценка&nbsp;решений за&nbsp;разумные деньги
        </Title>
        <Text className={cl(styles.text, styles.tariffs)}>
          Быстро запилить небольшой тест по цене бургера, провести исследование
          с настройкой под себя, использовать на всю катушку и не разориться? —
          Легко!
        </Text>
        <div className={styles.tarifList}>
          {Tarifs.map(el => (
            <TarifItem {...el} key={el.title} price={mainPrice} />
          ))}
        </div>
        <Btns btnId={SectionsId.Tariffs} />
      </Section>
    </div>
  );
});

export default IndexPage;
