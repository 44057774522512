import { createAsyncThunk } from '@reduxjs/toolkit';
import balanceModel from 'store/models/balance';
import fileDownload from 'js-file-download';
import { balanceActions } from '../slices/balanceSlice';

export const fetchLoadDocument = createAsyncThunk(
  'balance/fetchLoadDocument',
  async (data: { id: number }, thunkApi) => {
    try {
      const response = await balanceModel.getDocument(data);
      if (response.status !== 200 && !response.data) {
        throw new Error();
      }
      fileDownload(
        response.data,
        `document_${new Date().toJSON().slice(0, 10)}.pdf`,
      );
      thunkApi.dispatch(balanceActions.setCloseBalanceUpPopup());
      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
