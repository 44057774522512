import React, { FC } from 'react';
import './index.scss';

type TProps = {
  className?: string;
};

const SpinerMin: FC<TProps> = props => {
  const { className = '' } = props;
  return (
    <div className={`spinner-min ${className}`}>
      <i className="spinner-min__loader-icon" />
    </div>
  );
};

export default SpinerMin;
