import React, { FC, useEffect, useState } from 'react';
import styles from '../form/form.module.scss';

type TTimerProps = {
  time: number;
  stopTimer: () => void;
};

const TimerComponent: FC<TTimerProps> = props => {
  const { time, stopTimer } = props;

  const [timeState, setTimeState] = useState(time);

  const startTime = React.useCallback(() => {
    let currentTime = timeState;
    const intervalID = setInterval(() => {
      currentTime -= 1;
      setTimeState(currentTime);
      if (currentTime <= 0) {
        clearInterval(intervalID);
        setTimeState(0);
        stopTimer();
      }
    }, 1000);
  }, [stopTimer, timeState]);

  const declation = (num: number) => {
    const n = num % 10;
    if (n >= 2 && n <= 4 && (num > 15 || num <= 4)) {
      return 'ы';
    }
    if (n === 1 && num !== 11) {
      return 'у';
    }

    return '';
  };

  useEffect(() => {
    if (timeState > 0) {
      startTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <span className={styles.time}>
      через <br />
      {timeState} секунд{declation(timeState)}
    </span>
  );
};

export default React.memo(TimerComponent);
