import { Client } from '../../types/adminSupportSchema';

const getPollAddition: (arg: number) => string = num => {
  if (num > 10 && num < 21) {
    return 'тестов';
  }
  switch (num % 10) {
    case 1:
      return 'тест';
    case 2:
    case 3:
    case 4:
      return 'теста';
    default:
      return 'тестов';
  }
};

export const mapDataClientsList = (data: Client[]) => {
  return data.map(client => ({
    value: client.id,
    label: `${client.phone} (${client.name}${
      client.surname ? ` ${client.surname}` : ''
    })${
      client.polls ? ` — ${client.polls} ${getPollAddition(client.polls)}` : ''
    }`,
  }));
};
