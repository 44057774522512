import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';
import { fetchCompliants } from '../services/postCompliant';

import { CompliantSchema, Reason } from '../types/compliantSchema';

const initialState: CompliantSchema = {
  resultId: undefined,
  imageId: undefined,
  reason: null,
  comment: '',
  showCompliantModal: false,
  compliant: {
    response: undefined,
    isLoading: false,
    error: null,
  },
};

export const CompliantSlice = createSlice({
  name: 'compliant',
  initialState,
  reducers: {
    setResultId(state, action: PayloadAction<number>) {
      state.resultId = action.payload;
    },

    setImageId(state, action: PayloadAction<number>) {
      state.imageId = action.payload;
    },
    setReason(state, action: PayloadAction<Reason | null>) {
      state.reason = action.payload;
    },
    setComment(state, action: PayloadAction<string>) {
      state.comment = action.payload;
    },
    openCompliantModal(state) {
      state.showCompliantModal = true;
    },
    closeCompliantModal(state) {
      state.showCompliantModal = false;
      state.comment = '';
      state.reason = null;
      state.resultId = undefined;
      state.imageId = undefined;
      state.compliant = {
        response: undefined,
        isLoading: false,
        error: null,
      };
    },
  },
  extraReducers: builder => {
    //fetchCompliants
    builder
      .addCase(fetchCompliants.pending, state => {
        state.compliant.isLoading = true;
        state.compliant.error = null;
        state.compliant.response = undefined;
      })
      .addCase(fetchCompliants.fulfilled, (state, action) => {
        state.compliant.isLoading = false;
        state.compliant.error = null;
        state.compliant.response = action.payload;
      })
      .addCase(fetchCompliants.rejected, (state, action) => {
        state.compliant.isLoading = false;
        state.compliant.response = undefined;
        const message = (action.payload as NetworkError).message;
        state.compliant.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });
  },
});

export const { actions: CompliantActions } = CompliantSlice;
export const { reducer: CompliantReducer } = CompliantSlice;
