const getFineNumber = (
  number: number | string,
  fixNumberSigns: number,
): string => {
  const value = Number(number);
  const isInteger = Number.isInteger(value);

  if (isInteger) {
    return value.toString();
  }

  return value.toFixed(fixNumberSigns).replaceAll('.', ',');
};

export default getFineNumber;
