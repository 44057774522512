import { createAsyncThunk } from '@reduxjs/toolkit';
import supportModel from 'store/models/support';
import { mapDataClientsList } from '../../helpers/mapDataClientsList/mapDataClientsList';
import { Client } from '../../types/adminSupportSchema';

export const clientsList = createAsyncThunk(
  'support/clientsList',
  async (data, thunkApi) => {
    try {
      const response = (await supportModel.getClinetsList()) as unknown as {
        data: Client[];
      };
      if (!response.data) {
        throw new Error();
      }
      return mapDataClientsList(response.data);
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
