import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Button from 'shared/components/button';
import { Icon } from 'shared/components/icon';
import Popup from 'shared/components/popup';
import styles from './index.module.scss';
import { RadioInput } from 'shared/components/radioInput';
import { FieldList, FieldRow, FieldSet } from 'shared/components/grid/field';
import {
  radioBalanceData,
  radioPersonData,
} from '../../../../../pages/cabinet/balance/data';
import Input from 'shared/components/input';
import AsyncSelect from 'react-select/async';
import {
  OptionDaDataBalance,
  singleSelectWithoutCheck,
  LoaderBlock,
} from 'app/styles/select';
import { TPayer, TTransactionType } from '../../../model/types/balanceSchema';
import { useSelector } from 'react-redux';
import {
  getBalancePopupError,
  getBalancePopupIsLoading,
  getBalancePopupIsOpen,
  getBalanceRedirectLink,
  getCanCreatePaymentInvoice,
  getInvoiceLoading,
  getPayerCompanyAddress,
  getPayerCompanyLabel,
  getPayerCompanyname,
  getPayerEmail,
  getPayerInn,
  getPayerPayerType,
  getPayerSumme,
  getPayerUsername,
  getTransactionType,
} from '../../../model/selectors/getBalance';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { balanceActions } from '../../../model/slices/balanceSlice';
import { VALID } from 'shared/constants/valid';
import { fetchCreatePaymentInvoice } from 'entities/Balance/model/services/fetchCreatePaymentInvoice';
import {
  getUserlastname,
  getUserPartonomic,
  getUsername,
  getUserMail,
} from 'store/selectors/user';
import balanceModel from 'store/models/balance';
import { REDIRECT_INFO } from 'shared/constants/commmon';
import { useParams } from 'react-router';
import ROUTES from 'shared/constants/routes';
import { WindowWidth } from 'shared/constants/windowWidth';
import cl from 'classnames';

type TBalanceFormProps = {
  withIndent?: boolean;
  saveDraft?: () => void;
  title: string;
  classNameBtn?: string;
  cost?: number;
  position?: string;
};

export const BalanceForm: FC<TBalanceFormProps> = memo(
  ({ withIndent = false, saveDraft, title, classNameBtn, cost, position }) => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const firstname = useSelector(getUsername);
    const lastname = useSelector(getUserlastname);
    const middlename = useSelector(getUserPartonomic);
    const showPopup = useSelector(getBalancePopupIsOpen);
    const userEmail = useSelector(getUserMail);

    const onClosePopup = useCallback(() => {
      dispatch(balanceActions.setCloseBalanceUpPopup());
    }, [dispatch]);

    const onOpenPopup = useCallback(() => {
      const initialUsername =
        (lastname || '') + ' ' + (firstname || '') + ' ' + (middlename || '');
      dispatch(balanceActions.setUsername(initialUsername.trim()));
      dispatch(balanceActions.setOpenBalanceUpPopup());
      if (cost) {
        dispatch(balanceActions.setSumm(cost.toString()));
      }
      if (userEmail) {
        dispatch(balanceActions.setEmail(userEmail));
      }
    }, [lastname, firstname, middlename, dispatch, cost, userEmail]);

    const payer = useSelector(getPayerPayerType);
    const transactionType = useSelector(getTransactionType);
    const inn = useSelector(getPayerInn);
    const username = useSelector(getPayerUsername);
    const email = useSelector(getPayerEmail);
    const companyname = useSelector(getPayerCompanyname);
    const companyLabel = useSelector(getPayerCompanyLabel);
    const companyAddress = useSelector(getPayerCompanyAddress);
    const summ = useSelector(getPayerSumme);
    const canEditCreatePaymentInvoice = useSelector(getCanCreatePaymentInvoice);
    const createInvoiceIsLoading = useSelector(getBalancePopupIsLoading);
    const invoiceLoading = useSelector(getInvoiceLoading);
    const createInvoiceError = useSelector(getBalancePopupError);
    const redirectLink = useSelector(getBalanceRedirectLink);

    const onChangePayer = useCallback(
      (payerType: TPayer) => () => {
        dispatch(balanceActions.setPayer(payerType));
      },
      [dispatch],
    );

    const onChangeTransactionType = useCallback(
      (actionType: TTransactionType) => () => {
        dispatch(balanceActions.setTransactionType(actionType));
        dispatch(balanceActions.setPayer('user'));
      },
      [dispatch],
    );

    const onChangeUsername = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(balanceActions.setUsername(event.target.value));
      },
      [dispatch],
    );

    const onChangeEmail = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(balanceActions.setEmail(event.target.value));
      },
      [dispatch],
    );

    const timeOut = useRef<NodeJS.Timeout | null>(null);
    // const [inputValueSelect, setInputValueSelect] = useState('');

    const onChangeCompany = useCallback(
      (event: {
        value: string;
        label: string;
        name: string;
        address: string;
      }) => {
        dispatch(balanceActions.setCompanyname(event.name));
        dispatch(balanceActions.setCompanyLabel(event.label));
        dispatch(balanceActions.setInn(event.value));
        dispatch(balanceActions.setCompanyAddress(event.address));
        // setInputValueSelect('');
      },
      [dispatch],
    );

    const onChangeSumm = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (VALID.INTEGER.test(event.target.value) || !event.target.value) {
          dispatch(balanceActions.setSumm(event.target.value));
        }
      },
      [dispatch],
    );

    const getPage = useCallback((path: string) => {
      if (path.includes('balance')) {
        return 'balance';
      }
      if (path.includes('new')) {
        return 'edit';
      }
      if (path.includes('edit')) {
        return 'edit';
      }
      return 'balance';
    }, []);

    const onCreateInvoice = useCallback(() => {
      dispatch(
        fetchCreatePaymentInvoice(
          getPage(window.location.pathname) !== 'balance',
        ),
      );
      if (
        getPage(window.location.pathname) !== 'balance' &&
        saveDraft &&
        transactionType === 'bill'
      ) {
        saveDraft();
      }
    }, [dispatch, getPage, saveDraft, transactionType]);

    useEffect(() => {
      if (redirectLink) {
        const redirectInfo = {
          page: getPage(window.location.pathname),
          draft: id,
        };
        localStorage.setItem(REDIRECT_INFO, JSON.stringify(redirectInfo));
        if (getPage(window.location.pathname) === 'balance') {
          localStorage.setItem(REDIRECT_INFO, JSON.stringify(redirectInfo));
          window.location.href = redirectLink;
        }
        if (getPage(window.location.pathname) !== 'balance' && saveDraft) {
          saveDraft();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectLink]);

    const fetchSearchOptions = async (inputValue: string) => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
      const debounceSearch = async () => {
        try {
          await new Promise(resolve => {
            timeOut.current = setTimeout(resolve, 500);
            // return timeOut.current;
          });
          const fetchOptionsData = (await balanceModel.getSuggestDaData({
            query: inputValue,
            type: 'party',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          })) as any;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const data = (fetchOptionsData.data as any[]).map(item => ({
            value: item?.data?.inn,
            label:
              `${item?.value}$` +
              `${item?.data?.inn}$` +
              `${[
                item?.data?.address?.data?.city ||
                  item?.data?.address?.data?.settlement_with_type,
                item?.data?.address?.data?.street_with_type,
                item?.data?.address?.data?.house,
              ]
                .filter(Boolean)
                .join(', ')}`,
            name: item?.value,
            address: item?.data?.address?.unrestricted_value,
          }));
          return data as unknown as {
            value: string;
            label: string;
            name: string;
            address: string;
          }[];
        } catch (e) {
          return [] as { value: string; label: string }[];
        }
      };

      if (inputValue.length > 0) {
        return debounceSearch();
      }

      return [] as { value: string; label: string }[];
    };
    const menuPlacement =
      window.innerWidth >= WindowWidth.MOBILE ? 'bottom' : 'top';

    const ref = useRef<HTMLDivElement>(null);
    const [popupPosition, setPopupPosition] = useState<'popup' | 'bottom'>(
      'popup',
    );
    const changePopupPosition = () => {
      if (ref.current) {
        const popupHeight = 410;
        const { top, height } = ref.current.getBoundingClientRect();
        const bodyHeight = document.body.scrollHeight;

        if (top + height + window.scrollY + popupHeight > bodyHeight) {
          setPopupPosition('bottom');
        } else {
          setPopupPosition('popup');
        }
      }
    };

    useLayoutEffect(() => {
      changePopupPosition();
    });

    // Для дебага
    // value={{
    //   address:
    //     '620026, Свердловская обл, г Екатеринбург, Октябрьский р-н, ул Розы Люксембург, стр 51, помещ 634',
    //   label:
    //     'АО "СТМ" 6672241304 Екатеринбург, ул Розы Люксембург, 51',
    //   name: 'АО "СТМ"',
    //   value: '6672241304',
    // }}
    // dispatch(fetchLoadDocument({ id: idDoc }));

    return (
      <div className={withIndent ? styles.wrapIndent : styles.wrap} ref={ref}>
        <Button
          id="balance-btn"
          as="button"
          onClick={onOpenPopup}
          withIcon
          outline={showPopup}
          className={classNameBtn}
        >
          <Icon name="icon-card" />
          {title}
        </Button>
        <Popup
          closeIds={['balance-btn', 'select-inn']}
          className={cl(styles.popup, {
            [styles.right]: position,
            [styles.bottom]: popupPosition === 'bottom',
          })}
          isShow={showPopup}
          closeHandler={onClosePopup}
        >
          <div className={styles.form}>
            <FieldSet labelName="Пополнение баланса">
              <FieldList className={styles.list}>
                {radioBalanceData.map(({ name, idx, disabled }) => (
                  <FieldRow key={name}>
                    <RadioInput
                      value={name}
                      title={name}
                      id={idx}
                      disabled={disabled}
                      checked={transactionType === idx}
                      onChange={onChangeTransactionType(idx)}
                    />
                  </FieldRow>
                ))}
              </FieldList>

              <Input
                className={styles.input}
                placeholder="Сумма"
                value={summ || ''}
                onChange={onChangeSumm}
                inputMode="decimal"
              />

              <FieldList className={styles.list}>
                {radioPersonData.map(({ name, idx }) => (
                  <FieldRow key={name}>
                    <RadioInput
                      id={idx}
                      checked={payer === idx}
                      value={name}
                      title={name}
                      name={idx}
                      onChange={onChangePayer(idx)}
                      disabled={idx === 'company' && transactionType === 'card'}
                    />
                  </FieldRow>
                ))}
              </FieldList>

              {payer === 'user' ? ( //TODO временно для верстки
                <>
                  <Input
                    className={styles.input}
                    placeholder="ФИО"
                    value={username || ''}
                    onChange={onChangeUsername}
                  />
                  {transactionType === 'card' && (
                    <Input
                      className={styles.input}
                      placeholder="Эл. почта для чека"
                      value={email || ''}
                      onChange={onChangeEmail}
                    />
                  )}
                </>
              ) : (
                <>
                  <AsyncSelect
                    menuPlacement={menuPlacement}
                    styles={singleSelectWithoutCheck()}
                    // defaultOptions={selectOptions}
                    placeholder="Наименование или ИНН"
                    inputId="inn"
                    value={
                      inn
                        ? {
                            value: inn || null,
                            label: companyLabel?.replaceAll('$', ' ') || null,
                            name: companyname || null,
                            address: companyAddress || null,
                          }
                        : null
                    }
                    onChange={onChangeCompany}
                    loadOptions={fetchSearchOptions as any}
                    components={{
                      Option: OptionDaDataBalance,
                      LoadingMessage: LoaderBlock,
                    }}
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue ? null : 'Значение не найдено'
                    }
                    // menuIsOpen
                    id="select-inn"
                    className={styles.select_inn}
                    // inputValue={inputValueSelect}
                    // onMenuClose={() => setInputValueSelect(inputValueSelect)}
                    // onInputChange={event => setInputValueSelect(event)}
                  />
                </>
              )}
            </FieldSet>
            <div className={styles.footerBtn}>
              <Button
                type="button"
                as="button"
                disabled={
                  !canEditCreatePaymentInvoice ||
                  createInvoiceIsLoading ||
                  invoiceLoading
                }
                onClick={onCreateInvoice}
              >
                <Icon name="icon-arrow-right" />
                Продолжить
              </Button>

              {createInvoiceError ? (
                <span className={styles.error}>
                  Произошла ошибка
                  <br />
                  {createInvoiceError.status + ' ' + createInvoiceError.message}
                </span>
              ) : (
                <span className={styles.time}>
                  Нажимая, принимаю
                  <br />
                  <a
                    href={ROUTES.OFFER_DOCX.ROUTE}
                    target="_blank"
                    className={styles.offer}
                  >
                    договор-оферту
                  </a>
                </span>
              )}
            </div>
          </div>
        </Popup>
      </div>
    );
  },
);
