import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import authModel from 'store/models/auth';
import { shallowEqual, useSelector } from 'react-redux';
import {
  fetchAuth,
  fetchBitrix24Login,
  setOzonError,
} from 'pages/user/store/store';
import { getAuthResponse } from 'store/selectors/user';
import NetworkError from 'shared/exceptions/network';
import { SmsForm } from './smsForm';
import { PhoneForm } from './phoneForm';
import { NameForm } from './nameForm';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { PromocodeForm } from './promocodeForm';
import { getCookie } from 'pages/promocode';
import CONFIG from 'shared/constants/config';

export const FormType = {
  Auth: 'Auth',
  Reg: 'Reg',
  Profile: 'Profile',
} as const;

export type TFormTypeValues = typeof FormType[keyof typeof FormType];

export const FormStep = {
  Phone: 'Phone',
  Sms: 'Sms',
  Name: 'Name',
  Promocode: 'Promocode',
} as const;

export type TStepsValues = typeof FormStep[keyof typeof FormStep];

export type TFormTypeProps = TFormTypeValues | null;

type TForm = {
  formType?: TFormTypeProps;
  isShow: boolean;
  formPhone?: string;
  isBitrix?: boolean;
  isOzon?: boolean;
  data_token?: string;
};

const Form: FC<TForm> = React.memo(props => {
  const {
    formType,
    isShow,
    formPhone = '',
    isBitrix = false,
    isOzon = false,
    data_token,
  } = props;
  const dispatch = useAppDispatch();
  const [formStep, setFormStep] = useState<TStepsValues>();
  const [isSmsSend, setIsSmsSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [smsError, setSmsError] = useState<string | null>(null);
  const [smsTryError, setSmsTryError] = useState<string | null>(null);
  const [smsTime, setSmsTime] = useState<number | null>(null);
  const [authOption, setAuthOption] = useState<
    'call' | 'sms' | 'initialCall' | 'initialSms'
  >('initialSms');

  const changeAuthOption = useCallback((option: 'call' | undefined) => {
    if (option) {
      return setAuthOption(option);
    } else {
      setAuthOption(prev => {
        if (prev === 'call') {
          return 'sms';
        } else if (prev === 'initialCall') {
          return 'initialSms';
        } else if (prev === 'sms') {
          return 'call';
        } else return 'initialCall';
      });
    }
  }, []);

  const promocodeCookie = getCookie(CONFIG.PROMO_COOKIE_NAME)?.trim() || '';
  const INITIAL_STATE = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      phone: formPhone,
      sms: '',
      formType: '',
      promocode: promocodeCookie,
    }),
    [formPhone, promocodeCookie],
  );

  const [formState, setFormState] = useState(INITIAL_STATE);
  useEffect(() => {
    setFormState(INITIAL_STATE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promocodeCookie]);

  const authResponse = useSelector(getAuthResponse, shallowEqual);

  //Фокус на 1-м инпуте ввода кода при ошибочном вводе кода
  useEffect(() => {
    if (authResponse.error) {
      setSmsError((authResponse.error?.errors?.code[0] as string) || 'Ошибка');
      setFormState(prev => ({ ...prev, sms: '' }));
      const nextSibling = document.getElementById(`0-sms`) as HTMLInputElement;
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  }, [authResponse.error]);

  //Очистка стейтов при авторизации
  useEffect(() => {
    if (authResponse.response) {
      setFormState(INITIAL_STATE);
      setIsSmsSend(false);
      setSmsError(null);
      const currentType =
        formType === FormType.Reg
          ? FormStep.Name
          : formType === FormType.Auth
          ? FormStep.Phone
          : FormStep.Sms;
      setFormStep(currentType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authResponse.response]);

  //фокус на вводе телефона или первом инпуте формы ввода кода
  useEffect(() => {
    let nextSibling: HTMLInputElement | null = null;
    let nextSiblingArray: NodeListOf<Element> | null = null;
    if (
      !document.activeElement?.id.includes(`sms`) &&
      formStep === FormStep.Sms
    ) {
      nextSibling = document.getElementById(`0-sms`) as HTMLInputElement;
    }
    if (
      !document.activeElement?.id.includes(`phoneInput`) &&
      formStep === FormStep.Phone
    ) {
      nextSiblingArray = document.querySelectorAll(`#phoneInput`);
      nextSiblingArray.forEach(item => {
        item.parentElement?.parentElement?.classList.forEach(cls => {
          if (cls.includes('show')) {
            return (nextSibling = item as HTMLInputElement);
          }
        });
      });
    }
    if (
      !document.activeElement?.id.includes(`firstNameInput`) &&
      formStep === FormStep.Name
    ) {
      nextSiblingArray = document.querySelectorAll(`#firstNameInput`);
      nextSiblingArray.forEach(item => {
        item.parentElement?.parentElement?.classList.forEach(cls => {
          if (cls.includes('show')) {
            return (nextSibling = item as HTMLInputElement);
          }
        });
      });
    }
    if (
      !document.activeElement?.id.includes(`promocodeInput`) &&
      formStep === FormStep.Promocode
    ) {
      nextSiblingArray = document.querySelectorAll(`#promocodeInput`);
      nextSiblingArray.forEach(item => {
        item.parentElement?.parentElement?.classList.forEach(cls => {
          if (cls.includes('show')) {
            return (nextSibling = item as HTMLInputElement);
          }
        });
      });
    }
    setTimeout(() => {
      if (nextSibling !== null && isShow) {
        nextSibling.focus();
      }
    }, 50);
  }, [formStep, isShow]);

  useEffect(() => {
    if (formType === null) {
      setFormState(INITIAL_STATE);
      return;
    }
    const currentType =
      formType === FormType.Reg ? FormStep.Name : FormStep.Phone;

    setFormStep(currentType);
  }, [formType, INITIAL_STATE]);

  //Отправка смс
  const smsSend = async () => {
    setLoading(true);
    try {
      const response = (await authModel.postAuth({
        endpoint: 'sms',
        phone: formState.phone,
        type:
          authOption === 'sms' || authOption === 'initialSms' ? 'sms' : 'call',
      })) as unknown as {
        data: { message: string; registered: boolean };
      };
      if (response.data.message && !!(response.data.registered || isBitrix)) {
        setFormStep(FormStep.Sms);
        setSmsTime(90);
        setSmsTryError(null);
        setSmsError(null);
        setFormState(prev => ({ ...prev, formType: FormType.Auth }));
      }
      if (
        response.data.message &&
        !response.data.registered &&
        !isBitrix &&
        !isOzon
      ) {
        if (formType === FormType.Auth) {
          setFormStep(FormStep.Name);
        }
        if (formType === FormType.Reg) {
          setFormStep(FormStep.Sms);
        }
        setSmsTime(90);
        setIsSmsSend(true);
        setSmsTryError(null);
        setSmsError(null);
        setFormState(prev => ({ ...prev, formType: FormType.Reg }));
      }

      if (response.data.message && !response.data.registered && isOzon) {
        // if (formType === FormType.Auth) {
        //   setFormStep(FormStep.Name);
        // }
        // if (formType === FormType.Reg) {
        //   setFormStep(FormStep.Sms);
        // }
        // setSmsTime(90);
        // setIsSmsSend(true);
        // setSmsTryError(null);
        // setSmsError(null);
        // setFormState(prev => ({ ...prev, formType: FormType.Reg }));
        dispatch(setOzonError());
      }

      setLoading(false);
      return response;
    } catch (error) {
      const errors = error as NetworkError;
      setLoading(false);
      const errorTimeMessage = errors.errors?.time
        ? errors.errors?.time[0]
        : null;
      const errorTryMessage = errors.errors?.try ? errors.errors?.try[0] : null;
      setSmsTryError(null);
      setSmsTime(null);
      setSmsError(null);
      if (errorTimeMessage) {
        setSmsTime(Number(errorTimeMessage) || null);
        if (formType === FormType.Reg) {
          setFormStep(FormStep.Sms);
        } else {
          setFormStep(FormStep.Sms);
        }
      }
      if (errorTryMessage) {
        setSmsTryError((errorTryMessage as string) || null);
      }
      return error;
    }
  };

  //Отправка запроса авторизации
  const fetchLogin = useCallback(() => {
    dispatch(
      fetchAuth({
        endpoint: 'login',
        phone: formState.phone,
        code: formState.sms,
      }),
    );
  }, [dispatch, formState.phone, formState.sms]);

  const fetchLoginBitrix = useCallback(() => {
    if (data_token) {
      dispatch(
        fetchBitrix24Login({
          data_token,
          code: formState.sms,
          phone: formState.phone,
        }),
      );
    }
  }, [data_token, dispatch, formState]);

  //Отправка запроса регистрации
  const fetchReg = useCallback(() => {
    dispatch(
      fetchAuth({
        endpoint: 'register',
        phone: formState.phone,
        code: formState.sms,
        name: formState.firstName || undefined,
        surname: formState.lastName || undefined,
        promocode: formState.promocode || undefined,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formState.sms]);

  //Автоматическая отправка кода из смс
  useEffect(() => {
    if (formState.sms.length === 4) {
      if (formState.formType === FormType.Auth && !isBitrix) {
        fetchLogin();
      }
      if (formState.formType === FormType.Reg && !isBitrix) {
        fetchReg();
      }
      if (isBitrix) {
        fetchLoginBitrix();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.sms]);

  //Функция обработки значения инпутов для кода смс
  const handleSmsInputChange = (
    target: EventTarget & HTMLInputElement,
    idx: number,
  ) => {
    const { value, id } = target;
    if (!isNaN(Number(value))) {
      if (value.length === 4) {
        setFormState(prev => {
          return {
            ...prev,
            sms: value,
          };
        });
      } else {
        setFormState(prev => {
          return {
            ...prev,
            sms:
              prev.sms.length <= idx
                ? prev.sms + value
                : prev.sms
                    .split('')
                    .map((e, i) => (i === idx ? value || '_' : e))
                    .join(''),
          };
        });
      }
    }
    const [fieldIndex] = id.split('-');
    if (value.length === 4) {
      const nextSibling = document.getElementById(`3-sms`);
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    } else if (value.length >= 1) {
      if (parseInt(fieldIndex, 10) < 4) {
        const nextSibling = document.getElementById(
          `${parseInt(fieldIndex, 10) + 1}-sms`,
        ) as HTMLInputElement;
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  let result = <></>;

  switch (formStep) {
    case FormStep.Phone:
      result = (
        <PhoneForm
          phone={formState.phone}
          smsTryError={smsTryError}
          loading={loading}
          smsSend={smsSend}
          setFormState={setFormState}
          isBitrix={isBitrix}
          isOzon={isOzon}
        />
      );
      break;
    case FormStep.Sms:
      result = (
        <SmsForm
          phone={formState.phone}
          sms={formState.sms}
          smsError={smsError}
          smsTime={smsTime}
          authOption={authOption}
          setSmsError={setSmsError}
          setFormStep={setFormStep}
          smsSend={smsSend}
          setSmsTime={setSmsTime}
          inputHandler={handleSmsInputChange}
          changeAuthOption={changeAuthOption}
        />
      );
      break;
    case FormStep.Name:
      result = (
        <NameForm
          isSmsSend={isSmsSend}
          firstName={formState.firstName}
          lastName={formState.lastName}
          setFormStep={setFormStep}
          setFormState={setFormState}
        />
      );
      break;
    case FormStep.Promocode:
      result = (
        <PromocodeForm
          isSmsSend={isSmsSend}
          promocode={formState.promocode}
          setFormStep={setFormStep}
          setFormState={setFormState}
        />
      );
      break;
  }
  return <>{result}</>;
});

export { Form };
