import { createAsyncThunk } from '@reduxjs/toolkit';
import supportModel from 'store/models/support';
// import { TSupportMessageData } from '../types/userSupportSchema';

import { TState } from 'store/types';
import { getUserSupportData } from '../selectors/getUserSupportData';

export const fetchUserSupport = createAsyncThunk(
  'support/fetchUserSupport',
  async (_, thunkApi) => {
    const messageData = getUserSupportData(thunkApi.getState() as TState);
    try {
      const response = (await supportModel.postUserSupport(
        messageData,
      )) as unknown as {
        data: string;
      };
      if (!response.data) {
        throw new Error();
      }
      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
