import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthBlockSchema, TAuthBlockType } from '../types/authBlockSchema';
import { TFormTypeValues } from 'entities/AuthBlock/ui/authBlock/components/form';
import { fetchSmsRequest } from '../services/fetchSmsRequest';
import NetworkError from 'shared/exceptions/network';
import { checkPromocode } from '../services/checkPromocode';

const initialState: AuthBlockSchema = {
  currentBtnId: '',
  currentPopupType: 'header',
  popupIsOpen: false,
  formType: null,
  sms: {
    loading: false,
    error: null,
    response: {
      message: undefined,
    },
    time: 60,
    otp: '',
  },
  promocode: {
    loading: false,
    error: null,
    send: false,
    response: {
      check: false,
    },
  },
};

export const authBlockSlice = createSlice({
  name: 'authBlock',
  initialState,
  reducers: {
    setCurrentBtnId(state, action: PayloadAction<string>) {
      state.currentBtnId = action.payload;
    },
    setPopupType(state, action: PayloadAction<TAuthBlockType>) {
      state.currentPopupType = action.payload;
    },
    setFormType(state, action: PayloadAction<TFormTypeValues | null>) {
      state.formType = action.payload;
    },
    setOpenAuthPopup(state) {
      state.popupIsOpen = true;
    },
    setCloseAuthPopup(state) {
      state.popupIsOpen = false;
      state.sms = {
        loading: false,
        error: null,
        response: {
          message: undefined,
        },
        time: 60,
        otp: '',
      };
      state.promocode = {
        loading: false,
        error: null,
        send: false,
        response: {
          check: false,
        },
      };
    },
    setSmsTime(state, action: PayloadAction<number>) {
      state.sms.time = action.payload;
    },
    setSmsOtp(state, action: PayloadAction<string>) {
      state.sms.otp = action.payload;
    },
    setSmsError(state, action: PayloadAction<NetworkError | null>) {
      state.sms.error = action.payload;
    },
    clearSms(state) {
      state.sms = {
        loading: false,
        error: null,
        response: {
          message: undefined,
        },
        time: 60,
        otp: '',
      };
    },
    clearPromocode(state) {
      state.promocode = {
        loading: false,
        error: null,
        send: false,
        response: {
          check: false,
        },
      };
    },
  },
  extraReducers: builder => {
    //fetchSmsRequest
    builder
      .addCase(fetchSmsRequest.pending, state => {
        state.sms.loading = true;
        state.sms.error = null;
      })
      .addCase(fetchSmsRequest.fulfilled, (state, action) => {
        state.sms.loading = false;
        state.sms.error = null;
        state.sms.response = action.payload;
        state.sms.time = 60;
      })
      .addCase(fetchSmsRequest.rejected, (state, action) => {
        state.sms.loading = false;
        const errors = (action.payload as NetworkError).errors;
        const message = (action.payload as NetworkError).message;
        if (errors && errors.time) {
          state.sms.time = Number(errors.time[0]);
        }
        state.sms.error = { ...(action.payload as NetworkError), message };
      });

    //checkPromocode
    builder
      .addCase(checkPromocode.pending, state => {
        state.promocode.loading = true;
        state.promocode.error = null;
      })
      .addCase(checkPromocode.fulfilled, (state, action) => {
        state.promocode.loading = false;
        state.promocode.error = null;
        state.promocode.response = action.payload;
        state.promocode.send = true;
      })
      .addCase(checkPromocode.rejected, (state, action) => {
        state.sms.loading = false;
        const message = (action.payload as NetworkError).message;
        state.promocode.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });
  },
});

export const { actions: authBlockActions } = authBlockSlice;
export const { reducer: authBlockReducer } = authBlockSlice;
