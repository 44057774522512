import { createAsyncThunk } from '@reduxjs/toolkit';
import supportModel from 'store/models/support';

export const fetchPromocodes = createAsyncThunk(
  'sendMessageToRespondents/fetchPromocodes',
  async (data: { query?: string }, thunkApi) => {
    try {
      const response = await supportModel.getPromocodes(data);
      if (!response.data) {
        throw new Error();
      }
      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
