import cl from 'classnames';
import stylesInput from 'shared/components/input/index.module.scss';

type Config = {
  className?: string;
  error?: boolean;
  required?: boolean;
  placeholder?: string;
  mask?: string;
};

export const getImaskConfig = ({
  className,
  error,
  required = true,
}: Config = {}) => ({
  placeholder: '+7 999 999-99-99',
  type: 'tel',
  mask: '+{7} {#00} 000-00-00',
  definitions: { '#': /[01234569]/ },
  className: cl(stylesInput.input, className, {
    [stylesInput['input--error']]: error,
  }),
  required,
});

export const getImaskConfigValidation = ({
  className,
  error,
  required = true,
  placeholder = '00 00 000000',
  mask = '00 00 000000',
}: Config = {}) => ({
  placeholder,
  type: 'tel',
  mask,
  className: cl(stylesInput.input, className, {
    [stylesInput['input--error']]: error,
  }),
  required,
});
