import { Icon } from '../icon';
import styles from './index.module.scss';

export const SocialBlock = () => {
  return (
    <div className={styles.block}>
      Мы в соцсетях:
      <ul className={styles.list}>
        <li className={styles.item}>
          <a
            className={styles.link}
            href="https://t.me/splittestru"
            target="_blank"
          >
            <Icon name="icon-telegram" />
          </a>
        </li>
        <li className={styles.item}>
          <a
            className={styles.link}
            href="https://vk.com/splittestru"
            target="_blank"
          >
            <Icon name="icon-vk" />
          </a>
        </li>
        <li className={styles.item}>
          <a
            className={styles.link}
            href="https://youtube.com/@SplitTestRu?feature=shareb"
            target="_blank"
          >
            <Icon name="icon-youtube" />
          </a>
        </li>
      </ul>
    </div>
  );
};
