import { memo, useCallback, useEffect, useState } from 'react';
import { CompareItems } from '../../data';
import { ProgressImage } from 'shared/components/progressImage';
import styles from './VideoBlock.module.scss';
import './VideoBlock.scss';
import cl from 'classnames';
import { Random } from 'shared/helpers/utils/random';
import { ContentType } from 'pages/cabinet/store/types';

const IMAGE_CHANGE_TIME = 20000;
const images = CompareItems.map(el => el.data).flat();
const getRandomImagesCount = () => Random.int(0, images.length - 1);
const videoLinksId = ['204259273', '204258784', '204259387'];
const ImgsProgress = memo(
  ({
    imgs,
    className = '',
  }: {
    className: string;
    imgs: { link: string; votes: number }[];
  }) => {
    return (
      <div className={className}>
        {imgs.map((img, idx) => {
          return (
            <div
              key={img.link}
              className={cl(styles.videoImgWrap, {
                [styles.videoImgFirst]: idx === 0,
                [styles.videoImgSecond]: idx === 1,
              })}
            >
              <ProgressImage
                classNames={{ progress: styles.progress }}
                data={img}
                type="video"
                testType={ContentType.Couple}
                isIndex
              />
            </div>
          );
        })}
      </div>
    );
  },
);

const MemoPlayer = memo(
  ({ setLoadHandler }: { setLoadHandler: () => void }) => {
    return (
      <>
        <div className="video">
          <iframe
            title="Video"
            onLoad={setLoadHandler}
            src={`//kinescope.io/embed/${Random.itemFromArray(
              videoLinksId,
            )}?background=1`}
            allow="autoplay; picture-in-picture; encrypted-media;"
            frameBorder={0}
          />
        </div>
      </>
    );
  },
);

export const VideoBlock = () => {
  const [isLoad, setIsLoad] = useState(false);
  const [randomGroupCount, setRandomGroupCount] = useState(
    getRandomImagesCount(),
  );
  const setLoadHandler = useCallback(() => {
    setTimeout(() => {
      setIsLoad(true);
    }, 500);
  }, []);

  useEffect(() => {
    const ImgInterval = setInterval(() => {
      setRandomGroupCount(getRandomImagesCount());
    }, IMAGE_CHANGE_TIME);

    return () => {
      clearInterval(ImgInterval);
    };
  }, []);

  return (
    <>
      <div className={styles.videoWrap}>
        {images.map((imgs, idx) => (
          <ImgsProgress
            imgs={imgs}
            key={imgs[0].link}
            className={cl(styles.imgsGroup, {
              [styles.visible]: idx === randomGroupCount && isLoad,
            })}
          />
        ))}

        <MemoPlayer setLoadHandler={setLoadHandler} />
      </div>
    </>
  );
};
