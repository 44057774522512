import React, { FC, useEffect } from 'react';
import Button from 'shared/components/button';
import { Icon } from 'shared/components/icon';
import Input from 'shared/components/input';
import { FormStep, TStepsValues } from '..';
import styles from '../form.module.scss';
import { useSelector } from 'react-redux';
import {
  getPromocodeCheck,
  getPromocodeError,
  getPromocodeIsSend,
  getPromocodeLoading,
} from 'entities/AuthBlock/model/selectors/getAuthBlock';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { checkPromocode } from 'entities/AuthBlock/model/services/checkPromocode';
import { authBlockActions } from 'entities/AuthBlock';

type TPromocodeFormProps = {
  promocode: string;
  isSmsSend: boolean;
  setFormStep: React.Dispatch<React.SetStateAction<TStepsValues | undefined>>;
  setFormState: React.Dispatch<
    React.SetStateAction<{
      firstName: string;
      lastName: string;
      phone: string;
      sms: string;
      formType: string;
    }>
  >;
};

const PromocodeForm: FC<TPromocodeFormProps> = React.memo(props => {
  const { promocode, setFormState, setFormStep, isSmsSend } = props;
  const dispatch = useAppDispatch();
  const isPromocodeCheck = useSelector(getPromocodeCheck);
  const isPromocodeLoading = useSelector(getPromocodeLoading);
  const isPromocodeError = useSelector(getPromocodeError);
  const isPromocodeSend = useSelector(getPromocodeIsSend);
  useEffect(() => {
    if (promocode && isPromocodeCheck && isPromocodeSend) {
      if (isSmsSend) {
        setFormStep(FormStep.Sms);
      }
      if (!isSmsSend) {
        setFormStep(FormStep.Phone);
      }
      dispatch(authBlockActions.clearPromocode());
    }
  }, [
    dispatch,
    isPromocodeCheck,
    isPromocodeSend,
    isSmsSend,
    promocode,
    setFormStep,
  ]);

  return (
    <form
      className={styles.form}
      onSubmit={evt => {
        evt.preventDefault();
        if (!promocode) {
          if (isSmsSend) {
            setFormStep(FormStep.Sms);
          }
          if (!isSmsSend) {
            setFormStep(FormStep.Phone);
          }
        }
        if (promocode) {
          dispatch(checkPromocode(promocode));
        }
      }}
    >
      <h2 className={styles.title}> У вас есть промокод?</h2>
      <p className={styles.descr}>Введите промокод или нажмите продолжить</p>
      <Input
        id={`promocodeInput`}
        className={styles.input}
        placeholder="Текст промокода"
        onChange={evt => {
          setFormState(prev => ({
            ...prev,
            promocode: evt.target.value,
          }));
        }}
        value={promocode}
      />

      <div className={styles.footerBtn}>
        <Button type="submit" as="button" disabled={isPromocodeLoading}>
          <Icon name="icon-arrow-right" />
          Продолжить
        </Button>
        {!!isPromocodeError && (
          <span className={styles.smsError}>
            Ошибка сервера, повторите позже
          </span>
        )}
        {isPromocodeSend && !isPromocodeCheck && !isPromocodeLoading && (
          <span className={styles.smsError}>Такой промокод не существует</span>
        )}
      </div>
    </form>
  );
});

export { PromocodeForm };
