import { Icon, TIcon } from '../icon';
import mainstyles from './index.module.scss';
import cl from 'classnames';
import { FC, useEffect, useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverDescription,
  PopoverHeading,
  PopoverClose,
  Placement,
  PopoverOptions,
} from '../popover';
import { WindowWidth } from 'shared/constants/windowWidth';

type TInfoPopover = {
  id: string | number;
  title?: string;
  text?: string;
  iconName: TIcon;
  className?: string;
  placement: Placement;
  offsetValue?: {
    mobile?: number;
    desktop: number;
  };
} & Omit<PopoverOptions, 'offsetValue'>;

const InfoPopover: FC<TInfoPopover> = ({
  iconName,
  title,
  text,
  placement,
  className,
  offsetValue,
}) => {
  const [isOpen, setIsOpen] = useState(!!0);
  const [popupOffset, setPopupOffset] = useState(offsetValue?.desktop);

  useEffect(() => {
    if (window.innerWidth <= WindowWidth.TABLET && offsetValue?.mobile) {
      setPopupOffset(offsetValue?.mobile);
    }
  }, [offsetValue?.mobile]);

  return (
    <div className={cl(mainstyles.wrap)}>
      <Popover
        className={className}
        open={isOpen}
        onOpenChange={setIsOpen}
        placement={placement}
        offsetValue={popupOffset}
      >
        <PopoverTrigger
          className={cl(mainstyles.btn, { [mainstyles.active]: isOpen })}
          onClick={() => setIsOpen(v => !v)}
        >
          <Icon name={iconName} className={mainstyles.icon} />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeading>{title}</PopoverHeading>
          <PopoverDescription>{text}</PopoverDescription>
          <PopoverClose />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export { InfoPopover };
