import React, { FC } from 'react';
import Button from 'shared/components/button';
import { Icon } from 'shared/components/icon';
import Input from 'shared/components/input';
import { FormStep, TStepsValues } from '..';
import styles from '../form.module.scss';
import cl from 'classnames';
import ROUTES from 'shared/constants/routes';

type TNameFormProps = {
  isSmsSend: boolean;
  firstName: string;
  lastName: string;
  setFormStep: React.Dispatch<React.SetStateAction<TStepsValues | undefined>>;
  setFormState: React.Dispatch<
    React.SetStateAction<{
      firstName: string;
      lastName: string;
      phone: string;
      sms: string;
      formType: string;
    }>
  >;
};

const NameForm: FC<TNameFormProps> = React.memo(props => {
  const { isSmsSend, firstName, lastName, setFormState, setFormStep } = props;
  return (
    <form
      className={styles.form}
      onSubmit={evt => {
        evt.preventDefault();
        if (isSmsSend) {
          setFormStep(FormStep.Promocode);
        }
        if (!isSmsSend) {
          setFormStep(FormStep.Promocode);
        }
      }}
    >
      <h2 className={cl(styles.title, styles.reg)}>Давайте знакомиться</h2>
      <Input
        id={`firstNameInput`}
        className={cl(styles.input, styles.name)}
        placeholder="Ваше имя"
        onChange={evt => {
          setFormState(prev => ({
            ...prev,
            firstName: evt.target.value,
          }));
        }}
        value={firstName}
      />
      <Input
        className={styles.input}
        placeholder="Ваша фамилия"
        onChange={evt => {
          setFormState(prev => ({ ...prev, lastName: evt.target.value }));
        }}
        value={lastName}
      />
      <div className={styles.footerBtn}>
        <Button type="submit" disabled={!firstName} as="button">
          <Icon name="icon-arrow-right" />
          Продолжить
        </Button>

        <span className={styles.time}>
          Нажимая, соглашаюсь
          <br />
          на{' '}
          <a
            href={ROUTES.PERSONAL_DOCX.ROUTE}
            target="_blank"
            className={styles.offer}
          >
            обработку данных
          </a>
        </span>
      </div>
    </form>
  );
});

export { NameForm };
