import { Title } from '../Title/Title';
import style from './TarifItem.module.scss';
import { ReactNode } from 'react';
import { Checkbox } from 'shared/components/checkboxInput/components/checkbox';
import utils from 'shared/helpers/utils';
import { v4 as uuidv4 } from 'uuid';

export type TTarifItem = {
  id: 'start' | 'pro' | 'maximum';
  title: string;
  priceText: string;
  descr: ReactNode;
  data: { disabled: boolean; label: ReactNode }[];
  price?: { start: number; pro: number; maximum: number };
};

export const TarifItem = ({ title, descr, data, id, price }: TTarifItem) => {
  return (
    <div className={style.item}>
      <Title type="h3" className={style.title}>
        {title}
      </Title>
      {price && (
        <strong className={style.price}>
          {`${id === 'pro' || id === 'maximum' ? 'от ' : ''}${utils
            .addSpaces(price[id])
            .replace(' ', ' ')} ₽`}
        </strong>
      )}
      <div className={style.descr}>{descr}</div>
      {data.length > 0 && (
        <div className={style.list}>
          {data.map(el => {
            return (
              <div className={style.checkItem} key={uuidv4()}>
                <Checkbox
                  disabled={el.disabled}
                  checked
                  withCursor={false}
                  onChange={() => {}}
                />
                <span>{el.label}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
