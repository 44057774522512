export const VALID = {
  INTEGER: /^[0-9]+$/,
  INTEGER_SPACE: /^[0-9 ]+$/,
  INTEGER_SPACE_DEFIS: /^[0-9 -]+$/,
  // FLOAT: /^(\d+(\.\d{0,})?|\.?\d{1,2})$/,
  FLOAT: /^(\d+((\.\d{0,})|(,\d{0,}))?)$/,
  FLOAT_1: /^(\d+((\.\d{0,1})|(,\d{0,1}))?)$/,
  SIZE: /^[0-9]+(\x)?([0-9]+)?(\x)?([0-9]+)?$/,
  PHONE: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/,
  ONLY_TEXT: /^[a-zа-яёA-ZА-ЯЁ ]+$/i,
  ONLY_TEXT_AND_NUMBERS: /^[a-zа-яёA-ZА-ЯЁ0-9_-]+$/,
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
  // CRON: /^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/,
  CRON: /(^((\*\/)?([0-5]?[0-9])((,|-|\/)([0-5]?[0-9]))*|\*)\s+((\*\/)?((2[0-3]|1[0-9]|[0-9]|00))((,|-|\/)(2[0-3]|1[0-9]|[0-9]|00))*|\*)\s+((\*\/)?([1-9]|[12][0-9]|3[01])((,|-|\/)([1-9]|[12][0-9]|3[01]))*|\*)\s+((\*\/)?([1-9]|1[0-2])((,|-|\/)([1-9]|1[0-2]))*|\*|(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|des))\s+((\*\/)?[0-6]((,|-|\/)[0-6])*|\*|00|(sun|mon|tue|wed|thu|fri|sat))\s*$)|@(annually|yearly|monthly|weekly|daily|hourly|reboot)/gm,
  CRON_SYMBOL: /^([0-9]|(\*)|( )|(\/)|(-)|(,))+$/,
};
