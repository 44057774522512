import React, { FC, useMemo } from 'react';
import Button from 'shared/components/button';
import { Icon } from 'shared/components/icon';
import { IMaskInput } from 'react-imask';
import styles from '../form.module.scss';
import { getImaskConfig } from 'shared/constants/imask';
import { useIsIframe } from 'shared/helpers/hooks/useIsIframe/useIsIframe';
import ROUTES from 'shared/constants/routes';

type TPhoneFormProps = {
  phone: string;
  smsTryError: string | null;
  loading: boolean;
  smsSend: () => Promise<unknown>;
  setFormState: React.Dispatch<
    React.SetStateAction<{
      firstName: string;
      lastName: string;
      phone: string;
      sms: string;
      formType: string;
    }>
  >;
  isBitrix?: boolean;
  isOzon?: boolean;
};

const PhoneForm: FC<TPhoneFormProps> = React.memo(props => {
  const {
    phone,
    smsTryError,
    loading,
    smsSend,
    setFormState,
    isBitrix = false,
    isOzon = false,
  } = props;
  const iFrameData = useIsIframe();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const title = useMemo(() => {
    if (isBitrix && iFrameData.name) {
      return `${capitalizeFirstLetter(
        iFrameData.name,
      )}, добро пожаловать в SplitTest!`;
    }
    return 'Номер телефона';
  }, [iFrameData, isBitrix]);
  const description = useMemo(() => {
    if (isBitrix) {
      return 'Чтобы подтвердить вход — введите номер, на который по СМС будет отправлен код подтверждения';
    }
    return 'Введите номер, на который по СМС будет отправлен код подтверждения';
  }, [isBitrix]);
  return (
    <form
      className={styles.form}
      onSubmit={evt => {
        evt.preventDefault();
        smsSend();
      }}
    >
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.descr}>{description}</p>
      <IMaskInput
        {...getImaskConfig({ className: styles.input })}
        id={`phoneInput`}
        value={phone}
        onAccept={(_, mask) => {
          setFormState(prev => ({ ...prev, phone: mask.unmaskedValue }));
        }}
      />
      <div className={styles.footerBtn}>
        <Button
          type="submit"
          disabled={phone.length !== 11 || loading}
          as="button"
        >
          <Icon name="icon-sms-code" />
          Получить код
        </Button>
        {!smsTryError && (isBitrix || isOzon) && (
          <span className={styles.time}>
            Нажимая, соглашаюсь
            <br />
            на{' '}
            <a
              href={ROUTES.PERSONAL_DOCX.ROUTE}
              target="_blank"
              className={styles.offer}
            >
              обработку данных
            </a>
          </span>
        )}
        {smsTryError !== null ? (
          <span className={styles.smsError}>{smsTryError as string}</span>
        ) : null}
      </div>
    </form>
  );
});

export { PhoneForm };
