import { createAsyncThunk } from '@reduxjs/toolkit';
import supportModel from 'store/models/support';
import { TSendMessageToRespondentsRequest } from '../types/sendMessageToRespondentsSchema';

export const sendMessage = createAsyncThunk(
  'sendMessageToRespondents/sendMessage',
  async (data: TSendMessageToRespondentsRequest, thunkApi) => {
    try {
      const response = await supportModel.postMessageToRespondents(data);
      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
