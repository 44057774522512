import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProfileFormActions } from 'features/Profile';
import CONFIG from 'shared/constants/config';
import authModel from 'store/models/auth';

export const checkPromocode = createAsyncThunk(
  'authBlock/checkPromocode',
  async (promocode: string, thunkApi) => {
    thunkApi.dispatch(ProfileFormActions.setIsOpenSms(true));
    try {
      const response = (await authModel.checkPromo(promocode)) as unknown as {
        check: boolean;
      };
      if (!response?.check) {
        document.cookie = `${CONFIG.PROMO_COOKIE_NAME}=undefined; max-age=0; path=/`;
      }
      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
