import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';
import { initialState } from './initial';
import referralModel from 'store/models/referrals';
import {
  TReferralChartRequest,
  TReferralChartResponse,
  TReferralTransactionsRequest,
  TReferralTransactionsResponse,
  TReferralWithdrawRequest,
} from './schema';

export const fetchReferralTransactions = createAsyncThunk(
  'referral/fetchReferralTransactions',
  async (data: TReferralTransactionsRequest, { rejectWithValue }) => {
    try {
      const response =
        await referralModel.getReferralTransactions<TReferralTransactionsResponse>(
          data,
        );
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchReferralChart = createAsyncThunk(
  'referral/fetchReferralChart',
  async (data: TReferralChartRequest, { rejectWithValue }) => {
    try {
      const response =
        await referralModel.getReferralCharts<TReferralChartResponse>(data);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

//POST

export const fetchWithdrawReferral = createAsyncThunk(
  'referral/fetchWithdrawReferral',
  async (data: TReferralWithdrawRequest, { rejectWithValue }) => {
    try {
      const response = await referralModel.postWithdrawReferral(data);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const refferalSlice = createSlice({
  name: 'refferal',
  initialState: initialState,
  reducers: {
    onChangePeriod(state, action: PayloadAction<string>) {
      state.chart.period = action.payload;
    },
    setWithdrawSum(state, action: PayloadAction<string>) {
      state.withdraw.sum = action.payload;
    },
    clearWithdrawSum(state) {
      state.withdraw.sum = undefined;
    },
    setInit(state) {
      state.init = true;
    },
    clearWithdrawResponse(state) {
      state.withdraw = initialState.withdraw;
    },
  },
  extraReducers: builder => {
    //fetchReferralTransactions
    builder
      .addCase(fetchReferralTransactions.pending, state => {
        state.transactions.loading = true;
        state.transactions.error = undefined;
      })
      .addCase(fetchReferralTransactions.fulfilled, (state, action) => {
        state.transactions.loading = false;
        state.transactions.error = undefined;
        state.transactions.response = action.payload;
      })
      .addCase(fetchReferralTransactions.rejected, (state, action) => {
        state.transactions.loading = false;
        const message = (action.payload as NetworkError).message;
        state.transactions.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //fetchReferralChart
    builder
      .addCase(fetchReferralChart.pending, state => {
        state.chart.chartLoading = true;
        state.chart.chartError = undefined;
      })
      .addCase(fetchReferralChart.fulfilled, (state, action) => {
        state.chart.chartLoading = false;
        state.chart.chartError = undefined;
        state.chart.chartResponse = action.payload;
      })
      .addCase(fetchReferralChart.rejected, (state, action) => {
        state.chart.chartLoading = false;
        const message = (action.payload as NetworkError).message;
        state.chart.chartError = {
          ...(action.payload as NetworkError),
          message,
        };
      });

    //POST

    //fetchWithdrawReferral
    builder
      .addCase(fetchWithdrawReferral.pending, state => {
        state.withdraw.withdrawLoading = true;
        state.withdraw.withdrawError = undefined;
      })
      .addCase(fetchWithdrawReferral.fulfilled, state => {
        state.withdraw.withdrawLoading = false;
        state.withdraw.withdrawError = undefined;
        state.withdraw.withdrawResponse = 200;
      })
      .addCase(fetchWithdrawReferral.rejected, (state, action) => {
        state.withdraw.withdrawLoading = false;
        const message = (action.payload as NetworkError).message;
        state.withdraw.withdrawError = {
          ...(action.payload as NetworkError),
          message,
        };
      });
  },
});

export const { actions: refferalActions } = refferalSlice;
export const { reducer: refferalReducer } = refferalSlice;
