import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';
// eslint-disable-next-line import/extensions
import TestModel from '../../../store/models/test';
import { TTestState, TTestResponse } from './types';

export const fetchTest = createAsyncThunk(
  'test/fetchTest',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        (await TestModel.getTestData()) as unknown as TTestResponse[];
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const initialState: TTestState = {
  response: [],
  error: null,
  loading: false,
};

const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(fetchTest.pending, state => {
      state.loading = true;
    });

    builder.addCase(fetchTest.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.response = payload as unknown as TTestResponse[];
    });

    builder.addCase(fetchTest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as NetworkError;
    });
  },
});

export default testSlice.reducer;
