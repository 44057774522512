import { setOzonAuth } from 'pages/user/store/store';
import { memo, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import ROUTES from 'shared/constants/routes';
import { useIsIframe } from 'shared/helpers/hooks/useIsIframe/useIsIframe';
import { getBitrixAuth, getOzonAuth } from 'store/selectors/user';

type TLandingLayoutRoute = {
  isAuth: boolean;
  children?: ReactNode;
};

const OzonLayoutRoute = ({ isAuth, children }: TLandingLayoutRoute) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setOzonAuth());
  }, [dispatch]);
  const { isOzonAuth } = useSelector(getOzonAuth);

  if (isAuth && isOzonAuth) {
    return <Navigate to={ROUTES.CABINET_TESTS.ROUTE} replace />;
  }

  return <>{children ?? <Outlet />}</>;
};

export default memo(OzonLayoutRoute);
