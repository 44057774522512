import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';
import {
  BalanceSchema,
  TPayer,
  TTransactionType,
} from '../types/balanceSchema';
import { fetchClientTransactions } from '../services/fetchClientTransactions';
import { fetchLoadDocument } from '../services/fetchLoadDocument';
import { fetchCreatePaymentInvoice } from '../services/fetchCreatePaymentInvoice';

const initialState: BalanceSchema = {
  dataDraft: undefined,
  dataExecuted: undefined,
  loadingDraft: false,
  errorDraft: null,
  loadingExecuted: false,
  errorExecuted: null,
  downloadDocument: {
    loading: false,
    error: null,
    isDownloaded: false,
    idDocument: undefined,
  },
  upBalance: {
    username: undefined,
    companyname: undefined,
    inn: undefined,
    payer: 'user',
    transactionType: 'card',
    summ: undefined,
    loading: false,
    error: null,
    popupIsOpen: false,
    downloadLoading: false,
  },
};

export const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    setUsername(state, action: PayloadAction<string>) {
      state.upBalance.username = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.upBalance.email = action.payload;
    },
    setCompanyname(state, action: PayloadAction<string>) {
      state.upBalance.companyname = action.payload;
    },
    setCompanyLabel(state, action: PayloadAction<string>) {
      state.upBalance.companyLabel = action.payload;
    },
    setCompanyAddress(state, action: PayloadAction<string>) {
      state.upBalance.address = action.payload;
    },
    setInn(state, action: PayloadAction<string>) {
      state.upBalance.inn = action.payload;
    },
    setSumm(state, action: PayloadAction<string>) {
      state.upBalance.summ = action.payload;
    },
    setPayer(state, action: PayloadAction<TPayer>) {
      state.upBalance.payer = action.payload;
    },
    setTransactionType(state, action: PayloadAction<TTransactionType>) {
      state.upBalance.transactionType = action.payload;
    },
    setOpenBalanceUpPopup(state) {
      state.upBalance.popupIsOpen = true;
    },
    setCloseBalanceUpPopup(state) {
      state.upBalance = {
        username: '',
        companyname: '',
        inn: '',
        payer: 'user',
        transactionType: 'card',
        summ: '',
        loading: false,
        error: null,
        popupIsOpen: false,
        redirectLink: undefined,
        redirectToBalance: undefined,
        downloadLoading: false,
      };
    },
    clearTransaction(state) {
      state.upBalance = {
        username: undefined,
        companyname: undefined,
        inn: undefined,
        payer: 'user',
        transactionType: 'card',
        summ: undefined,
        loading: false,
        error: null,
        popupIsOpen: false,
        redirectLink: undefined,
        redirectToBalance: undefined,
        downloadLoading: false,
      };
    },
  },
  extraReducers: builder => {
    //fetchClientTransactions
    builder
      .addCase(fetchClientTransactions.pending, (state, { meta }) => {
        if (meta.arg.draft) {
          state.loadingDraft = true;
          state.errorDraft = null;
        } else {
          state.loadingExecuted = true;
          state.errorExecuted = null;
        }
      })
      .addCase(
        fetchClientTransactions.fulfilled,
        (state, { meta, payload }) => {
          if (meta.arg.draft) {
            state.loadingDraft = false;
            state.errorDraft = null;
            state.dataDraft = payload.data;
            state.initDraftBlock = true;
          } else {
            state.loadingExecuted = false;
            state.errorExecuted = null;
            state.dataExecuted = payload.data;
            state.initPaymentsBlock = true;
            state.page = payload.meta.current_page;
            state.total = payload.meta.total;
            state.lastPage = payload.meta.last_page;
          }
        },
      )
      .addCase(fetchClientTransactions.rejected, (state, { meta, payload }) => {
        const message = (payload as NetworkError).message;
        if (meta.arg.draft) {
          state.loadingDraft = false;
          state.errorDraft = {
            ...(payload as NetworkError),
            message,
          };
          state.initDraftBlock = true;
        } else {
          state.loadingExecuted = false;
          state.errorExecuted = {
            ...(payload as NetworkError),
            message,
          };
          state.initPaymentsBlock = true;
        }
      });

    // balance/fetchLoadDocument
    builder.addCase(fetchLoadDocument.pending, (state, { meta }) => {
      state.downloadDocument.loading = true;
      state.downloadDocument.isDownloaded = false;
      state.downloadDocument.error = null;
      state.downloadDocument.idDocument = meta.arg.id;
      state.upBalance.downloadLoading = true;
    });

    builder.addCase(fetchLoadDocument.fulfilled, state => {
      state.downloadDocument.loading = false;
      state.downloadDocument.error = null;
      state.downloadDocument.isDownloaded = true;
      state.downloadDocument.idDocument = undefined;
      state.upBalance.downloadLoading = false;
    });

    builder.addCase(fetchLoadDocument.rejected, (state, action) => {
      state.downloadDocument.loading = false;
      state.downloadDocument.isDownloaded = false;
      state.downloadDocument.idDocument = undefined;
      const message = (action.payload as NetworkError).message;
      state.errorExecuted = {
        ...(action.payload as NetworkError),
        message,
      };
      state.upBalance.downloadLoading = false;
    });

    // balance/fetchCreatePaymentInvoice
    builder.addCase(fetchCreatePaymentInvoice.pending, state => {
      state.upBalance.loading = true;
      state.upBalance.error = null;
    });

    builder.addCase(
      fetchCreatePaymentInvoice.fulfilled,
      (state, { payload }) => {
        state.upBalance.loading = false;
        state.upBalance.error = null;
        if (state.upBalance.transactionType === 'card') {
          state.upBalance.redirectLink = payload.data.link;
        }
        if (state.upBalance.transactionType === 'bill') {
          state.upBalance.redirectToBalance = true;
        }
      },
    );

    builder.addCase(fetchCreatePaymentInvoice.rejected, (state, action) => {
      const message = (action.payload as NetworkError).message;
      state.upBalance.loading = false;
      state.upBalance.error = {
        ...(action.payload as NetworkError),
        message,
      };
    });
  },
});

export const { actions: balanceActions } = balanceSlice;
export const { reducer: balanceReducer } = balanceSlice;
