import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import CONFIG from 'shared/constants/config';
import ROUTES from 'shared/constants/routes';
import { getIsAuth } from 'store/selectors/user';

export function getCookie(name: string) {
  const matches = document.cookie.split(';').find(item => item.includes(name));
  return matches ? matches.replace(`${name}=`, '') : undefined;
}

const PromocodePage = () => {
  const isAuth = useSelector(getIsAuth);
  const { promocode } = useParams<{ promocode: string }>();

  if (!isAuth) {
    if (getCookie(CONFIG.PROMO_COOKIE_NAME)) {
      document.cookie = `${CONFIG.PROMO_COOKIE_NAME}=${promocode}; max-age=0`;
    }
    document.cookie = `${CONFIG.PROMO_COOKIE_NAME}=${promocode}; max-age=2592000; path=/`;
    return <Navigate to={ROUTES.MAIN.ROUTE} replace />;
  }
  if (isAuth) {
    return <Navigate to={ROUTES.CABINET_TESTS.ROUTE} replace />;
  }

  return <></>;
};

export default memo(PromocodePage);
