export const TEST_TYPE = {
  COUPLE: 'couple',
  RANGE: 'range',
  STAR: 'star',
  QUESTION: 'question',
  CLICK: 'click',
  TOURNEY: 'tourney',
} as const;

export type TCoupleAliasKeys = typeof TEST_TYPE[keyof typeof TEST_TYPE];

export const TEST_NAME: Record<TCoupleAliasKeys, string> = {
  [TEST_TYPE.COUPLE]: 'Тест один из двух',
  [TEST_TYPE.RANGE]: 'Приоритетный тест',
  [TEST_TYPE.STAR]: 'Звездный рейтинг',
  [TEST_TYPE.QUESTION]: 'Открытый вопрос',
  [TEST_TYPE.CLICK]: 'Клик-тест (heatmap)',
  [TEST_TYPE.TOURNEY]: 'Турнирный тест',
} as const;

export const TARIFF_TYPE = {
  BASIC: 'basic',
  START: 'start',
  PRO: 'pro',
  MAXIMUM: 'maximum',
} as const;

export type TCoupleTariffKeys = typeof TARIFF_TYPE[keyof typeof TARIFF_TYPE];

export const TARIFF_NAME: Record<TCoupleTariffKeys, string> = {
  [TARIFF_TYPE.BASIC]: 'Базовый тариф — бесплатно',
  [TARIFF_TYPE.START]: 'START — 999 ₽/мес.',
  [TARIFF_TYPE.PRO]: 'PRO — 1 999 ₽/мес.',
  [TARIFF_TYPE.MAXIMUM]: 'MAXIMUM — 4 999 ₽/мес.',
} as const;
