import React, { FC, useCallback } from 'react';
import styles from './index.module.scss';
import Button from 'shared/components/button';
import stylesButton from 'shared/components/button/index.module.scss';
import { Icon } from 'shared/components/icon';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { useSelector } from 'react-redux';
import {
  getFormType,
  getPopupIsOpen,
  getPopupType,
} from '../../../../model/selectors/getAuthBlock';
import { authBlockActions } from '../../../../model/slices/authBlockSlice';
import cl from 'classnames';
import { FormType, TFormTypeValues } from '../form';
import { AuthBlockBtnsId } from '../..';
import { Position, PositionValues } from 'app/styles/types';
import { burgerBlockActions } from 'shared/components/burgerBlock/model/slices/burgerBlockSlice';
import { sendMetrik } from 'shared/helpers/sendMetrik/yandexMetrik';

type TButtons = {
  className?: string;
  position?: PositionValues;
};

export const AuthButtonsComponent: FC<TButtons> = React.memo(
  ({ className = '', position }) => {
    const dispatch = useAppDispatch();
    const showPopup = useSelector(getPopupIsOpen);
    const formType = useSelector(getFormType);
    const popupType = useSelector(getPopupType);
    const togglePopup = useCallback(
      (type: TFormTypeValues) => {
        dispatch(authBlockActions.setOpenAuthPopup());
        dispatch(authBlockActions.setFormType(type));
        dispatch(burgerBlockActions.setOpenPopup(false));
        dispatch(authBlockActions.setPopupType('header'));
      },
      [dispatch],
    );
    const isDesktop = position === Position.Desktop;
    const isMobile = position === Position.Mobile;

    const onOpenRegistration = useCallback(() => {
      sendMetrik('reachGoal', 'push_button_registration');
      togglePopup(FormType.Reg);
    }, [togglePopup]);

    return (
      <div
        className={cl(className, styles.buttons, {
          [styles.desktop]: isDesktop,
          [styles.mobile]: isMobile,
        })}
      >
        <Button
          id={AuthBlockBtnsId.Login}
          className={cl(styles.loginBtn, {
            [stylesButton.outline]: formType === FormType.Auth,
          })}
          onClick={evt => {
            evt.currentTarget.blur();
            togglePopup(FormType.Auth);
          }}
          as="button"
        >
          <Icon name="icon-user" />
          Вход
        </Button>

        <Button
          id={AuthBlockBtnsId.Reg}
          onClick={onOpenRegistration}
          transparent
          nopadding
          as="button"
        >
          <Icon name="icon-key" />
          <span
            className={cl({
              [styles.bordered]:
                showPopup &&
                formType === FormType.Reg &&
                popupType === 'header',
            })}
          >
            Регистрация
          </span>
        </Button>
      </div>
    );
  },
);
