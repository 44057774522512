import Network, { TAxios } from 'shared/libraries/network';
import CONFIG from 'shared/constants/config';
import { TTransactionsRequestData } from 'entities/Balance';
import {
  TCreateInvoiceParams,
  TDadataRequest,
} from 'entities/Balance/model/types/balanceSchema';

class BalanceModel extends Network {
  constructor(baseURL: string, timeout = 120000) {
    super(baseURL, timeout);
  }

  //GET
  //Получение списка транзакций
  async getClientTransactions(data: TTransactionsRequestData): Promise<TAxios> {
    return this.send('get', `/transactions`, { params: { ...data } });
  }

  //daData
  async getSuggestDaData<T>(data: TDadataRequest): Promise<T> {
    const { point = 'suggest', ...rest } = data;
    return this.send('get', `/${point}`, { params: { ...rest } });
  }

  // скачать акт или счет
  async getDocument(data: { id: number }): Promise<TAxios> {
    const { id } = data;
    return this.sendFile('get', `/transactions/${id}/doc`, {
      responseType: 'blob',
    });
  }

  //POST
  //Создать акт или счет
  async postCreateInvoice(data: TCreateInvoiceParams): Promise<TAxios> {
    return this.send('post', `/transactions`, { ...data });
  }
}

const baseUrl = CONFIG.REACT_APP_API_BASE_URL as string;
const balanceModel = new BalanceModel(baseUrl);

export default balanceModel;
