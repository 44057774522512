import { TState } from 'store/types';

export const getUserSupportData = (state: TState) =>
  state.userSupport.data || {};

export const getUserSupportName = (state: TState) =>
  state.userSupport.data.name || '';

export const getUserSupportSurname = (state: TState) =>
  state.userSupport.data.surname || '';

export const getUserSupportPhone = (state: TState) =>
  state.userSupport.data.phone || '';

export const getUserSupportEmail = (state: TState) =>
  state.userSupport.data.email || '';

export const getUserSupportMessage = (state: TState) =>
  state.userSupport.data.message || '';

export const getUserSupportTheme = (state: TState) =>
  state.userSupport.data.type || '';

export const getUserSupportLoading = (state: TState) =>
  state.userSupport.loading || false;

export const getUserSupportIsGoodResponse = (state: TState) =>
  state.userSupport.isGoodResponse || false;

export const getUserSupportIsOpen = (state: TState) => state.userSupport.isOpen;

export const getUserSupportError = (state: TState) => state.userSupport.error;
