type TPostion = 'cabinetHeader' | 'footer' | 'footerSupport' | 'footer docs';

export type TRoute = {
  ROUTE: string;
  NAME: string;
  POSITION?: TPostion | null;
  ANKER?: boolean;
  TBLANK?: boolean;
};

type TRoutes = {
  [key: string]: TRoute;
};

const ROUTES: TRoutes = {
  MAIN: {
    ROUTE: '/',
    NAME: 'Сервис для создания онлайн-опросов и маркетинговых исследований SPLITTEST',
  },
  SHARE: {
    ROUTE: '/share/:hash',
    NAME: 'Результы теста',
  },
  PROMO: {
    ROUTE: '/promo/:promocode',
    NAME: 'Промокод',
  },
  CABINET_MAIN: {
    ROUTE: '/main',
    NAME: 'Главная',
  },
  CABINET_TESTS: {
    ROUTE: '/all',
    NAME: 'Тесты',
    POSITION: 'cabinetHeader',
  },
  TEST_ITEM: {
    ROUTE: '/all/:id',
    NAME: 'Тесты',
  },
  CABINET_NEW_TEST: {
    ROUTE: '/new',
    NAME: 'Новый тест',
    POSITION: 'cabinetHeader',
  },
  CABINET_EDIT: {
    ROUTE: '/edit',
    NAME: 'Редактировать',
  },
  CABINET_EDIT_TEST: {
    ROUTE: '/edit/:id',
    NAME: 'Редактировать тест',
  },
  CABINET_PROFILE: {
    ROUTE: '/profile',
    NAME: 'Профиль',
    POSITION: 'cabinetHeader',
  },
  CABINET_INFORMATION: {
    ROUTE: '/profile/information',
    NAME: 'Персональные данные',
  },
  CABINET_REFERRAL: {
    ROUTE: '/profile/referral',
    NAME: 'Реферальная программа',
  },
  CABINET_PARTNER: {
    ROUTE: '/profile/referral',
    NAME: 'Мой промокод',
  },
  PARTNER_REGISTER: {
    ROUTE: '/partner',
    NAME: 'Данные партнера',
  },
  CABINET_RESPONDENTS: {
    ROUTE: '/profile/respondents',
    NAME: 'Собственная аудитория',
  },
  CABINET_TARIFF: {
    ROUTE: '/profile/tariff',
    NAME: 'Тариф и подписка',
  },
  CABINET_BALANCE: {
    ROUTE: '/balance',
    NAME: 'Баланс',
    POSITION: 'cabinetHeader',
  },
  CABINET_HELP: {
    ROUTE: '/help',
    NAME: 'Поддержка',
    POSITION: 'cabinetHeader',
  },
  FEATURES: {
    ROUTE: '/features',
    NAME: 'Как это работает: исследование за 6 шагов',
  },
  PRICES: {
    ROUTE: '/prices',
    NAME: 'Базовый тариф и базовые возможности',
  },
  SUBSCRIPTIONS: {
    ROUTE: '/subscriptions',
    NAME: 'Расширение возможностей с пакетом подписки',
  },
  REVIEWS: {
    ROUTE: '/reviews',
    NAME: 'Отзывы — о сервисе говорят реальные клиенты',
  },
  TESTS: {
    ROUTE: '/tests',
    NAME: 'Тесты',
  },
  ARTICLES: {
    ROUTE: '/articles',
    NAME: 'Статьи',
  },
  ARTICLES1: {
    ROUTE: '/articles1',
    NAME: 'Статьи',
  },
  ABOUT: {
    ROUTE: '/about',
    NAME: 'О сервисе',
    POSITION: 'footer',
  },
  REFERRAL: {
    ROUTE: '/referral',
    NAME: 'Оферта реферальная программа',
    POSITION: 'footer docs',
  },
  REFERRAL_DOCS: {
    ROUTE: '/referral.pdf',
    NAME: 'Оферта реферальная программа',
    POSITION: 'footer docs',
  },
  OFFER: {
    ROUTE: '/agreement',
    NAME: 'Договор-оферта',
    POSITION: 'footer docs',
  },
  OFFER_DOCX: {
    ROUTE: '/agreement.pdf',
    NAME: 'Договор-оферта',
    POSITION: 'footer',
    TBLANK: true,
  },
  PERSONAL: {
    ROUTE: '/privacy',
    NAME: 'Обработка личной информации',
    POSITION: 'footer docs',
  },
  PERSONAL_DOCX: {
    ROUTE: '/privacy.pdf',
    NAME: 'Обработка личной информации',
    POSITION: 'footer',
    TBLANK: true,
  },
  SURVEY_AGREEMENT: {
    ROUTE: '/survey_agreement',
    NAME: 'Обработка личной информации',
    POSITION: 'footer docs',
  },
  SURVEY_AGREEMENT_DOCX: {
    ROUTE: '/survey_agreement.pdf',
    NAME: 'Обработка личной информации',
    POSITION: 'footer docs',
  },
  HELP: {
    ROUTE: '/help',
    NAME: 'Поддержка',
    POSITION: 'footer',
  },
  CONNECT: {
    ROUTE: '/',
    NAME: 'Подключение кабинета',
    POSITION: 'footerSupport',
  },
  SPAM: {
    ROUTE: '/spam',
    NAME: 'Рассылка респондентам',
    POSITION: 'footerSupport',
  },
  NOTIFICATION: {
    ROUTE: '/notification',
    NAME: 'Уведомления',
    POSITION: 'footerSupport',
  },
  EMBEDED_BITRIX_LOGIN: {
    ROUTE: '/embed/bitrix/login',
    NAME: 'Bitrix24 логин',
  },
  EMBEDED_BITRIX_NEW: {
    ROUTE: '/embed/bitrix/new',
    NAME: 'Bitrix24 новый тест',
  },
  EMBEDED_BITRIX_ALL: {
    ROUTE: '/embed/bitrix/all',
    NAME: 'Bitrix24 тесты',
  },
  EMBEDED_OZON: {
    ROUTE: '/embed/ozon',
    NAME: 'Ozon тесты',
  },
};

export default ROUTES;
