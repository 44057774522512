import { TState } from 'store/types';
import { SendMessageToRespondentsSchema } from '../types/sendMessageToRespondentsSchema';
import { createSelector } from '@reduxjs/toolkit';

export const getMessageToRespondents = (
  state: TState,
): SendMessageToRespondentsSchema => state.sendMessageToRespondents;

export const getSendMessageToRespondentsIsOpen = createSelector(
  getMessageToRespondents,
  ({ isOpen }) => isOpen,
);

export const getSendMessageToRespondentsTester = createSelector(
  getMessageToRespondents,
  ({ tester }) => tester,
);

export const getSendMessageToRespondentsPromocode = createSelector(
  getMessageToRespondents,
  ({ promocode }) => promocode,
);

export const getSendMessageToRespondentsMessage = createSelector(
  getMessageToRespondents,
  ({ message }) => message || '',
);

export const getSendMessageToRespondentsIsLoading = createSelector(
  getMessageToRespondents,
  ({ isLoading }) => isLoading,
);

export const getSendMessageToRespondentsError = createSelector(
  getMessageToRespondents,
  ({ error }) => error,
);

export const getSendMessageToRespondentsIsSent = createSelector(
  getMessageToRespondents,
  ({ isSent }) => isSent,
);

export const getPromocodesList = createSelector(
  getMessageToRespondents,
  ({ promocodesList: { data } }) => data || [],
);
