import {
  TPayer,
  TTransactionType,
} from 'entities/Balance/model/types/balanceSchema';

export const radioBalanceData: {
  idx: TTransactionType;
  name: string;
  disabled: boolean;
}[] = [
  {
    idx: 'card',
    name: 'Банковской картой',
    disabled: false,
  },
  {
    idx: 'bill',
    name: 'Оплатой по счету',
    disabled: false,
  },
];

export const radioPersonData: {
  idx: TPayer;
  name: string;
  disabled: false;
}[] = [
  {
    idx: 'user',
    name: 'Плательщик физическое лицо',
    disabled: false,
  },
  {
    idx: 'company',
    name: 'Плательщик юридическое лицо / ИП',
    disabled: false,
  },
];

export const selectOptions = [
  {
    value: '1',
    label: `
    ООО "ТЕХНОТРЕЙД"
    5257177263
    Нижний Новгород, ул Тонкинская, 7А`,
  },
  {
    value: '2',
    label: `
    ООО "ТЕХНОТРЕЙД"
    5256087169
    Нижний Новгород, Южное шоссе, 14`,
  },
  {
    value: '3',
    label: `
    ООО "ТЕХНОТРЕЙД"
    5245027263
    ул Кудьминская промышленная зона N2, 1
  `,
  },
];
