import { FC, ImgHTMLAttributes, PropsWithChildren } from 'react';
import styles from './index.module.scss';
import cl from 'classnames';
import { Rating } from 'shared/components/rating';
import getFineNumber from 'shared/helpers/utils/getFineNumber';

type TImage = ImgHTMLAttributes<HTMLImageElement> & {
  error?: boolean;
  noMinImg?: boolean;
  text?: string;
  rating?: number | string;
};

const Image = ({
  text,
  rating,
  alt = '',
  error,
  noMinImg,
  ...props
}: TImage) => {
  return (
    <div className={styles.wrap}>
      <div
        className={cl(styles.wrapImage, {
          [styles.error]: error,
          [styles.noMin]: noMinImg,
        })}
      >
        <img alt={alt} {...props} />
      </div>
      {rating ? (
        <Rating className={styles.rating} rating={rating} withValue />
      ) : null}
      {text ? <strong className={styles.text}>{text}</strong> : null}
    </div>
  );
};

const ImageGrid: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className = '',
}) => <div className={cl(styles.grid, className)}>{children}</div>;

export { Image, ImageGrid };
