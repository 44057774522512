import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';

import { userSupportSchema } from '../types/userSupportSchema';
import { fetchUserSupport } from '../services/fetchUserSuport';

const initialState: userSupportSchema = {
  data: {},
  loading: false,
  error: null,
  isOpen: false,
  isGoodResponse: false,
};

export const UserSupportSlice = createSlice({
  name: 'userSupport',
  initialState,
  reducers: {
    setUserSupportOpen(state) {
      state.isOpen = true;
    },
    setUserSupportClose(state) {
      state.isOpen = false;
      state.isGoodResponse = false;
      state.data = {};
      state.error = null;
      state.loading = false;
    },
    setPhone(state, action: PayloadAction<string>) {
      state.data.phone = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.data.email = action.payload;
    },
    setName(state, action: PayloadAction<string>) {
      state.data.name = action.payload;
    },
    setSurname(state, action: PayloadAction<string>) {
      state.data.surname = action.payload;
    },
    setMessage(state, action: PayloadAction<string>) {
      state.data.message = action.payload;
    },
    setTheme(state, action: PayloadAction<string>) {
      state.data.type = action.payload;
    },
    setGoodResponse(state, action: PayloadAction<boolean>) {
      state.isGoodResponse = action.payload;
    },
    clearMessageData(state) {
      state.data = {};
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    //fetchUserSupport
    builder
      .addCase(fetchUserSupport.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserSupport.fulfilled, state => {
        state.loading = false;
        state.error = null;
        state.isGoodResponse = true;
        // state.isOpen = false;п
      })
      .addCase(fetchUserSupport.rejected, (state, action) => {
        state.loading = false;
        const message = (action.payload as NetworkError).message;
        state.error = { ...(action.payload as NetworkError), message };
      });
  },
});

export const { actions: userSupportActions } = UserSupportSlice;
export const { reducer: userSupportReducer } = UserSupportSlice;
