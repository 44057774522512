import { FC, DOMAttributes, PropsWithChildren, useEffect } from 'react';
import { Icon } from 'shared/components/icon';
import styles from './index.module.scss';
import cl from 'classnames';
import { useSelector } from 'react-redux';
import { getPopupType } from 'entities/AuthBlock/model/selectors/getAuthBlock';
import { TAuthBlockType } from 'entities/AuthBlock/ui/authBlock';

export type TPopupPosition = 'static' | 'absolute';

type TPopup = DOMAttributes<HTMLDivElement> & {
  closeHandler: () => void;
  closeIds?: string[];
  isShow: boolean;
  className?: string;
  withArrow?: boolean;
  arrowPosition?: 'left' | 'top';
  withCloseBtn?: boolean;
  borderRadius?: number;
  position?: TPopupPosition;
  type?: 'navbar' | 'comment' | 'auth';
  parentType?: TAuthBlockType;
};

export const PopupNoCloseClass = 'noClosePopup';

const Popup: FC<TPopup> = ({
  className = '',
  children,
  closeHandler,
  closeIds = [],
  isShow,
  withArrow,
  arrowPosition = 'left',
  withCloseBtn = true,
  borderRadius = 10,
  type,
  position,
  parentType,
  ...props
}) => {
  const popupType = useSelector(getPopupType);
  useEffect(() => {
    const handleDocumentClick = (evt: MouseEvent) => {
      const el = evt.target as Element;
      if (
        !el.classList.contains(PopupNoCloseClass) &&
        !el.closest(`.${styles.popup}`) &&
        !closeIds.find(id => el.closest(`#${id}`))
      ) {
        closeHandler();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      {...props}
      className={cl(styles.popup, className, {
        [styles['popup--show']]: isShow,
        [styles['popup--navbar']]: type === 'navbar',
        [styles['popup--comment']]: type === 'comment',
        [styles['popup--auth']]: type === 'auth',
        [styles.header]: popupType === 'header' && parentType === popupType,
        [styles.landing]: popupType === 'landing' && parentType === popupType,
      })}
      style={{
        ...(position ? { position } : {}),
        borderRadius,
      }}
    >
      {withArrow && (
        <span
          className={cl(styles.arrow, {
            [styles.left]: arrowPosition === 'left',
            [styles.top]: arrowPosition === 'top',
          })}
        />
      )}
      {withCloseBtn && (
        <button type="button" className={styles.button} onClick={closeHandler}>
          <Icon name="icon-close" />
        </button>
      )}
      {children}
    </div>
  );
};
export const PopupTitle: FC<PropsWithChildren> = ({ children }) => (
  <h2 className={styles.title}>{children}</h2>
);
export default Popup;
