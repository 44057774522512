import { Modal } from 'shared/components/modal/modal';
import { Suspense, useCallback } from 'react';
import { CompliantActions } from '../../model/slice/compliantSlice';
import { useSelector } from 'react-redux';
import { getShowModal } from '../../model/selectors/getCompliantModalShow/getCompliantShowModal';
import CompliantForm from '../ComplaintForm/CompliantForm';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';

const CompliantModal = () => {
  const dispatch = useAppDispatch();
  const onClose = useCallback(() => {
    dispatch(CompliantActions.closeCompliantModal());
  }, [dispatch]);

  const isOpen = useSelector(getShowModal);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Suspense fallback={''}>
        <CompliantForm onSuccess={onClose} />
      </Suspense>
    </Modal>
  );
};

export { CompliantModal };
