import { FC, PropsWithChildren } from 'react';
import style from './index.module.scss';
import cl from 'classnames';

type TPageDescription = PropsWithChildren<{
  className?: string;
}>;

const PageDescription: FC<TPageDescription> = ({ children, className }) => {
  return <div className={cl(style.text, className)}>{children}</div>;
};

export { PageDescription };
