import utils from '../utils';

const nbsp = document.createTextNode('\u00A0');

export class Price {
  static isValid(value: string | number) {
    return !!value || Number(value) === 0;
  }

  static getString(value: string | number) {
    if (!Price.isValid(value)) return '';

    return `${utils.addSpaces(value)}${nbsp.textContent}₽`;
  }
}

export class PriceWOR {
  static isValid(value: string | number) {
    return !!value || Number(value) === 0;
  }

  static getString(value: string | number) {
    if (!Price.isValid(value)) return '';

    return `${utils.addSpaces(value)}${nbsp.textContent}`;
  }
}
