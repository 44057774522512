import { memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import ROUTES from 'shared/constants/routes';
import { useIsIframe } from 'shared/helpers/hooks/useIsIframe/useIsIframe';
import { getBitrixAuth } from 'store/selectors/user';

type TLandingLayoutRoute = {
  isAuth: boolean;
  children?: ReactNode;
};

const BitrixLayoutRoute = ({ isAuth, children }: TLandingLayoutRoute) => {
  const { isBitrixNew } = useIsIframe();
  const { isBitrixAuth } = useSelector(getBitrixAuth);

  if (isAuth && isBitrixAuth) {
    if (isBitrixNew) {
      return <Navigate to={ROUTES.CABINET_NEW_TEST.ROUTE} replace />;
    }
    return <Navigate to={ROUTES.CABINET_TESTS.ROUTE} replace />;
  }

  return <>{children ?? <Outlet />}</>;
};

export default memo(BitrixLayoutRoute);
