import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import NetworkError from 'shared/exceptions/network';
import { SendMessageToRespondentsSchema } from '../types/sendMessageToRespondentsSchema';
import { fetchPromocodes } from '../services/fetchPromocodes';
import { sendMessage } from '../services/sendMessageToRespondents';

const initialState: SendMessageToRespondentsSchema = {
  tester: false,
  isOpen: false,
  isSent: false,
  isLoading: false,
  error: null,
  promocodesList: {
    data: undefined,
    loading: false,
    error: null,
  },
};

export const sendMessageToRespondentsSlice = createSlice({
  name: 'sendMessageToRespondents',
  initialState,
  reducers: {
    sendIsopenModal(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
      if (!action.payload) {
        state.tester = false;
        state.isSent = false;
        state.promocode = undefined;
        state.isSent = false;
        state.message = undefined;
        state.promocodesList.data = undefined;
      }
    },
    sendIsTester(state, action: PayloadAction<boolean>) {
      state.tester = action.payload;
    },
    sendPromocode(state, action: PayloadAction<string | undefined | null>) {
      state.promocode = action.payload;
    },
    sendMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    sendIsSent(state, action: PayloadAction<boolean>) {
      state.isSent = action.payload;
      state.error = null;
    },
  },
  extraReducers: builder => {
    // fetchPromocodes;
    builder
      .addCase(fetchPromocodes.pending, state => {
        state.promocodesList.loading = true;
        state.promocodesList.error = null;
      })
      .addCase(fetchPromocodes.fulfilled, (state, action) => {
        state.promocodesList.loading = true;
        state.promocodesList.error = null;
        state.promocodesList.data = action.payload;
      })
      .addCase(fetchPromocodes.rejected, (state, action) => {
        state.promocodesList.loading = false;
        const message = (action.payload as NetworkError)?.message;
        state.promocodesList.error = {
          ...(action.payload as NetworkError),
          message,
        };
      });
    //getAccess
    builder
      .addCase(sendMessage.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendMessage.fulfilled, state => {
        state.isLoading = false;
        state.error = null;
        state.isSent = true;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false;
        const message = (action.payload as NetworkError)?.message;
        state.error = { ...(action.payload as NetworkError), message };
      });
  },
});

export const { actions: sendMessageToRespondentsActions } =
  sendMessageToRespondentsSlice;
export const { reducer: sendMessageToRespondentsReducer } =
  sendMessageToRespondentsSlice;
