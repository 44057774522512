import Network, { TAxios } from 'shared/libraries/network';
import CONFIG from 'shared/constants/config';

class TariffsModel extends Network {
  constructor(baseURL: string, timeout = 120000) {
    super(baseURL, timeout);
  }

  //GET
  //Получение списка тарифов
  async getTariffs(): Promise<TAxios> {
    return this.send('get', `/tariffs`);
  }

  //Получение списка тарифов для главной
  async getMainTariffs(): Promise<TAxios> {
    return this.send('get', `/prices`);
  }
}

const baseUrl = CONFIG.REACT_APP_API_BASE_URL as string;
const tariffsModel = new TariffsModel(baseUrl);

export default tariffsModel;
