import { FC, useCallback, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Icon } from '../icon';
import styles from './index.module.scss';
import cl from 'classnames';

export type TFile = File & {
  preview?: string;
};

type TDropZone = DropzoneOptions & {
  id?: number | string;
  item?: (File | string)[];
  onDropFile: (file: File[]) => void;
  valid?: boolean;
};
const accept = {
  'image/jpeg': [],
  'image/png': [],
  'image/webp': [],
  'image/gif': [],
};

export const acceptedFilesExt = Object.keys(accept)
  .map(key => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, ext] = key.split('/');

    return ext;
  })
  .join(', ');

const DropZone: FC<TDropZone> = ({
  id,
  item,
  disabled,
  valid,
  maxFiles = 1,
  onDropFile,
  ...props
}) => {
  const [files, setFiles] = useState<TFile[]>(
    item && item[0] ? ([{ preview: item[0] }] as TFile[]) : [],
  );

  const onDrop = useCallback(
    acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      onDropFile(acceptedFiles);
    },
    [onDropFile],
  );

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    disabled: disabled,
    maxFiles,
    accept,
    onFileDialogCancel: () => {},
    onDropRejected: () => {},
    onDrop,
    ...props,
  });
  const clearDropZone = () => {
    const input = inputRef.current;
    if (input) {
      input.value = '';
    }

    setFiles([]);
    onDropFile([]);
  };

  return (
    <section className={styles.dropzone} id={String(id)}>
      <header className={styles.header}>
        <h2 className={styles.title}>Изображение {Number(id) + 1}</h2>
        {!!files.length && (
          <button
            className={styles.trashBtn}
            type="button"
            onClick={clearDropZone}
          >
            <Icon name="icon-trash" />
          </button>
        )}
      </header>
      <div
        className={cl(styles.body, {
          [styles.hover]: isDragActive,
          [styles.error]: !valid,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div className={styles.text}>
          <>
            <span className={styles.textDesktop}>
              Перетащите сюда <br /> или нажмите для выбора
            </span>
            <span className={styles.textMobile}>
              Нажмите <br /> для выбора
            </span>
          </>

          <p className={styles.subText}>{acceptedFilesExt}</p>
        </div>
        {!!files.length && (
          <>
            {files.map(file => (
              <div
                key={`${file.name}-${file.size}-${id}`}
                className={styles.imgWrap}
                style={{
                  backgroundImage: `url(${file.preview})`,
                }}
              />
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export { DropZone };
