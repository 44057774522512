import NetworkError, { TNetworkError } from 'shared/exceptions/network';
import { TCoupleAliasKeys } from 'shared/constants/types';

export type TPollsState = {
  polls: {
    response: (TPollResponse & TMeta) | null;
    error: null | NetworkError;
    loading: boolean;
    isDelete?: boolean;
  };
  targeting: {
    questions?: TTargetingQuestionType[];
    loading: boolean;
    error?: TNetworkError;
  };
  filters: {
    response: { data: TFilters } | null;
    error: null | NetworkError;
    loading: boolean;
  };
  newPoll: {
    response: { data: TPoll } | null;
    error: null | NetworkError;
    loading: boolean;
  };
  pollReport: {
    response: {
      data: TPollReport;
    } | null;
    error: null | NetworkError;
    loading: boolean;
  };
  addPoll: {
    added: boolean;
    loading: boolean;
    error?: TNetworkError;
  };
};

export type TPollResponse = {
  data: TPoll[];
};

export type TPollBase = {
  approved?: boolean;
  rejected: string | null;
  id: number;
  range: AgeType[] | null;
  created_at: string;
  draft: boolean;
  education: string[] | null;
  gender: string | null;
  grade: string[] | null;
  respondents: number | null;
  type: ContentTypeValues;
  updated_at: string;
  question: string;
  client_balance?: string;
  poll_process?: boolean;
  share: string;
  target: { [key: string]: string }[] | null;
  target_condition: null | 'and' | 'or';
  target_type: null | 'simple' | 'difficult';
  user_id: number;
  rate?: number;
  favourite?: boolean;
  bitrix_notify_ids?: string[];
};

export type TPoll = TPollBase & {
  images: {
    id: number;
    link: string;
    votes: number;
    answers?: number;
    compressed?: string;
    rate?: number;
  }[];
  question: string;
};

export type TMeta = {
  meta: {
    all_polls_count: number;
    current_page: number;
    from: number;
    last_page: number;
    links: {
      active: boolean;
      label: string;
      url: string | null;
    }[];
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
};

export type TPollReport = TPollBase & {
  images: TImagesReport[];
  type: TCoupleAliasKeys;
  result?: TPollStarImages[];
};

export type TPollStarReport = TPollBase & {
  images: TPollStarImages[];
  type: Extract<TCoupleAliasKeys, 'star'>;
  results: { [key: string]: TResultsStars };
};

export type TImagesBase = {
  details: { [key in AgeType]: TImagesDetails };
  compressed: string;
  unfiltered_details: { [key in AgeType]: TImagesDetails };
  female: number;
  id: number;
  link: string;
  male: number;
  min?: string;
  votes: number;
  answers?: number;
  initialPlace?: number;
};

export type TImagesReport = TImagesBase & {
  images?: TPollImages[];
  results: TImagesResults[];
  star: string;
  rate: number | null;
};

export type TResultsStars = TImagesReport & {
  percent: number | null;
};

export type TPollImages = {
  compressed: string;
  id: number;
  link: string;
  min: string;
  poll_id: number;
  votes: string;
  star: string;
};
export type TPollStarImages = TPollImages & {
  rate: number;
  statistic: { [key: string]: TPollStarImagesStatistic };
  unfiltered_statistic: { [key: string]: TPollStarImagesStatistic };
};

export type TPollStarImagesStatistic = {
  female: number | null;
  male: number | null;
  percent: number | null;
};

export type TImagesDetails = {
  male: number;
  female: number;
};

export type TImagesResults = {
  id: number;
  message: string;
  user: TUser;
  likes: number;
  complaint: boolean;
  first_image?: string;
  second_image?: string;
  third_image?: string;
  fourth_image?: string;
};

export type TUser = {
  age: number;
  grade: string;
  id: number;
  name: string;
  photo: string | null;
};

export type TFilters = {
  [key: string]: {
    id: string;
    name: string;
    params: {
      [key: string]: {
        id: string;
        name: string;
        disabled: boolean;
      };
    };
  };
};

export type TTargetingQuestionType = {
  formulation: string;
  id: number;
  options: string[];
  question_id: null;
  relate_answer: string | null;
};

export const ContentType = {
  Couple: 'couple',
  Question: 'question',
  Range: 'range',
  Tournament: 'Tournament',
  Click: 'Click',
  Star: 'star',
} as const;

export enum AgeType {
  Y18 = '18-24',
  Y25 = '25-34',
  Y35 = '35-44',
  Y45 = '45-54',
  Y55 = '55-64',
  Y65 = '65+',
}

export enum EducationType {
  middle = 'Среднее',
  prof = 'Среднее профессиональное',
  un_high = 'Неоконченное высшее',
  high = 'Высшее',
}

export enum GradeType {
  new = 'Новичок',
  spec = 'Спец',
  profi = 'Профи',
  expert = 'Эксперт',
  grandmaster = 'Грандмастер',
  superstar = 'Суперзвезда',
}

export enum GenderType {
  male = 'Мужчины',
  female = 'Женщины',
  all = 'Мужчины и женщины',
}

export enum FilterType {
  gender = 'Пол',
  age = 'Возраст',
  education = 'Образование',
  grade = 'Грейд',
}

export type ContentTypeValues = typeof ContentType[keyof typeof ContentType];
