// const imgPath = '/img/indexPage/testList';

import { TEST_NAME, TEST_TYPE } from 'shared/constants/types';

export const StartScreenList = [
  {
    id: TEST_TYPE.COUPLE,
    title: <>Тест один из&nbsp;двух</>,
    titleText: TEST_NAME.couple,
    text: (
      <>
        Тест прямого сравнения пары вариантов. Респонденты голосуют за&nbsp;один
        из&nbsp;двух вариантов и&nbsp;пишут объяснение выбора.
      </>
    ),
    link: '/new',
    // img: `${imgPath}/illustration_test_ab.svg`,
    img: (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 240 180"
          width="240px"
          height="180px"
        >
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M169.5,124.5h5c2.76,0,5,2.24,5,5v40c0,2.76-2.24,5-5,5h-5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="179.5"
            y1="149.5"
            x2="219.5"
            y2="149.5"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M169.5,4.5h5c2.76,0,5,2.24,5,5v100c0,2.76-2.24,5-5,5h-5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="179.5"
            y1="59.5"
            x2="219.5"
            y2="59.5"
          />
          <circle cx="219.5" cy="149.5" r="9.5" />
          <circle cx="219.5" cy="59.5" r="19.5" />
          <circle cx="154.5" cy="164.5" r="4.5" />
          <circle cx="154.5" cy="134.5" r="4.5" />
          <circle cx="154.5" cy="104.5" r="4.5" />
          <circle cx="154.5" cy="74.5" r="4.5" />
          <circle cx="154.5" cy="44.5" r="4.5" />
          <circle cx="154.5" cy="14.5" r="4.5" />
          <circle cx="124.5" cy="164.5" r="4.5" />
          <circle cx="124.5" cy="134.5" r="4.5" />
          <circle cx="124.5" cy="104.5" r="4.5" />
          <circle cx="124.5" cy="74.5" r="4.5" />
          <circle cx="124.5" cy="44.5" r="4.5" />
          <circle cx="124.5" cy="14.5" r="4.5" />
          <circle cx="94.5" cy="164.5" r="4.5" />
          <circle cx="94.5" cy="134.5" r="4.5" />
          <circle cx="94.5" cy="104.5" r="4.5" />
          <circle cx="94.5" cy="74.5" r="4.5" />
          <circle cx="94.5" cy="44.5" r="4.5" />
          <circle cx="94.5" cy="14.5" r="4.5" />
          <circle cx="64.5" cy="164.5" r="4.5" />
          <circle cx="64.5" cy="134.5" r="4.5" />
          <circle cx="64.5" cy="104.5" r="4.5" />
          <circle cx="64.5" cy="74.5" r="4.5" />
          <circle cx="64.5" cy="44.5" r="4.5" />
          <circle cx="64.5" cy="14.5" r="4.5" />
          <circle cx="34.5" cy="164.5" r="4.5" />
          <circle cx="34.5" cy="134.5" r="4.5" />
          <circle cx="34.5" cy="104.5" r="4.5" />
          <circle cx="34.5" cy="74.5" r="4.5" />
          <circle cx="34.5" cy="44.5" r="4.5" />
          <circle cx="34.5" cy="14.5" r="4.5" />
          <circle cx="4.5" cy="164.5" r="4.5" />
          <circle cx="4.5" cy="134.5" r="4.5" />
          <circle cx="4.5" cy="104.5" r="4.5" />
          <circle cx="4.5" cy="74.5" r="4.5" />
          <circle cx="4.5" cy="44.5" r="4.5" />
          <circle cx="4.5" cy="14.5" r="4.5" />
        </svg>
      </>
    ),
  },
  {
    id: TEST_TYPE.RANGE,
    title: TEST_NAME.range,
    text: (
      <>
        Тест выявляет приоритетность. Респонденты располагают варианты
        в&nbsp;порядке предпочтения и&nbsp;пишут объяснение выбора.
      </>
    ),
    link: '/new',
    // img: `${imgPath}/illustration_test_order.svg`,
    img: (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 240 180"
          width="240px"
          height="180px"
        >
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M4.5,164.5l40-100l40,50l40-100l40,150h55"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M4.5,114.5l40-100l40,150l40-50l40-50l55,65"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M4.5,64.5l40,100l40-150l40,50l40,50l55-30"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M4.5,14.5l40,100l40-50l40,100l40-150l55,15"
          />
          <circle cx="219.5" cy="164.5" r="4.5" />
          <circle cx="219.5" cy="129.5" r="9.5" />
          <circle cx="219.5" cy="84.5" r="14.5" />
          <circle cx="219.5" cy="29.5" r="19.5" />
          <circle cx="164.5" cy="164.5" r="4.5" />
          <circle cx="164.5" cy="114.5" r="4.5" />
          <circle cx="164.5" cy="64.5" r="4.5" />
          <circle cx="164.5" cy="14.5" r="4.5" />
          <circle cx="124.5" cy="164.5" r="4.5" />
          <circle cx="124.5" cy="114.5" r="4.5" />
          <circle cx="124.5" cy="64.5" r="4.5" />
          <circle cx="124.5" cy="14.5" r="4.5" />
          <circle cx="84.5" cy="164.5" r="4.5" />
          <circle cx="84.5" cy="114.5" r="4.5" />
          <circle cx="84.5" cy="64.5" r="4.5" />
          <circle cx="84.5" cy="14.5" r="4.5" />
          <circle cx="44.5" cy="164.5" r="4.5" />
          <circle cx="44.5" cy="114.5" r="4.5" />
          <circle cx="44.5" cy="64.5" r="4.5" />
          <circle cx="44.5" cy="14.5" r="4.5" />
          <circle cx="4.5" cy="164.5" r="4.5" />
          <circle cx="4.5" cy="114.5" r="4.5" />
          <circle cx="4.5" cy="64.5" r="4.5" />
          <circle cx="4.5" cy="14.5" r="4.5" />
        </svg>
      </>
    ),
  },
  {
    id: TEST_TYPE.STAR,
    title: TEST_NAME.star,
    text: (
      <>
        Тест формирует среднюю оценку варианта. Респонденты оценивают вариант
        до&nbsp;5&nbsp;звезд и&nbsp;пишут объяснение выбора.
      </>
    ),
    link: '/new',
    // img: `${imgPath}/illustration_test_stars.svg`,
    img: (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 240 180"
          width="240px"
          height="180px"
        >
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M214.5,14.5h15c2.76,0,5,2.24,5,5v110c0,2.76-2.24,5-5,5H9.5c-2.76,0-5,2.24-5,5v15c0,2.76,2.24,5,5,5h15"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="214.5"
            y1="114.5"
            x2="234.5"
            y2="114.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="214.5"
            y1="89.5"
            x2="234.5"
            y2="89.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="214.5"
            y1="64.5"
            x2="234.5"
            y2="64.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="214.5"
            y1="39.5"
            x2="234.5"
            y2="39.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="4.5"
            y1="114.5"
            x2="24.5"
            y2="114.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="4.5"
            y1="89.5"
            x2="24.5"
            y2="89.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="4.5"
            y1="64.5"
            x2="24.5"
            y2="64.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="4.5"
            y1="39.5"
            x2="24.5"
            y2="39.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="4.5"
            y1="14.5"
            x2="24.5"
            y2="14.5"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="189.5"
            cy="159.5"
            r="9"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="154.5"
            cy="159.5"
            r="9"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="189.5"
            cy="114.5"
            r="4"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="189.5"
            cy="89.5"
            r="4"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="189.5"
            cy="64.5"
            r="4"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="189.5"
            cy="39.5"
            r="4"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="154.5"
            cy="114.5"
            r="4"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="154.5"
            cy="89.5"
            r="4"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="154.5"
            cy="64.5"
            r="4"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="119.5"
            cy="114.5"
            r="4"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="119.5"
            cy="89.5"
            r="4"
          />
          <circle
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="84.5"
            cy="114.5"
            r="4"
          />
          <circle cx="119.5" cy="159.5" r="9.5" />
          <circle cx="84.5" cy="159.5" r="9.5" />
          <circle cx="49.5" cy="159.5" r="9.5" />
          <circle cx="189.5" cy="14.5" r="4.5" />
          <circle cx="154.5" cy="39.5" r="4.5" />
          <circle cx="154.5" cy="14.5" r="4.5" />
          <circle cx="119.5" cy="64.5" r="4.5" />
          <circle cx="119.5" cy="39.5" r="4.5" />
          <circle cx="119.5" cy="14.5" r="4.5" />
          <circle cx="84.5" cy="89.5" r="4.5" />
          <circle cx="84.5" cy="64.5" r="4.5" />
          <circle cx="84.5" cy="39.5" r="4.5" />
          <circle cx="84.5" cy="14.5" r="4.5" />
          <circle cx="49.5" cy="114.5" r="4.5" />
          <circle cx="49.5" cy="89.5" r="4.5" />
          <circle cx="49.5" cy="64.5" r="4.5" />
          <circle cx="49.5" cy="39.5" r="4.5" />
          <circle cx="49.5" cy="14.5" r="4.5" />
        </svg>
      </>
    ),
  },
  {
    id: TEST_TYPE.QUESTION,
    title: TEST_NAME.question,
    text: (
      <>
        Тест формирует общее отношение к&nbsp;теме вопроса. Респонденты дают
        ответ в&nbsp;свободной форме. Вопрос может быть о&nbsp;чем угодно
      </>
    ),
    link: '/new',
    // img: `${imgPath}/illustration_test_question.svg`,
    img: (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 240 180"
          width="240px"
          height="180px"
        >
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="99.5"
            y1="14.5"
            x2="139.5"
            y2="164.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="94.5"
            y1="44.5"
            x2="144.5"
            y2="134.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="54.5"
            y1="24.5"
            x2="184.5"
            y2="154.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="69.5"
            y1="59.5"
            x2="169.5"
            y2="119.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="4.5"
            y1="59.5"
            x2="234.5"
            y2="119.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="54.5"
            y1="89.5"
            x2="184.5"
            y2="89.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="4.5"
            y1="119.5"
            x2="234.5"
            y2="59.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="69.5"
            y1="119.5"
            x2="169.5"
            y2="59.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="54.5"
            y1="154.5"
            x2="184.5"
            y2="24.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="94.5"
            y1="134.5"
            x2="144.5"
            y2="44.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="99.5"
            y1="164.5"
            x2="139.5"
            y2="14.5"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="119.5"
            y1="139.5"
            x2="119.5"
            y2="39.5"
          />
          <circle cx="69.5" cy="119.5" r="4.5" />
          <circle cx="94.5" cy="134.5" r="4.5" />
          <circle cx="119.5" cy="139.5" r="4.5" />
          <circle cx="144.5" cy="134.5" r="4.5" />
          <circle cx="169.5" cy="119.5" r="4.5" />
          <circle cx="184.5" cy="89.5" r="4.5" />
          <circle cx="169.5" cy="59.5" r="4.5" />
          <circle cx="144.5" cy="44.5" r="4.5" />
          <circle cx="119.5" cy="39.5" r="4.5" />
          <circle cx="94.5" cy="44.5" r="4.5" />
          <circle cx="69.5" cy="59.5" r="4.5" />
          <circle cx="54.5" cy="89.5" r="4.5" />
          <circle cx="4.5" cy="119.5" r="4.5" />
          <circle cx="54.5" cy="154.5" r="4.5" />
          <circle cx="99.5" cy="164.5" r="4.5" />
          <circle cx="139.5" cy="164.5" r="4.5" />
          <circle cx="184.5" cy="154.5" r="4.5" />
          <circle cx="234.5" cy="119.5" r="4.5" />
          <circle cx="234.5" cy="59.5" r="4.5" />
          <circle cx="184.5" cy="24.5" r="4.5" />
          <circle cx="139.5" cy="14.5" r="4.5" />
          <circle cx="99.5" cy="14.5" r="4.5" />
          <circle cx="54.5" cy="24.5" r="4.5" />
          <circle cx="4.5" cy="59.5" r="4.5" />
          <circle cx="119.5" cy="89.5" r="19.5" />
        </svg>
      </>
    ),
  },
  {
    id: 'click',
    title: 'Клик-тест (heatmap)',
    text: (
      <>
        Тест выявляет привлекательные элементы. Фиксируются нажатия респондентов
        по&nbsp;изображению и&nbsp;формируется тепловая карта.
      </>
    ),
    link: '',
    // img: `${imgPath}/illustration_test_heatmap.svg`,
    img: (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 240 180"
          width="240px"
          height="180px"
        >
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M233.34,120.67c-0.88-9.87-4.9-18.6-10.94-25.38c-5.3-5.95-8.54-13.42-9.6-21.32c-0.43-3.21-1.06-6.42-1.91-9.6c-1.99-7.54-1.7-15.52,1.23-22.75c1-2.47,1.49-5.2,1.36-8.08c-0.41-8.95-7.28-16.51-16.15-17.83c-2.18-0.32-4.32-0.27-6.35,0.12c-7.96,1.51-16.22,0.22-23.59-3.15c-21.72-9.93-47.67-9.94-70.33,2.25C81.31,23.41,69.6,36.46,62.69,51.57c-2.76,6.04-7.61,10.85-13.51,13.91c-7.89,4.09-14.61,10.6-18.93,19.13c-0.54,1.07-1.04,2.16-1.49,3.26c-2.64,6.45-7.43,11.66-13.52,15.04c-3.86,2.14-7.01,5.65-8.65,10.24c-3.01,8.39,0.38,17.94,8,22.56c4.02,2.44,8.49,3.21,12.7,2.58c6.83-1.03,13.82,0.53,19.76,4.05c0.83,0.49,1.69,0.96,2.57,1.41c8.04,4.08,16.75,5.44,25.07,4.44c6.71-0.81,13.45,0.64,19.26,4.11c16.85,10.06,37.15,13.65,56.76,9.53c6.56-1.38,13.43-0.76,19.45,2.19c8.64,4.24,18.76,5.7,29.04,3.4C219.52,162.88,235.19,141.41,233.34,120.67z"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M53.5,34.5c0,10.49-8.51,19-19,19s-19-8.51-19-19s8.51-19,19-19S53.5,24.01,53.5,34.5z"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M218.37,121.73c-0.79-8.14-4.83-15.1-10.67-19.81c-7.98-6.42-13.35-15.54-14.77-25.69c-1.27-9.02-4.65-17.9-10.32-25.9c-17.32-24.43-50.74-32-76.86-17.35c-10.59,5.94-18.58,14.55-23.67,24.47c-4.74,9.22-12.74,16.27-22.49,19.81c-7.02,2.55-13.08,7.81-16.47,15.22c-6.17,13.48-0.97,29.74,11.87,37.14c6.83,3.94,14.51,4.78,21.51,3.03c10.07-2.51,20.63-1.31,29.75,3.65c11.65,6.34,25.21,8.62,38.37,6.33c10.16-1.76,20.47,0.59,29.12,6.2c6.04,3.93,13.57,5.63,21.31,4.14C209.59,150.16,219.8,136.49,218.37,121.73z"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M173.5,84.5c0,21.54-17.46,39-39,39s-39-17.46-39-39s17.46-39,39-39S173.5,62.96,173.5,84.5z"
          />
          <circle cx="234.5" cy="79.5" r="4.5" />
          <circle cx="64.5" cy="14.5" r="4.5" />
          <circle cx="4.5" cy="89.5" r="4.5" />
          <circle cx="84.5" cy="164.5" r="4.5" />
          <circle cx="24.5" cy="119.5" r="9.5" />
          <circle cx="34.5" cy="34.5" r="9.5" />
          <circle cx="194.5" cy="34.5" r="9.5" />
          <circle cx="69.5" cy="104.5" r="14.5" />
          <circle cx="189.5" cy="124.5" r="14.5" />
          <circle cx="134.5" cy="84.5" r="19.5" />
        </svg>
      </>
    ),
  },
  {
    id: 'tournament',
    title: (
      <>
        Турнирный <br /> тест
      </>
    ),
    titleText: 'Турнирный тест',
    text: (
      <>
        Тест для сравнения нескольких вариантов по&nbsp;турнирной сетке.
        Респонденты оценивают варианты парами, результаты суммируются.
      </>
    ),
    link: '',
    // img: `${imgPath}/illustration_test_tournament.svg`,
    img: (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 240 180"
          width="240px"
          height="180px"
        >
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M4.5,144.5h10c2.76,0,5,2.24,5,5v10c0,2.76-2.24,5-5,5h-10"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M4.5,104.5h10c2.76,0,5,2.24,5,5v10c0,2.76-2.24,5-5,5h-10"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M4.5,54.5h10c2.76,0,5,2.24,5,5v10c0,2.76-2.24,5-5,5h-10"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M4.5,14.5h10c2.76,0,5,2.24,5,5v10c0,2.76-2.24,5-5,5h-10"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M19.5,114.5h55c2.76,0,5,2.24,5,5v30c0,2.76-2.24,5-5,5h-55"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M19.5,24.5h55c2.76,0,5,2.24,5,5v30c0,2.76-2.24,5-5,5h-55"
          />
          <path
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M79.5,44.5h75c2.76,0,5,2.24,5,5v80c0,2.76-2.24,5-5,5h-75"
          />
          <line
            fill="none"
            opacity="0.15"
            stroke="#000000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="159.5"
            y1="89.5"
            x2="219.5"
            y2="89.5"
          />
          <circle cx="219.5" cy="89.5" r="19.5" />
          <circle cx="119.5" cy="134.5" r="14.5" />
          <circle cx="119.5" cy="44.5" r="14.5" />
          <circle cx="49.5" cy="154.5" r="9.5" />
          <circle cx="49.5" cy="114.5" r="9.5" />
          <circle cx="49.5" cy="64.5" r="9.5" />
          <circle cx="49.5" cy="24.5" r="9.5" />
          <circle cx="4.5" cy="164.5" r="4.5" />
          <circle cx="4.5" cy="144.5" r="4.5" />
          <circle cx="4.5" cy="124.5" r="4.5" />
          <circle cx="4.5" cy="104.5" r="4.5" />
          <circle cx="4.5" cy="74.5" r="4.5" />
          <circle cx="4.5" cy="54.5" r="4.5" />
          <circle cx="4.5" cy="34.5" r="4.5" />
          <circle cx="4.5" cy="14.5" r="4.5" />
        </svg>
      </>
    ),
  },
];
