import { HTMLAttributes, useMemo } from 'react';
import styles from './index.module.scss';
import cl from 'classnames';
import { ProgressLine } from '../progressLine';
import { ContentType } from 'pages/cabinet/store/types';

type TProgressImage = HTMLAttributes<HTMLElement> & {
  data: {
    link: string;
    votes: number;
    compressed?: string;
    answers?: number;
  };
  classNames?: {
    img?: string;
    progress?: string;
  };
  type?: 'default' | 'video';
  testType: string;
  respondents?: number;
  isIndex?: boolean;
};

export const ProgressImage = ({
  data,
  classNames = {},
  type = 'default',
  testType,
  respondents = 1,
  isIndex,
}: TProgressImage) => {
  const { votes = 0, link, compressed, answers = 0 } = data;
  const progressTitle = useMemo(() => {
    if (testType === ContentType.Couple) {
      return `${votes || 0}%`;
    }
    if (testType === ContentType.Range) {
      return `${answers || 0}`;
    }
    return '';
  }, [answers, testType, votes]);

  const progressCount = useMemo(() => {
    if (testType === ContentType.Couple) {
      return votes || 0;
    }
    if (testType === ContentType.Range) {
      return ((answers || 0) / respondents) * 100;
    }
    return 0;
  }, [answers, respondents, testType, votes]);
  return (
    <div
      className={cl(styles.wrap, {
        [styles.index]: isIndex === true,
      })}
    >
      <div
        className={cl(styles.imgWrap, classNames.img, {
          [styles.video]: type === 'video',
          [styles.index]: isIndex === true,
        })}
      >
        <img src={compressed || link} alt="" />
      </div>

      <div className={styles.progressWrap}>
        <ProgressLine
          direction={'ltr'}
          width={progressCount || 0}
          className={cl(classNames.progress, styles.progress)}
        />
        <strong className={styles.progressCount}>{progressTitle}</strong>
      </div>
    </div>
  );
};
