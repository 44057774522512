import Network, { TAxios } from 'shared/libraries/network';
import CONFIG from 'shared/constants/config';
import { TSupportMessageData } from 'features/ContactSupport/model/types/userSupportSchema';
import { TSendMessageToRespondentsRequest } from 'features/SendMessageToRespondents';

class SupportModel extends Network {
  constructor(baseURL: string, timeout = 120000) {
    super(baseURL, timeout);
  }

  //GET
  //Получение списка клиентов
  async getClinetsList(): Promise<TAxios> {
    return this.send('get', `/supports/clients`);
  }

  //Получение списка промокодов
  async getPromocodes(data: { query?: string }): Promise<TAxios> {
    return this.send('get', `/supports/promocodes`, { ...data });
  }

  //POST
  //Получить лоступ к ЛК пользователя
  async getAccess(id: number): Promise<TAxios> {
    return this.send('post', `/supports/access`, { id });
  }

  // Отправить сообщение в тех.поддержку
  async postUserSupport(data: TSupportMessageData): Promise<TAxios> {
    return this.send('post', `/supports/message`, { ...data });
  }

  // Отправить сообщение респондентам
  async postMessageToRespondents(
    data: TSendMessageToRespondentsRequest,
  ): Promise<TAxios> {
    return this.send('post', `/supports/message-to-respondents`, { ...data });
  }
}

const baseUrl = CONFIG.REACT_APP_API_BASE_URL as string;
const supportModel = new SupportModel(baseUrl);

export default supportModel;
