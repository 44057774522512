import { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { FieldSet } from 'shared/components/grid/field';
import Select from 'react-select';
import { singleSelectStyles } from 'app/styles/select';
import Button from 'shared/components/button';
import cls from './CompliantForm.module.scss';
import { useSelector } from 'react-redux';
import { TextArea } from 'shared/components/textarea';
import { Reason } from '../../model/types/compliantSchema';
import { CompliantActions } from '../../model/slice/compliantSlice';
import { getReason } from 'features/ComplaintToComment/model/selectors/getCompliantReason/getCompliantReason';
import { getComment } from 'features/ComplaintToComment/model/selectors/getCompliantComment/getCompliantComment';
import { getResultId } from 'features/ComplaintToComment/model/selectors/getCompliantsResultId/getCompliantsResultId';
import { fetchCompliants } from 'features/ComplaintToComment/model/services/postCompliant';
import { getCompliantResponse } from 'features/ComplaintToComment/model/selectors/getCompliantResponse/getCompliantResponse';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { ModalFooter, ModalTitle } from 'shared/components/modal/modal';
import { getImageId } from 'features/ComplaintToComment/model/selectors/getCompiantImageId/getCompliantImageId';

export type CompliantFormProps = {
  onSuccess: () => void;
};

export const optionsReason = [
  { value: Reason.NONSENSE, label: 'Несодержательный ответ' },
  { value: Reason.PROFANITY, label: 'Ненормативная лексика' },
  { value: Reason.OTHER, label: 'Другое' },
];

const CompliantForm = memo((props: CompliantFormProps) => {
  const { onSuccess } = props;
  const dispatch = useAppDispatch();

  const reason = useSelector(getReason);
  const comment = useSelector(getComment);
  const result_id = useSelector(getResultId);
  const response = useSelector(getCompliantResponse);
  const image_id = useSelector(getImageId);

  const onChangeReason = useCallback(
    (value: { value: Reason; label: string }) => {
      dispatch(CompliantActions.setReason(value.value));
    },
    [dispatch],
  );

  const onChangeComment = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(CompliantActions.setComment(event.target.value));
    },
    [dispatch],
  );

  const onFetchCompliant = useCallback(() => {
    if (result_id && reason)
      dispatch(
        fetchCompliants({
          result_id,
          reason,
          comment: comment || undefined,
          image_id: image_id || undefined,
        }),
      );
  }, [comment, dispatch, image_id, reason, result_id]);

  const onCloseModal = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  const SuccessContent = useMemo(() => {
    return (
      <>
        <ModalTitle>Жалоба отправлена</ModalTitle>
        <Button
          withIcon={false}
          className={cls.supButton}
          onClick={onCloseModal}
          as="button"
        >
          Закрыть
        </Button>
      </>
    );
  }, [onCloseModal]);

  return (
    <>
      {response.response === 'success' ? (
        SuccessContent
      ) : (
        <>
          <FieldSet labelName="Причина жалобы">
            <Select
              styles={singleSelectStyles()}
              options={optionsReason}
              placeholder=""
              inputId="compliant"
              value={
                optionsReason.find(options => options.value === reason) || null
              }
              onChange={onChangeReason}
            />
          </FieldSet>
          <FieldSet labelName="Комментарий к жалобе">
            <TextArea
              value={comment}
              maxLength={200}
              onChange={onChangeComment}
            />
          </FieldSet>
          {!!response.error && (
            <p className={cls.error}>{response.error?.message}</p>
          )}

          <ModalFooter>
            <Button
              className={cls.supButton}
              onClick={onFetchCompliant}
              disabled={!reason || response.isLoading || !!response.error}
              as="button"
              withIcon={false}
            >
              Отправить жалобу
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  );
});

export default CompliantForm;
