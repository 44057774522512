import { FC, PropsWithChildren } from 'react';
import Label from '../label';
import styles from './index.module.scss';
import cl from 'classnames';

type TFieldSet = {
  labelName?: string;
  labelFor?: string;
  subText?: string;
  subError?: string;
  className?: string;
  bordered?: boolean;
};

const FieldSet: FC<PropsWithChildren & TFieldSet> = ({
  children,
  labelName,
  labelFor,
  bordered,
  subText,
  subError,
  className,
}) => {
  return (
    <fieldset
      className={cl(className, styles.fieldset, {
        [styles.bordered]: bordered,
      })}
    >
      {labelName && <Label htmlFor={labelFor}>{labelName}</Label>}
      {subText && <p className={styles.subText}>{subText}</p>}
      {subError && <p className={styles.subError}>{subError}</p>}
      {children}
    </fieldset>
  );
};

const enum RowType {
  Row = 'Row',
  Column = 'Column',
}

type TFieldSetRow = {
  labelName?: string;
  labelFor?: string;
  direction?: keyof typeof RowType;
  duo?: boolean;
  className?: string;
};

const FieldRow: FC<PropsWithChildren & TFieldSetRow> = ({
  children,
  direction = RowType.Row,
  duo = false,
  className,
}) => {
  return (
    <div
      className={cl(styles.row, className, {
        [styles.rowColumn]: direction === RowType.Column,
        [styles.duo]: duo === true,
      })}
    >
      {children}
    </div>
  );
};

type TFieldList = {
  className?: string;
};

const FieldList: FC<PropsWithChildren & TFieldList> = ({
  children,
  className,
}) => {
  return <div className={cl(styles.list, className)}>{children}</div>;
};

export { FieldRow, FieldSet, FieldList };
