import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  TTransactionsRequestData,
  TTransactionsResponse,
} from '../types/balanceSchema';
import balanceModel from 'store/models/balance';

export const fetchClientTransactions = createAsyncThunk(
  'balance/fetchClientTransactions',
  async (data: TTransactionsRequestData, thunkApi) => {
    try {
      const response = (await balanceModel.getClientTransactions(
        data,
      )) as unknown as TTransactionsResponse;
      if (!response.data) {
        throw new Error();
      }
      return response;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);
