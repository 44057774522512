import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { PageTitle } from 'shared/components/pageTitle/index';
import style from '../index.module.scss';

import {
  getInitBalancePage,
  getIsEmptyBalancsPage,
} from '../../../model/selectors/getBalance';
import { BalanceForm } from '../balanceForm';
import { PaymentsList } from '../paymentsList';
import { getUserBalance } from 'store/selectors/user';
import { Price } from 'shared/helpers/utils/price';

export const PaymentsBalanceBlock: FC = memo(() => {
  const isEmptyPage = useSelector(getIsEmptyBalancsPage);
  const isInitPage = useSelector(getInitBalancePage);
  const clientBalance = useSelector(getUserBalance);

  if (!isEmptyPage && isInitPage) {
    return (
      <>
        <PageTitle className={style.title}>
          Баланс:&#160;
          {Price.getString(clientBalance)}
        </PageTitle>
        <BalanceForm withIndent title="Пополнить баланс" />
        <PaymentsList />
      </>
    );
  }
  return null;
});
