import { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { Form } from './components/form';
import Popup, { PopupNoCloseClass } from 'shared/components/popup';
import popupStyles from 'shared/components/popup/index.module.scss';
import styles from './index.module.scss';
import cl from 'classnames';
import { Position, PositionValues } from 'app/styles/types';
import { ChangeBtnsId } from './components/form/smsForm';
import { useSelector } from 'react-redux';
import {
  getPopupIsOpen,
  getFormType,
  getPopupType,
  getCurrentBtnId,
} from '../../model/selectors/getAuthBlock';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { authBlockActions } from '../../model/slices/authBlockSlice';
import { AuthButtonsComponent } from './components/buttons';
import Button from 'shared/components/button';
import { Icon } from 'shared/components/icon';
import { sendMetrik } from 'shared/helpers/sendMetrik/yandexMetrik';

export const AuthBlockBtnsId = {
  Login: 'login-btn',
  Reg: 'reg-btn',
} as const;

export type TAuthBlockType = 'header' | 'landing';

type TAuthBlock = {
  position?: PositionValues;
  Buttons?: ReactNode;
  type: TAuthBlockType;
  btnId?: string;
  onCreate?: (btnId: string) => void;
  onHowButton?: () => void;
  howBtn?: boolean;
};

export const AuthBlock: FC<TAuthBlock> = ({
  position,
  onCreate = () => {},
  onHowButton,
  type,
  btnId = '',
  howBtn,
}) => {
  const isDesktop = position === Position.Desktop;
  const isMobile = position === Position.Mobile;
  const dispatch = useAppDispatch();
  const showPopup = useSelector(getPopupIsOpen);
  const formType = useSelector(getFormType);
  const popupType = useSelector(getPopupType);
  const currentBtnId = useSelector(getCurrentBtnId);
  const isLanding = type === 'landing';
  const isShow =
    showPopup &&
    popupType === type &&
    (isLanding ? btnId === currentBtnId : true);

  const ref = useRef<HTMLDivElement>(null);
  const [popupPosition, setPopupPosition] = useState<'bottom' | 'top'>(
    'bottom',
  );
  const changePopupPosition = () => {
    if (ref.current) {
      const popupHeight = 270;
      const { top, height } = ref.current.getBoundingClientRect();

      if (top + height + popupHeight > window.innerHeight) {
        setPopupPosition('bottom');
      } else {
        setPopupPosition('top');
      }
    }
  };

  const onCreateTest = useCallback(
    evt => {
      sendMetrik('reachGoal', 'push_button_create_test');
      changePopupPosition();
      onCreate(btnId);
      evt.currentTarget.blur();
    },
    [btnId, onCreate],
  );

  const onClosePopup = useCallback(() => {
    dispatch(authBlockActions.setCloseAuthPopup());
    dispatch(authBlockActions.setFormType(null));
  }, [dispatch]);

  return (
    <>
      <div
        className={cl(styles.authBlock, {
          [styles.desktop]: isDesktop,
          [styles.mobile]: isMobile,
          [styles.header]: type === 'header',
          [styles.landing]: type === 'landing',
        })}
      >
        {isLanding ? (
          <div className={styles.btns} ref={ref}>
            <Button
              as="button"
              onClick={onCreateTest}
              className={PopupNoCloseClass}
            >
              <Icon name="icon-plus" />
              Создать тест
            </Button>
            {howBtn && (
              <Button onClick={onHowButton} transparent as="button">
                Как это работает
              </Button>
            )}
          </div>
        ) : (
          <AuthButtonsComponent position="Desktop" />
        )}
        <Popup
          closeIds={[AuthBlockBtnsId.Login, AuthBlockBtnsId.Reg, ChangeBtnsId]}
          type="auth"
          parentType={type}
          isShow={isShow}
          closeHandler={onClosePopup}
          className={cl({
            [popupStyles.top]: popupPosition === 'top',
            [popupStyles.bottom]: popupPosition === 'bottom',
          })}
        >
          <Form formType={formType} isShow />
        </Popup>
      </div>
    </>
  );
};
