import { FC, HTMLAttributes } from 'react';
import { Icon } from '../icon';
import styles from './index.module.scss';
import cl from 'classnames';
import getFineNumber from 'shared/helpers/utils/getFineNumber';

type TRating = HTMLAttributes<HTMLElement> & {
  rating: number | string;
  type?: 'min' | 'big';
  withValue?: boolean;
};

export const Rating: FC<TRating> = ({
  rating,
  type = 'min',
  className,
  withValue = false,
}) => {
  return (
    <div className={cl(styles.rating, className)}>
      <div
        className={cl(styles.wrap, {
          [styles.min]: type === 'min',
          [styles.big]: type === 'big',
        })}
      >
        {Array.from({ length: 5 }, (_, idx) => {
          const currentStarNumber = idx + 1;

          return (
            <Icon
              className={cl(styles.star, styles['star-' + currentStarNumber], {
                [styles.active]: currentStarNumber <= Number(rating),
              })}
              name="icon-indicator-star"
              key={String(idx)}
              {...(type === 'big' ? { width: 32, height: 32 } : {})}
            />
          );
        })}
      </div>
      {withValue && (
        <strong className={styles.ratingText}>
          {getFineNumber(rating, 1)}
        </strong>
      )}
    </div>
  );
};
