import axios, { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { AUTH_TOKEN } from 'shared/constants/commmon';
import NetworkError, { TNetworkInsideErrors } from 'shared/exceptions/network';
import { getAuthUserData } from 'shared/helpers/auth';

export type TAxios = Promise<AxiosResponse>;

class Network {
  instance: AxiosInstance;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: Map<string, any>;

  constructor(baseURL: string, timeout: number) {
    this.instance = axios.create({
      baseURL,
      timeout,
    });

    this.methods = new Map([
      ['post', this.instance.post],
      ['get', this.instance.get],
      ['put', this.instance.put],
      ['patch', this.instance.patch],
      ['delete', this.instance.delete],
    ]);
  }

  public setToken(token: string | null): Network {
    this.instance.defaults.headers.Authorization = `Bearer ${token}`;
    return this;
  }

  public setSellerId(sellerId: string | null): Network {
    this.instance.defaults.headers.SellerId = sellerId;

    return this;
  }

  public async send<T>(
    requestType: string,
    url: string,
    payload: unknown = {},
    headers: unknown = {},
  ): Promise<T> {
    const token = getAuthUserData();

    if (token) {
      this.setToken(token);
    } else {
      this.setToken(null);
    }

    try {
      const method = this.methods.get(requestType);
      const { data } = await method(url, payload, headers);
      return data;
    } catch (error) {
      const { response, message: errorMessage } = error as AxiosError<
        { message: string; errors: TNetworkInsideErrors | null },
        string
      >;
      if (response) {
        const {
          data: { message, errors },
          status,
        } = response;
        if (
          status === 401 &&
          message &&
          message !== 'The given data was invalid.'
        ) {
          localStorage.removeItem(AUTH_TOKEN);
          // eslint-disable-next-line no-alert
          document.location.reload();
        }

        throw new NetworkError(message, status, errors as null | undefined);
      } else {
        throw new NetworkError(errorMessage);
      }
    }
  }

  public async sendFile(
    requestType: string,
    url: string,
    payload: unknown = {},
    headers: unknown = {},
  ): Promise<TAxios> {
    const token = getAuthUserData();

    if (token) {
      this.setToken(token);
    } else {
      this.setToken(null);
    }
    try {
      const method = this.methods.get(requestType);
      const response = await method(url, payload, headers);
      return response;
    } catch (error) {
      const { response, message: errorMessage } = error as AxiosError<
        { message: string; errors: TNetworkInsideErrors | null },
        string
      >;
      if (response) {
        const {
          data: { message, errors },
          status,
        } = response;
        throw new NetworkError(message, status, errors as null | undefined);
      } else {
        throw new NetworkError(errorMessage);
      }
    }
  }
}

export default Network;
