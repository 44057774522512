import React, { InputHTMLAttributes } from 'react';
import '../../index.scss';
import cl from 'classnames';
import { TCheckboxBoxInput } from '../../index';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  withCursor?: boolean;
} & TCheckboxBoxInput;

export const Checkbox = ({
  className = '',
  id,
  value,
  name,
  title,
  withCursor = true,
  hide,
  ...props
}: CheckboxProps) => {
  return (
    <input
      type="checkbox"
      className={cl(className, { noEvents: !withCursor })}
      id={id}
      value={value}
      name={name}
      {...props}
    />
  );
};
