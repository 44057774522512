import { FC, ReactNode, memo } from 'react';
import { PageTitle } from 'shared/components/pageTitle';
import style from './index.module.scss';
import { PageDescription } from 'shared/components/pageDescription';
import Button from 'shared/components/button';

type TErrorPage = {
  errorCode: number | string;
  errorText?: string;
  errorDescription?: ReactNode | string;
  main?: boolean;
};

const ErrorPage: FC<TErrorPage> = memo(
  ({ errorCode, errorDescription, errorText, main = false }) => {
    return (
      <>
        <PageTitle className={style.title}>
          Ошибка {errorCode}&nbsp;— <br />
          {errorText}
        </PageTitle>
        {errorDescription && (
          <PageDescription className={style.descr}>
            {errorDescription}
          </PageDescription>
        )}
        {!main && (
          <Button as="link" href="/">
            Вернуться на главную
          </Button>
        )}
      </>
    );
  },
);

export default ErrorPage;
