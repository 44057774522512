import React, { FC, useEffect } from 'react';
import withProviders from './providers';
import Routing from '../pages';

import './styles/index.scss';
import { initializeAuthUser } from 'pages/user/store/store';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { ContactSupportModal } from 'features/ContactSupport';

const App: FC = () => {
  const dispatch = useAppDispatch();
  dispatch(initializeAuthUser());

  useEffect(() => {
    window.addEventListener('error', e => {
      // prompt user to confirm refresh
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload();
      }
    });
  }, []);

  return (
    <>
      <Routing />
      <ContactSupportModal />
    </>
  );
};

const WrapProviders: FC = () => {
  return (
    <>
      <App />
    </>
  );
};

export default withProviders(WrapProviders);
