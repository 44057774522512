import { FC, PropsWithChildren, createElement } from 'react';
import style from './Title.module.scss';
import cl from 'classnames';

type TTitle = PropsWithChildren<{
  className?: string;
  middle?: boolean;
  type?: 'h1' | 'h2' | 'h3';
}>;

const Title: FC<TTitle> = ({ children, className, middle, type = 'h1' }) => {
  return (
    <>
      {createElement(type, {
        className: cl(style.title, className, {
          [style.h1]: type === 'h1',
          [style.h2]: type === 'h2',
          [style.h3]: type === 'h3',
          [style.middle]: middle,
        }),
        children,
      })}
    </>
  );
};

export { Title };
