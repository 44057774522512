import { FC, InputHTMLAttributes } from 'react';
import styles from './index.module.scss';
import cl from 'classnames';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  recommended?: boolean;
  error?: boolean;
};
const Input: FC<InputProps> = ({
  type = 'text',
  children,
  recommended,
  error,
  value,
  onChange,
  className = '',
  ...props
}) => {
  return (
    <input
      value={value}
      onChange={onChange}
      className={cl(
        styles.input,
        className,
        {
          [styles['input--recommended']]: recommended,
        },
        {
          [styles['input--error']]: error,
        },
      )}
      type={type}
      {...props}
    />
  );
};

export default Input;
