import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { profileFormSchema } from '../types/ProfileFormSchema';
import { TUser } from 'pages/user/store/types';

const DEFAULT_DATA: TUser = {
  email: '',
  name: '',
  phone: '',
  surname: '',
  birth: '',
  patronymic: '',
  email_notification: false,
  sms_notification: false,
  referral_balance: '0',
};
const initialState: profileFormSchema = {
  initialData: DEFAULT_DATA,
  changedData: DEFAULT_DATA,
  isOpenSms: false,
};

export const ProfileFormSlice = createSlice({
  name: 'profileForm',
  initialState,
  reducers: {
    setInitialData(state, action: PayloadAction<TUser>) {
      state.initialData = action.payload;
    },
    setChangedData(state, action: PayloadAction<TUser>) {
      state.changedData = action.payload;
    },
    setChangedDataValue(
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) {
      const { name, value } = action.payload;
      state.changedData[name] = value;
    },
    setIsOpenSms(state, action: PayloadAction<boolean>) {
      state.isOpenSms = action.payload;
    },
  },
});

export const { actions: ProfileFormActions } = ProfileFormSlice;
export const { reducer: ProfileFormReducer } = ProfileFormSlice;
