import { LabelHTMLAttributes } from 'react';
import styles from './index.module.scss';
import cl from 'classnames';

type TLabelProps = LabelHTMLAttributes<HTMLLabelElement>;

const Label = ({ children, className, ...props }: TLabelProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label className={cl(styles.label, className)} {...props}>
      {children}
    </label>
  );
};

export default Label;
