/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FC } from 'react';
import { Icon } from 'shared/components/icon';
import {
  InputProps,
  MenuListProps,
  OptionProps,
  components,
} from 'react-select';
import { PopupNoCloseClass } from 'shared/components/popup';
import mainStyles from './index.module.scss';
import cl from 'classnames';
import { bottom } from '@popperjs/core';

type TStyle = {
  [key: string]: string | number | { [key: string]: string | number };
};

const Colors = {
  Black: '#000',
  White: '#fff',
  Active: '#000',
  Gray: '#d9d9d9',
  Red: '#ff0000',
  Transparent: 'transparent',
} as const;

const getCheckIcon = ({
  isFocused = false,
  position = ':before',
} = {}): TStyle => {
  return {
    alignItems: 'center',
    display: 'flex',

    [position]: {
      content: '" "',
      display: 'block',
      position: 'absolute',
      top: 5,
      left: 5,
      width: 20,
      height: 20,
      marginRight: '10px',
      flexShrink: 0,
      maskImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20px" height="20px"><path d="M15.707,6.293c-0.391-0.391-1.023-0.391-1.414,0L9,11.586L6.707,9.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l3,3c0.391,0.391,1.023,0.391,1.414,0l6-6C16.098,7.317,16.098,6.684,15.707,6.293z"/></svg>')`,
      maskSize: 'auto 20px',
      maskRepeat: 'no-repeat',
      backgroundColor: isFocused ? Colors.White : Colors.Black,
    },
  };
};

type TSelectStyle = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (style: TStyle, state: any) => TStyle;
};

const singleSelectStyles = (arg?: number): TSelectStyle => ({
  menu: (styles, state) => {
    const trigger = !!arg && arg < 340 && state.options.length > 3;
    return {
      ...styles,
      color: state.selectProps.menuColor,
      padding: 5,
      margin: 0,
      boxShadow: '0px 20px 40px 0px rgba(0,0,0,0.15)',
      zIndex: 2,
      top: trigger ? 'auto' : '100%',
      bottom: !trigger ? 'auto' : '100%',
    };
  },
  menuList: styles => {
    return {
      ...styles,
      padding: 0,
    };
  },
  option: (styles, state) => {
    const { isSelected, isFocused } = state;
    const customIcon = isSelected ? getCheckIcon({ isFocused }) : {};

    return {
      ...styles,
      ...customIcon,
      'cursor': isSelected ? 'default' : 'pointer',
      'color': isFocused ? Colors.White : Colors.Black,
      'position': 'relative',
      'paddingTop': 5,
      'paddingBottom': 5,
      'paddingLeft': '30px',
      'backgroundColor': isFocused ? Colors.Active : 'transparent',
      ':active': {
        ...(styles[':active'] as TStyle),
        backgroundColor: Colors.Active,
        color: Colors.White,
      },
      ':hover': {
        ...(styles[':hover'] as TStyle),
        backgroundColor: Colors.Active,
        color: Colors.White,
        fill: Colors.White,
      },
      'transition': 'all .1s',
    };
  },
  loadingIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer(styles) {
    return {
      ...styles,
      color: 'inherit',
    };
  },
  dropdownIndicator: (styles, state) => {
    const {
      isDisabled,
      selectProps: { menuIsOpen, required: isRequired },
      hasValue,
    } = state;

    let indicatorColor;

    if (isDisabled) {
      indicatorColor = 'rgba(0, 0, 0, 0.5)';
    } else if (menuIsOpen || (isRequired && !hasValue)) {
      indicatorColor = Colors.Black;
    } else {
      indicatorColor = Colors.Black;
    }
    return {
      ...styles,
      'visibility': isDisabled ? 'hidden' : 'visible',
      'width': '20px',
      'padding': 0,
      'transform': menuIsOpen ? 'rotate(180deg)' : '',
      'transition': 'transform .3s',
      'fill': indicatorColor,
      'color': indicatorColor,
      ':hover': {
        color: Colors.Black,
      },
    };
  },
  placeholder: styles => ({
    ...styles,
    margin: 0,
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '0 11px',
  }),
  control: (styles, state) => {
    const { selectProps, hasValue, isDisabled, isFocused } = state;
    const isError = selectProps.error;
    const isRecommend = selectProps.recommend;
    const isRequired = selectProps.required;

    let backgroundColor = '';
    if (hasValue && !isDisabled) {
      backgroundColor = Colors.White;
    } else if (isDisabled) {
      backgroundColor = '#e5e5e5';
    } else if (isRecommend) {
      backgroundColor = 'rgba(255, 191, 0, 0.1)';
    } else if (isRequired && !hasValue) {
      // backgroundColor = 'rgba(255, 0, 42, 0.1)';
    }
    let borderColor = '';
    if (isDisabled) {
      borderColor = '1px solid rgba(0,0,0,0.09)';
    } else if (isFocused) {
      borderColor = Colors.Black;
    } else if (isRequired && !hasValue) {
      borderColor = Colors.Red;
    } else if (isError) {
      borderColor = Colors.Red;
    } else {
      borderColor = Colors.Gray;
    }
    let boxShadow = '';

    if (isDisabled) {
      boxShadow = '0 0 0 3px #e5e5e5 inset';
    } else if (isFocused) {
      boxShadow = `0 0 0 1px ${Colors.Black} inset`;
    } else {
      boxShadow = `0 0 0 1px ${Colors.Transparent} inset`;
    }
    return {
      ...styles,
      backgroundColor,
      'height': 40,
      'boxShadow': boxShadow,
      'borderWidth': '1px',
      'borderStyle': 'solid',
      'borderColor': borderColor,
      'borderRadius': '5px',
      'paddingRight': 14,
      'transition': 'all .3s ease',
      'cursor': 'pointer',
      'maxWidth': '620px',
      'color': isFocused ? Colors.Black : Colors.Gray,
      ':hover': {
        boxShadow: `0 0 0 1px ${Colors.Black} inset`,
        borderColor: Colors.Black,
        color: Colors.Black,
      },
    };
  },
  singleValue: (styles, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...styles, opacity, transition };
  },
});

const singleSelectWithoutCheck = () => ({
  ...singleSelectStyles(),
  dropdownIndicator: () => ({ display: 'none' }),
  option: (styles, state) => {
    return {
      ...singleSelectStyles().option(styles, state),
      'paddingLeft': 10,
      ':before': {
        content: 'none',
      },
    };
  },
});

const DropdownIndicator = childProps => {
  const { children } = childProps;
  return (
    <components.DropdownIndicator {...childProps}>
      {children}
      <Icon className="select__drop-icon" name="icon-drop" size={20} />
    </components.DropdownIndicator>
  );
};

const LoaderBlock = () => {
  return <div className={mainStyles.loaderText}>Загрузка</div>;
};

type TLoaderMessage = JSX.Element | FC;
const LoadingMessage = (LoaderComponent: TLoaderMessage = <LoaderBlock />) => {
  return childProps => {
    return (
      <components.LoadingMessage {...childProps}>
        {LoaderComponent}
      </components.LoadingMessage>
    );
  };
};

const OptionIcon: FC = (props: OptionProps & { data: { label: string } }) => {
  const {
    data: { label },
  } = props;

  return (
    <components.Option {...props}>
      <i className={`select__icon ${label}`} />
    </components.Option>
  );
};

const OptionDaDataBalance: FC = (
  props: OptionProps & { data: { label: string } },
) => {
  const {
    data: { label },
  } = props;
  const [value, inn, address] = label.split('$');

  return (
    <components.Option
      {...props}
      className={cl(PopupNoCloseClass, mainStyles.selectData)}
    >
      <div className={cl(PopupNoCloseClass, mainStyles.title)}>{value}</div>
      <div className={cl(PopupNoCloseClass, mainStyles.subtitle)}>{inn}</div>
      <div className={cl(PopupNoCloseClass, mainStyles.subtitle)}>
        {address}
      </div>
    </components.Option>
  );
};

const MenuListIcon: FC = (props: MenuListProps) => {
  const { children } = props;

  return (
    <components.MenuList {...props}>
      <div className="select__icon-menu-list">{children}</div>
    </components.MenuList>
  );
};

const CustomInput: FC = (props: InputProps) => {
  return <components.Input {...props} />;
};

const NoOptionsMessage = (
  isLoading = false,
  LoaderComponent: TLoaderMessage = <LoaderBlock />,
) => {
  return childProps => (
    <components.NoOptionsMessage {...childProps}>
      {isLoading ? LoaderComponent : <div>Значение не найдено</div>}
    </components.NoOptionsMessage>
  );
};

export {
  getCheckIcon,
  singleSelectStyles,
  singleSelectWithoutCheck,
  DropdownIndicator,
  LoaderBlock,
  LoadingMessage,
  OptionIcon,
  MenuListIcon,
  CustomInput,
  NoOptionsMessage,
  OptionDaDataBalance,
};
