import Network, { TAxios } from 'shared/libraries/network';
import CONFIG from 'shared/constants/config';
import { ParsedQuery } from 'query-string';
import { Reason } from 'features/ComplaintToComment/model/types/compliantSchema';

export type TPollsRequestGet = {
  limit?: number;
  page?: number;
  sort?: { column: string; direction: string };
  filters?: ParsedQuery<string> | undefined;
};

export interface IPollsRequest {
  type: string;
  start?: string;
  finish?: string;
  gender?: string;
  range?: string[];
  respondents?: number;
  draft?: boolean;
  education?: string[];
  grade?: string[];
  question?: string;
  force?: boolean;
}

export interface IPollsRequestPut extends IPollsRequest {
  poll: number;
  images: (File | string)[];
}

export interface IPollsRequestPost extends IPollsRequest {
  images: (File | string)[];
}

class PollsModel extends Network {
  constructor(baseURL: string, timeout = 120000) {
    super(baseURL, timeout);
  }

  //GET
  //Получение списка опросов
  async getPolls(data: TPollsRequestGet): Promise<TAxios> {
    return this.send('get', `/polls`, {
      params: { ...data, filters: data.filters },
    });
  }

  //Получение списка фильтров
  async getFilters(data: {
    filters?: ParsedQuery<string> | undefined;
  }): Promise<TAxios> {
    return this.send('get', `/polls/filters`, {
      params: { ...data },
    });
  }

  //Просмотр опроса
  async getPoll(id: string): Promise<TAxios> {
    return this.send('get', `/polls/${id}`);
  }

  //Подробный отчет по отпросу
  async getPollReport(data: {
    id: string;
    filters?: ParsedQuery<string>;
  }): Promise<TAxios> {
    return this.send('get', `/polls/${data.id}/report`, {
      params: { filters: data.filters },
      // params: { filters: JSON.stringify(data.filters) },
    });
  }

  //Подробный отчет по отпросу не авторизованный
  async getPollShareReport(data: {
    share: string;
    filters?: ParsedQuery<string>;
  }): Promise<TAxios> {
    return this.send('get', `/polls/share/${data.share}`, {
      params: { filters: data.filters },
    });
  }

  //Получить вопросы по таргетингу
  async getTargetingQuestions<T>(): Promise<T> {
    return this.send('get', `/questions`);
  }

  //POST
  //Создание опроса
  async postPolls(data: IPollsRequestPost): Promise<TAxios> {
    const { images, ...rest } = data;
    const formdata = new FormData();
    images.forEach((file, i) => {
      formdata.append(`images[${i}]`, file);
    });
    return this.send('post', `/polls`, formdata, {
      params: { ...rest },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  //Жалоба
  async postCompliants(data: {
    result_id: number;
    reason: Reason;
    comment?: string;
  }): Promise<TAxios> {
    return this.send('post', `/complaints`, {
      ...data,
    });
  }

  //Подробный отчет по отпросу не авторизованный
  async addPollInYour(data: { share: string }): Promise<TAxios> {
    return this.send('post', `/polls/share/${data.share}`);
  }

  //PUT
  //Обновление опроса
  async putPolls(data: IPollsRequestPut): Promise<TAxios> {
    const { poll, images, ...rest } = data;
    const formdata = new FormData();
    images.forEach((file, i) => {
      formdata.append(`images[${i}]`, file);
    });
    return this.send('post', `/polls/${poll}`, formdata, {
      params: { ...rest, _method: 'PUT' },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  //Лайк
  async putLike(data: {
    poll: number;
    result_id: number;
    likes: number;
    id: number;
  }): Promise<TAxios> {
    const { poll, result_id } = data;
    return this.send('put', `/polls/${poll}/like`, {
      result_id,
    });
  }

  //DELETE
  //Удалить опрос
  async deletePolls(
    data: TPollsRequestGet & { poll: number },
  ): Promise<TAxios> {
    const { poll, ...rest } = data;
    return this.send('delete', `/polls/${poll}`, { params: rest });
  }
}

const baseUrl = CONFIG.REACT_APP_API_BASE_URL as string;
const pollsModel = new PollsModel(baseUrl);

export default pollsModel;
