import cl from 'classnames';
import { FC, InputHTMLAttributes } from 'react';
import styles from './index.module.scss';

type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  title?: string;
  disabled?: boolean;
  error?: boolean;
};

const RadioInput: FC<RadioProps> = ({
  id,
  value,
  disabled = false,
  className = '',
  title,
  error,
  ...props
}: RadioProps) => {
  return (
    <div className={cl(styles.radio, className)}>
      <input
        type="radio"
        id={id}
        value={value}
        {...props}
        disabled={disabled}
        className={cl({
          [styles['radio--error']]: !!error,
        })}
      />
      {!!title && (
        <label className={styles.label} htmlFor={id}>
          {title}
        </label>
      )}
    </div>
  );
};

export { RadioInput };
