import { FC, PropsWithChildren } from 'react';
import Button from '../button';
import { Icon } from '../icon';
import Popup from '../popup';
import styles from './index.module.scss';
import cl from 'classnames';
import { Layouts, LayoutValues } from 'shared/layouts/types';
import { useSelector } from 'react-redux';
import { getPopupIsOpen } from './model/selectors/getBurgerBlock';
import { useAppDispatch } from 'shared/helpers/hooks/useAppDispatch/useAppDispatch';
import { burgerBlockActions } from './model/slices/burgerBlockSlice';

type TBurgerBlock = PropsWithChildren<{ layout?: LayoutValues }>;

const LandingLayoutBtnsId = {
  Burger: 'burger-btn',
} as const;

const BurgerBlock: FC<TBurgerBlock> = ({ children, layout }) => {
  const dispatch = useAppDispatch();
  const isCabinet = layout === Layouts.Cabinet;
  const showPopup = useSelector(getPopupIsOpen);

  const changeShowPopup = (status: boolean) => {
    dispatch(burgerBlockActions.setOpenPopup(status));
  };

  return (
    <div className={styles.wrap}>
      <Button
        className={styles.btn}
        id={LandingLayoutBtnsId.Burger}
        onClick={() => changeShowPopup(!showPopup)}
        transparent
        nopadding
        as="button"
        withIcon={false}
      >
        <Icon name="icon-burger" />
      </Button>
      <Popup
        closeIds={[LandingLayoutBtnsId.Burger]}
        isShow={showPopup}
        closeHandler={() => changeShowPopup(!!0)}
        withCloseBtn={false}
        className={cl(styles.popup, { [styles.cabinet]: isCabinet })}
        borderRadius={5}
      >
        {children}
      </Popup>
    </div>
  );
};

export default BurgerBlock;
