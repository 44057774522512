import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ParsedQuery } from 'query-string';
import NetworkError from 'shared/exceptions/network';
import pollsModel, {
  IPollsRequestPost,
  IPollsRequestPut,
  TPollsRequestGet,
} from 'store/models/pools';
import initialState from './initial';
import {
  TFilters,
  TMeta,
  TPoll,
  TPollReport,
  TPollResponse,
  TPollStarImages,
  TPollStarReport,
  TResultsStars,
  TTargetingQuestionType,
} from './types';
import { setClientBalance } from 'pages/user/store/store';
import { TEST_TYPE } from 'shared/constants/types';

//GET
export const fetchAllPolls = createAsyncThunk(
  'polls/fetchAllPolls',
  async (data: TPollsRequestGet, { rejectWithValue }) => {
    try {
      const response = (await pollsModel.getPolls(
        data,
      )) as unknown as TPollResponse & TMeta;
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchFilters = createAsyncThunk(
  'polls/fetchFilters',
  async (
    data: {
      filters?: ParsedQuery<string> | undefined;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = (await pollsModel.getFilters(data)) as unknown as {
        data: TFilters;
      };
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchPoll = createAsyncThunk(
  'polls/fetchPoll',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = (await pollsModel.getPoll(id)) as unknown as {
        data: TPoll;
      };
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchPollReport = createAsyncThunk(
  'polls/fetchPollReport',
  async (
    data: { id: string; filters?: ParsedQuery<string>; isFilters?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = (await pollsModel.getPollReport(data)) as unknown as {
        data: TPollReport | TPollStarReport;
      };
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchPollShareReport = createAsyncThunk(
  'polls/fetchPollShareReport',
  async (
    data: { share: string; filters?: ParsedQuery<string>; isFilters?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = (await pollsModel.getPollShareReport(
        data,
      )) as unknown as {
        data: TPollReport | TPollStarReport;
      };
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchTargetingQuestions = createAsyncThunk(
  'polls/fetchTargetingQuestions',
  async (_, { rejectWithValue }) => {
    try {
      const response: { data: TTargetingQuestionType[] } =
        await pollsModel.getTargetingQuestions();
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

//POST
export const fetchNewPoll = createAsyncThunk(
  'polls/fetchNewPoll',
  async (data: IPollsRequestPost, { rejectWithValue, dispatch }) => {
    try {
      const response = (await pollsModel.postPolls(data)) as unknown as {
        data: TPoll;
      };
      if (
        response?.data?.client_balance ||
        response?.data?.client_balance?.toString() === '0'
      ) {
        dispatch(setClientBalance(+response.data.client_balance));
      }
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

//POST
export const addPollInYourList = createAsyncThunk(
  'polls/addPollInYourList',
  async (data: { share: string }, { rejectWithValue }) => {
    try {
      const response = await pollsModel.addPollInYour(data);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

//PUT
export const fetchEditPoll = createAsyncThunk(
  'polls/fetchEditPoll',
  async (data: IPollsRequestPut, { rejectWithValue, dispatch }) => {
    try {
      const response = (await pollsModel.putPolls(data)) as unknown as {
        data: TPoll;
      };
      if (
        response?.data?.client_balance ||
        response?.data?.client_balance?.toString() === '0'
      ) {
        dispatch(setClientBalance(+response.data.client_balance));
      }
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchLike = createAsyncThunk(
  'polls/fetchLike',
  async (
    data: {
      poll: number;
      result_id: number;
      likes: number;
      id: number;
      increase: boolean;
    },
    thunkApi,
  ) => {
    try {
      const response = (await pollsModel.putLike(data)) as unknown as {
        message: string;
      };
      if (response.message !== 'success') {
        thunkApi.dispatch(
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          authSlice.actions.setLike({
            id: data.id,
            result_id: data.result_id,
            increase: !data.increase,
          }),
        );
        throw new Error();
      }
      return response;
    } catch (e) {
      thunkApi.dispatch(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        authSlice.actions.setLike({
          id: data.id,
          result_id: data.result_id,
          increase: !data.increase,
        }),
      );
      return thunkApi.rejectWithValue(e);
    }
  },
);

//DELETE
export const fetchDeletePolls = createAsyncThunk(
  'polls/fetchDeletePolls',
  async (
    data: TPollsRequestGet & { poll: number } & { edit?: true },
    { rejectWithValue },
  ) => {
    try {
      const response = (await pollsModel.deletePolls(
        data,
      )) as unknown as TPollResponse & TMeta;
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const authSlice = createSlice({
  name: 'polls',
  initialState,
  reducers: {
    clearNewPoll(state) {
      state.newPoll = initialState.newPoll;
    },
    clearPolls(state) {
      state.polls.response = null;
      state.polls.loading = false;
      state.polls.error = null;
    },
    setLike(state, action) {
      if (state.pollReport.response?.data) {
        state.pollReport.response.data.images =
          state.pollReport.response.data.images.map(images => {
            if (images.id === action.payload.id) {
              return {
                ...images,
                results: images.results.map(results => {
                  if (results.id === action.payload.result_id) {
                    if (action.payload.increase) {
                      return { ...results, likes: results.likes + 1 };
                    } else return { ...results, likes: results.likes - 1 };
                  } else return results;
                }),
              };
            } else return images;
          });
      }
    },
    setCompliant(state, action) {
      if (state.pollReport.response?.data) {
        state.pollReport.response.data.images =
          state.pollReport.response.data.images.map(images => {
            if (images.id === action.payload.id) {
              return {
                ...images,
                results: images.results.map(results => {
                  if (results.id === action.payload.result_id) {
                    return { ...results, complaint: !results.complaint };
                  } else return results;
                }),
              };
            } else return images;
          });
      }
    },
    removeIsDelete(state) {
      state.polls.isDelete = undefined;
    },
    removeErrorStartPoll(state) {
      state.polls.error = null;
      state.newPoll.error = null;
    },
    removeAdded(state) {
      state.addPoll.added = false;
    },
  },

  extraReducers: builder => {
    //GET
    //polls/fetchPoll
    builder.addCase(fetchPoll.pending, state => {
      state.polls.loading = true;
      state.polls.error = null;
    });
    builder.addCase(fetchPoll.fulfilled, (state, { payload }) => {
      state.polls.loading = false;
      state.polls.error = null;
      state.polls.response = { data: [payload.data] } as TPollResponse & TMeta;
    });
    builder.addCase(fetchPoll.rejected, (state, action) => {
      state.polls.loading = false;
      const message = (action.payload as NetworkError).message;
      state.polls.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchAllPolls
    builder.addCase(fetchAllPolls.pending, state => {
      state.polls.loading = true;
      state.polls.error = null;
    });
    builder.addCase(fetchAllPolls.fulfilled, (state, { payload }) => {
      state.polls.loading = false;
      state.polls.error = null;
      state.polls.response = payload;
    });
    builder.addCase(fetchAllPolls.rejected, (state, action) => {
      state.polls.loading = false;
      const message = (action.payload as NetworkError).message;
      state.polls.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchFilters
    builder.addCase(fetchFilters.pending, state => {
      state.filters.loading = true;
      state.filters.error = null;
    });
    builder.addCase(fetchFilters.fulfilled, (state, { payload }) => {
      state.filters.loading = false;
      state.filters.error = null;
      state.filters.response = payload;
    });
    builder.addCase(fetchFilters.rejected, (state, action) => {
      state.filters.loading = false;
      const message = (action.payload as NetworkError).message;
      state.filters.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchPollReport
    builder.addCase(fetchPollReport.pending, (state, { meta }) => {
      if (!meta.arg.isFilters) {
        state.pollReport.loading = true;
      }
      state.pollReport.error = null;
    });
    builder.addCase(fetchPollReport.fulfilled, (state, { payload }) => {
      state.pollReport.loading = false;
      state.pollReport.error = null;

      // if (payload.data.type !== TEST_TYPE.STAR) {
      state.pollReport.response = payload as { data: TPollReport };
      // }
      // if (payload.data.type === TEST_TYPE.STAR) {
      //   state.pollReport.response = {
      //     ...payload,
      //     data: {
      //       ...payload.data,
      //       images: Object.keys((payload.data as TPollStarReport).results).map(
      //         key => ({
      //           ...(payload.data as TPollStarReport).results[key],
      //           star: key,
      //         }),
      //       ),
      //       results: payload.data.images as unknown as TPollStarImages[],
      //     },
      //   };
      // }
    });
    builder.addCase(fetchPollReport.rejected, (state, action) => {
      state.pollReport.loading = false;
      const message = (action.payload as NetworkError).message;
      state.pollReport.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchPollShareReport
    builder.addCase(fetchPollShareReport.pending, (state, { meta }) => {
      if (!meta.arg.isFilters) {
        state.pollReport.loading = true;
      }
      state.pollReport.error = null;
    });
    builder.addCase(fetchPollShareReport.fulfilled, (state, { payload }) => {
      state.pollReport.loading = false;
      state.pollReport.error = null;
      // if (payload.data.type !== TEST_TYPE.STAR) {
      state.pollReport.response = payload as { data: TPollReport };
      // }
      // if (payload.data.type === TEST_TYPE.STAR) {
      //   state.pollReport.response = {
      //     ...payload,
      //     data: {
      //       ...payload.data,
      //       images: Object.keys((payload.data as TPollStarReport).results).map(
      //         key => ({
      //           ...(payload.data as TPollStarReport).results[key],
      //           star: key,
      //         }),
      //       ),
      //       results: payload.data.images as unknown as TPollStarImages[],
      //     },
      //   };
      // }
    });
    builder.addCase(fetchPollShareReport.rejected, (state, action) => {
      state.pollReport.loading = false;
      const message = (action.payload as NetworkError).message;
      state.pollReport.error = { ...(action.payload as NetworkError), message };
    });

    //polls/fetchTargetingQuestions
    builder.addCase(fetchTargetingQuestions.pending, state => {
      state.targeting.loading = true;
      state.targeting.error = undefined;
    });
    builder.addCase(fetchTargetingQuestions.fulfilled, (state, { payload }) => {
      state.targeting.loading = false;
      state.targeting.error = undefined;
      state.targeting.questions =
        [...payload.data]?.sort((a, b) =>
          a.formulation > b.formulation ? 1 : -1,
        ) || [];
    });
    builder.addCase(fetchTargetingQuestions.rejected, (state, action) => {
      state.targeting.loading = false;
      const message = (action.payload as NetworkError).message;
      state.targeting.error = { ...(action.payload as NetworkError), message };
    });

    //POST
    //polls/fetchNewPoll
    builder.addCase(fetchNewPoll.pending, state => {
      state.newPoll.loading = true;
      state.newPoll.error = null;
    });
    builder.addCase(fetchNewPoll.fulfilled, (state, { payload }) => {
      state.newPoll.loading = false;
      state.newPoll.error = null;
      state.newPoll.response = payload;
    });
    builder.addCase(fetchNewPoll.rejected, (state, action) => {
      state.newPoll.loading = false;
      const message = (action.payload as NetworkError).message;
      state.newPoll.error = { ...(action.payload as NetworkError), message };
    });

    //POST
    //polls/addPollInYourList
    builder.addCase(addPollInYourList.pending, state => {
      state.addPoll.loading = true;
      state.addPoll.error = undefined;
    });
    builder.addCase(addPollInYourList.fulfilled, state => {
      state.addPoll.loading = false;
      state.addPoll.error = undefined;
      state.addPoll.added = true;
    });
    builder.addCase(addPollInYourList.rejected, (state, action) => {
      state.addPoll.loading = false;
      const message = (action.payload as NetworkError).message;
      state.addPoll.error = { ...(action.payload as NetworkError), message };
    });

    //PUT
    //polls/fetchEditPoll
    builder.addCase(fetchEditPoll.pending, state => {
      state.newPoll.loading = true;
      state.newPoll.error = null;
    });
    builder.addCase(fetchEditPoll.fulfilled, (state, { payload }) => {
      state.newPoll.loading = false;
      state.newPoll.error = null;
      state.newPoll.response = payload;
    });
    builder.addCase(fetchEditPoll.rejected, (state, action) => {
      state.newPoll.loading = false;
      const message = (action.payload as NetworkError).message;
      state.newPoll.error = { ...(action.payload as NetworkError), message };
    });

    //DELETE
    //polls/fetchDeletePolls
    builder.addCase(fetchDeletePolls.pending, state => {
      state.polls.loading = true;
      state.polls.error = null;
    });
    builder.addCase(fetchDeletePolls.fulfilled, (state, { payload, meta }) => {
      state.polls.loading = false;
      state.polls.error = null;
      if (meta.arg.edit) {
        state.polls.isDelete = true;
      }
      if (!meta.arg.edit) {
        state.polls.response = payload;
      }
    });
    builder.addCase(fetchDeletePolls.rejected, (state, action) => {
      state.polls.loading = false;
      const message = (action.payload as NetworkError).message;
      state.polls.error = { ...(action.payload as NetworkError), message };
    });
  },
});

export default authSlice.reducer;
export const {
  clearNewPoll,
  clearPolls,
  setLike,
  removeIsDelete,
  removeErrorStartPoll,
  setCompliant,
  removeAdded,
} = authSlice.actions;
