import { BurgerBlockSchema } from '../types/burgerBlockSchema';
import { createSelector } from '@reduxjs/toolkit';
import { TState } from 'store/types';

export const getBurgerBlock = (state: TState): BurgerBlockSchema =>
  state.burgerBlock;

export const getPopupIsOpen = createSelector(
  getBurgerBlock,
  ({ popupIsOpen }) => {
    return popupIsOpen;
  },
);
