export type TNetworkError = {
  name: string;
  message: string;
  status: number;
  errors: TNetworkInsideErrors | null;
};

export type TNetworkInsideErrors = {
  [key: string]: TNetworkInsideError[];
};

export type TNetworkInsideError = {
  [key: number]: string;
};

class NetworkError extends Error {
  readonly status: number;

  readonly errors: TNetworkInsideErrors | null;

  constructor(message: string, status = 502, errors = null) {
    super(message);
    this.status = status;
    this.name = this.constructor.name;
    this.errors = errors;
  }
}

export default NetworkError;
