import React, { FC, useMemo } from 'react';
import styles from '../form.module.scss';
import TimerComponent from 'entities/AuthBlock/ui/authBlock/components/timer';
import Button from 'shared/components/button';
import { FormStep, TStepsValues } from '..';
import utils from 'shared/helpers/utils';
import { Icon } from 'shared/components/icon';
import Input from 'shared/components/input';

export const ChangeBtnsId = 'change-phone-btn' as const;
const maxLength = {
  0: 4,
  1: 3,
  2: 2,
  3: 1,
};
type TSmsFormProps = {
  phone: string;
  sms: string;
  smsError: string | null;
  smsTime: number | null;
  authOption: 'call' | 'sms' | 'initialSms' | 'initialCall';
  setSmsError: React.Dispatch<React.SetStateAction<string | null>>;
  setFormStep: React.Dispatch<React.SetStateAction<TStepsValues | undefined>>;
  smsSend: () => Promise<unknown>;
  setSmsTime: React.Dispatch<React.SetStateAction<number | null>>;
  inputHandler: (arg1: EventTarget & HTMLInputElement, arg2: number) => void;
  changeAuthOption: (option: 'call' | undefined) => void;
};

const SmsForm: FC<TSmsFormProps> = React.memo(props => {
  const {
    phone,
    sms,
    smsError,
    smsTime,
    setSmsError,
    setFormStep,
    smsSend,
    setSmsTime,
    inputHandler,
    authOption,
    changeAuthOption,
  } = props;

  const buttonTitle = useMemo(() => {
    if (authOption === 'call') {
      return 'Повторный звонок';
    } else if (authOption === 'initialCall') {
      return 'Сделать звонок';
    } else return 'Получить новый';
  }, [authOption]);

  const descriptionTitle = useMemo(() => {
    if (authOption === 'call' || authOption === 'initialCall') {
      return 'Введите последние цифры телефона, с которого был звонок на номер ';
    } else return 'Введите код из СМС-сообщения на номер ';
  }, [authOption]);

  return (
    <form className={styles.form} onSubmit={evt => evt.preventDefault()}>
      <h2 className={styles.title}>Код подтверждения</h2>
      <p className={styles.descr}>
        {descriptionTitle}
        <br />
        {`+${phone}`}{' '}
        <button
          type="button"
          className={styles.changePhoneBtn}
          onClick={() => {
            setFormStep(FormStep.Phone);
          }}
          id="change-phone-btn"
        >
          Изменить
        </button>
      </p>
      <div className={styles.smsWrap}>
        <div className={styles.smsInputs}>
          {Array.from({ length: 4 }, (_, idx) => {
            return (
              <Input
                value={
                  !!sms.split('')[idx] && sms.split('')[idx] !== '_'
                    ? sms.split('')[idx]
                    : ''
                }
                error={smsError !== null && !sms.split('')[idx]}
                placeholder="_"
                key={String(idx)}
                type="text"
                className={styles.smsInput}
                max={1}
                id={`${idx}-sms`}
                maxLength={maxLength[idx]}
                inputMode="decimal"
                onChange={({ target }) => inputHandler(target, idx)}
                onKeyUp={({ target, key }) => {
                  if (key === 'Backspace') {
                    const { id } = target as EventTarget & HTMLInputElement;
                    const [fieldIndex] = id.split('-');

                    if (parseInt(fieldIndex, 10) < 4) {
                      const nextSibling = document.getElementById(
                        `${parseInt(fieldIndex, 10) - 1}-sms`,
                      ) as HTMLInputElement;
                      if (nextSibling !== null) {
                        nextSibling.focus();
                      }
                    }
                  }
                }}
                // autoComplete="one-time-code"
                autoComplete={!idx ? 'one-time-code' : 'off'}
                name={`onetime${idx}`}
              />
            );
          })}
        </div>
        {smsError !== null && (
          <span className={styles.smsError}>{smsError}</span>
        )}
      </div>

      <div className={styles.footerBtn}>
        <Button
          type="button"
          onClick={() => {
            smsSend();
            if (authOption === 'initialCall') {
              changeAuthOption('call');
            }
            setSmsError(null);
            utils.setFocus('sms', '0-sms');
          }}
          disabled={smsTime !== null}
          as="button"
        >
          <Icon name="icon-cycle" />
          {buttonTitle}
        </Button>
        {smsTime !== null ? (
          <TimerComponent
            time={smsTime || 90}
            stopTimer={() => setSmsTime(null)}
          />
        ) : (
          <button
            type="button"
            className={styles.changeAuthOption}
            onClick={() => changeAuthOption(undefined)}
            id="change-authOption-btn"
          >
            {authOption === 'sms' || authOption === 'initialSms'
              ? 'По звонку'
              : 'По SMS'}
          </button>
        )}
      </div>
    </form>
  );
});

export { SmsForm };
