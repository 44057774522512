import { PropsWithChildren } from 'react';
import cl from 'classnames';
import style from './index.module.scss';

type TCol = {
  left?: boolean;
};

const Col = ({ children, left }: TCol & PropsWithChildren) => {
  return (
    <div className={cl(style.col, { [style.left]: left })}>{children}</div>
  );
};

export default Col;
