import React, { FC, ReactNode, useEffect, useState } from 'react';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import cl from 'classnames';
import styles from './index.module.scss';
import { WindowWidth } from 'shared/constants/windowWidth';
import renderBlockPagination from './items/blockPagination';
import renderDotsPagination from './items/blockDots';
import Button from '../button';
import { Icon } from '../icon';

export type TPaginationProps = {
  page?: string | null;
  total: number | string;
  limit: number | string;
  className?: string;
};

const Pagination: FC<TPaginationProps> = props => {
  const { page, total: totalProps, limit: limitProps, className = '' } = props;
  const current = Number(page) || 1;
  const limit = Number(limitProps);
  const total = Number(totalProps);
  const count = Math.ceil(total / limit);

  const isOnePage = total / limit > 1;

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const queryParam = qs.parse(search);
  const setSearch: (arg: number) => string = val =>
    qs.stringify({ ...queryParam, page: val });

  const result: ReactNode[] = [];
  const increment = 1;
  // if (count > 29 && count < 100) increment = 5;
  // if (count > 99 && count < 500) increment = 10;
  // if (count > 499 && count < 1000) increment = 25;
  // if (count > 999) increment = 50;

  //   страниц меньше или равно 15

  const params = {
    7: {
      plength: 7,
      start: 4,
      startCount: 5,
      diff: 1,
      end: 4,
    },
    10: {
      plength: 10,
      start: 6,
      startCount: 8,
      diff: 2,
      end: 7,
    },
    11: {
      plength: 11,
      start: 8,
      startCount: 9,
      diff: 3,
      end: 8,
    },
    15: {
      plength: 15,
      start: 8,
      startCount: 13,
      diff: 5,
      end: 12,
    },
  };

  const startPtype = () => {
    const width = window.innerWidth;
    if (width >= WindowWidth.TABLET) {
      return 10;
    }
    // if (width < WindowWidth.TABLET && width >= WindowWidth.MOBILE) {
    //   return 11;
    // }
    return 7;
  };

  const [pType, setPtype] = useState(startPtype);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width >= WindowWidth.TABLET) {
        setPtype(10);
        // } else if (width < WindowWidth.TABLET && width >= WindowWidth.MOBILE) {
        //   setPtype(11);
      } else if (width < WindowWidth.TABLET) {
        setPtype(7);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  if (count <= params[pType].plength) {
    result.push(renderBlockPagination({ current, start: 1, count, setSearch }));
  }

  //   страниц больше 15
  if (count > params[pType].plength) {
    if (current <= params[pType].start) {
      result.push(
        renderBlockPagination({
          current,
          start: 1,
          count: params[pType].startCount,
          setSearch,
        }),
      );
      result.push(
        renderDotsPagination({
          current,
          increment,
          key: 'central',
          setSearch,
        }),
      );
      result.push(
        renderBlockPagination({ current, start: count, count, setSearch }),
      );
    }
    if (
      current > params[pType].start &&
      current <= count - params[pType].start
    ) {
      result.push(
        renderBlockPagination({ current, start: 1, count: 1, setSearch }),
      );
      result.push(
        renderDotsPagination({
          current,
          increment: -increment,
          key: 'left',
          setSearch,
        }),
      );
      result.push(
        renderBlockPagination({
          current,
          start: current - params[pType].diff,
          count: current + params[pType].diff,
          setSearch,
        }),
      );
      result.push(
        renderDotsPagination({
          current,
          increment,
          key: 'right',
          setSearch,
        }),
      );
      result.push(
        renderBlockPagination({ current, start: count, count, setSearch }),
      );
    }
    if (current > count - params[pType].start) {
      result.push(
        renderBlockPagination({ current, start: 1, count: 1, setSearch }),
      );
      result.push(
        renderDotsPagination({
          current,
          increment: -increment,
          key: 'central',
          setSearch,
        }),
      );
      result.push(
        renderBlockPagination({
          current,
          start: count - params[pType].end,
          count,
          setSearch,
        }),
      );
    }
  }

  const buttonNavigate = (nextPage: number) => {
    const newQueryParam = qs.stringify({
      ...queryParam,
      page: nextPage,
    });

    navigate(`${pathname}?${newQueryParam}`);
  };

  return (
    <>
      {isOnePage ? (
        <nav className={cl(styles.pagination, className)}>
          <Button
            className={cl(styles.btn, styles.prev, {
              [styles.empty]: page?.toString() === '1',
            })}
            empty
            transparent
            disabled={page?.toString() === '1'}
            onClick={() => {
              if (page && +page > 1) {
                buttonNavigate(+page - 1);
              }
            }}
            as="button"
          >
            <Icon name="icon-arrow-left" />
            Назад
          </Button>
          <ul className={styles.list}>{result}</ul>
          <Button
            className={cl(styles.btn, styles.next)}
            empty
            transparent
            disabled={!!page && +page === count}
            onClick={() => {
              if (page && +page < count) {
                buttonNavigate(+page + 1);
              }
            }}
            as="button"
          >
            Вперёд
            <Icon name="icon-arrow-right" />
          </Button>
        </nav>
      ) : null}
    </>
  );
};

export default React.memo(Pagination);
